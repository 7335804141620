/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { ResearchServiceMonthCounts } from "../../Constants/ResearchServiceMonthCounts";
import axios from "axios";
import {
  CREATE_NEW_ORDER,
  RESEARCH_SERVICES_GET_ALL,
} from "../../utils/API_Names";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import {
  GetAllClient,
  GetListOfUserByCompanyMasterCodeAndUserTypeCode,
  listOfManufactureNDistributorCompanies,
} from "../../api/Api";
import { productTypeEnum } from "../../Constants/ProductTypeEnum";
import { toast } from "react-toastify";
import { changeDate } from "../../utils/Helper";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { IncomeType } from "../../Constants/IncomeType";
import ClientModalForOrder from "../ClientModalForOrder";

const OrderWindowForResearchServices = () => {
  const [monthValue, setMonthValue] = React.useState<any>(
    ResearchServiceMonthCounts[0].value
  );
  const [month, setMonth] = React.useState<any>(
    ResearchServiceMonthCounts[0].month
  );
  const [planDetails, setPlanDetails] = React.useState<any>("");
  const [planDetail, setPlanDetail] = React.useState<any>("");
  const [companyMasterCode, setCompanyMasterCode] = React.useState();
  const [companyTypeCode, setCompanyTypeCode] = React.useState();
  const [distributorCode, setDistributorCode] = React.useState("");
  const [clientId, setClientId] = React.useState("");
  const { handleSubmit, reset } = useForm();
  const [showCircularProgress, setshowCircularProgress] = React.useState(false);
  const [orderDate, setOrderDate] = React.useState(new Date());
  const [Clients, setClients] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [clientDetails, setClientDetails] = React.useState<any>("");

  React.useEffect(() => {
    axios
      .get(`${RESEARCH_SERVICES_GET_ALL}?tenure=${monthValue}`)
      .then((data: any) =>
        setPlanDetails(data.data.researchServiceSubscriptionDTOList)
      );
  }, [monthValue]);

  const { data: distributorCompanyList } = useQuery(
    "ManufactureNDistributorCompanie",
    () => listOfManufactureNDistributorCompanies("D,E"),
    {
      enabled:
        sessionStorage.getItem("Profile") === "B" ||
        sessionStorage.getItem("Profile") === "E",
    }
  );
  const filteredClients = Clients.filter((item: any) => {
    return (
      item?.fullName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item?.panCardNo?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item?.clientCode?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const { data: UserProfilesByCompanyMasterCode } = useQuery(
    ["userProfileByCompany", companyMasterCode, companyTypeCode],
    () =>
      GetListOfUserByCompanyMasterCodeAndUserTypeCode(
        companyTypeCode === "S" ? "E" : companyTypeCode,
        companyMasterCode
      ),
    {
      enabled: !!companyMasterCode,
    }
  );

  const { data: clientData } = useQuery(
    ["getAllClients", companyMasterCode, distributorCode],
    () => GetAllClient(companyMasterCode, distributorCode),
    {
      enabled: !!companyMasterCode || sessionStorage.getItem("Profile") === "D",
    }
  );

  const onSubmit = () => {
    axios
      .post(CREATE_NEW_ORDER, {
        productTypeCode: productTypeEnum.RESEARCH_SERVICES,
        productMasterCode: planDetail.productMasterCode,
        clientId: clientId,
        version: "1",
        distCompanyMasterCode: companyMasterCode,
        distEmployeeCode: distributorCode,
        subscriptionPlan: planDetail?.productName,
        orderDate: changeDate(orderDate),
      })
      .then((res: any) => {
        toast.success(res?.data?.status?.message?.title);
        window.location.reload();
      })
      .then(() => {
        setshowCircularProgress(false);
        reset();
      })
      .catch((err: any) => {
        setshowCircularProgress(false);
        toast.error(
          err?.response?.data?.status?.message?.title
            ? err?.response?.data?.status?.message?.title
            : err?.response?.data?.title
        );
      });
  };
  const { data } = useQuery("getAllClients", () => GetAllClient(), {
    onSuccess: (data: any) => {
      const newData = data.data.clients.map((item: any, index: any) => {
        return { ...item, SrNo: index + 1 };
      });
      setClients(newData);
    },
  });
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = useState(0);

  return (
    <>
      <Grid container rowSpacing={1} columnSpacing={1}>
        {sessionStorage.getItem("Profile") === "B" ||
        sessionStorage.getItem("Profile") === "E" ? (
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={
                distributorCompanyList &&
                distributorCompanyList.data.companies.map(
                  (options: any) => options
                )
              }
              getOptionLabel={(options: any) =>
                options.companyName + " - " + options.companyTypeCode
              }
              fullWidth
              onChange={(options: any, newValue) => {
                setCompanyMasterCode(
                  newValue ? newValue.companyMasterCode : ""
                );
                setCompanyTypeCode(newValue ? newValue.companyTypeCode : "");
              }}
              renderInput={(params: any) => (
                <TextField {...params} label="Select Company" size="small" />
              )}
            />
          </Grid>
        ) : (
          <></>
        )}

        {(UserProfilesByCompanyMasterCode &&
          sessionStorage.getItem("Profile") === "B") ||
        (UserProfilesByCompanyMasterCode &&
          sessionStorage.getItem("Profile") === "E") ? (
          <>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={
                  UserProfilesByCompanyMasterCode
                    ? UserProfilesByCompanyMasterCode.data.userProfile.map(
                        (options: any) => options
                      )
                    : []
                }
                getOptionLabel={(options: any) =>
                  options?.userCode +
                  "- " +
                  options?.firstName +
                  " " +
                  options?.lastName
                }
                fullWidth
                onChange={(options: any, newValue) => {
                  setDistributorCode(newValue ? newValue.employeeCode : "");
                }}
                renderInput={(params: any) => (
                  <TextField {...params} label="Select User" size="small" />
                )}
              />
            </Grid>
          </>
        ) : (
          <></>
        )}

        {clientData && (
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={clientData.data.clients.map((options: any) => options)}
              getOptionLabel={(options: any) =>
                options.fullName + " - " + options.panCardNo
              }
              fullWidth
              onChange={(options: any, newValue) => {
                setClientId(newValue ? newValue.clientId : "");
                setClientDetails(newValue ? newValue : "");
              }}
              renderInput={(params: any) => (
                <form id="form1">
                  {/* onSubmit={handleSubmit(onSubmitInvestNow)} */}
                  <TextField {...params} label="Select Clients" size="small" />
                </form>
              )}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={ResearchServiceMonthCounts.map((option: any) => option)}
            getOptionLabel={(options: any) => options.month}
            defaultValue={ResearchServiceMonthCounts[0]}
            onChange={(options: any, newValue) => {
              setMonthValue(newValue.value);
              setMonth(newValue.month);
            }}
            size="small"
            disableClearable
            renderInput={(params) => (
              <TextField {...params} label="Select Month" size={"small"} />
            )}
          />
        </Grid>
        {planDetails && (
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={planDetails.map((options: any) => options)}
              getOptionLabel={
                (options: any) => options.productName
                // + ", Price: " + options.planAmount
              }
              onChange={(options: any, newValue) => {
                setPlanDetail(newValue);
              }}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  label="Select Subscription Plan"
                  size={"small"}
                />
              )}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileDatePicker
              label="Order Date"
              inputFormat="DD/MM/YYYY"
              value={orderDate}
              onChange={(newValue: any) => {
                setOrderDate(newValue.toJSON());
              }}
              renderInput={(params) => (
                <TextField size="small" {...params} sx={{ width: "100%" }} />
              )}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
      {clientId && <ClientModalForOrder clientDetails={clientDetails} />}
      {/* {!!planDetail && <Grid container rowSpacing={1} columnSpacing={1}>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <form>
            <TextField
              label="Investment Amount"
              size="small"
              fullWidth
              value={planDetail.planAmount}
              {...register("buyRate")}
            />
          </form>
        </Grid>
      </Grid>} */}
      {planDetail ? (
        <>
          <Typography variant="subtitle1" textAlign="center" gutterBottom>
            Plan Category: <b>{planDetail.category}</b>
          </Typography>
          <Typography variant="subtitle1" textAlign="center" gutterBottom>
            Plan Tenure: <b>{month}</b>
          </Typography>
          <Typography variant="subtitle1" textAlign="center" gutterBottom>
            Plan Name: <b>{planDetail.productName}</b>
          </Typography>
          <Typography variant="subtitle1" textAlign="center" gutterBottom>
            Plan Amount: <b>{planDetail.planAmount}</b>
          </Typography>
          <Typography variant="subtitle1" textAlign="center" gutterBottom>
            Plan Segment: <b>{planDetail.segment}</b>
          </Typography>
          <Grid container sx={{ textAlign: "center" }}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Button type="submit" variant="contained" onClick={onSubmit}>
                Order Now
              </Button>
            </Grid>
          </Grid>
        </>
      ) : (
        <></>
      )}
      <></>
    </>
  );
};

export default OrderWindowForResearchServices;
