/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Alert,
  Button,
  Container,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Snackbar,
  SnackbarOrigin,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useQuery } from "react-query";
import { getPasswordPolicy } from "../api/Api";
import CircleIcon from "@mui/icons-material/Circle";

export interface State extends SnackbarOrigin {
  openSnack: boolean;
}

const userLogout = () => {
  axios.post("authentication/logout");
  sessionStorage.clear();
  window.location.href = "/";
};

const ChangePassword = () => {
  const [authErrorMessage, setauthErrorMessage] = useState("");
  const [authErrortype, setauthErrortype] = useState<any>("");
  const [password, setPassword] = useState("");
  const [confPassword, setConfPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [confError, setConfError] = useState("");
  const [policy, setPolicy] = useState<any>([]);
  const [state, setState] = React.useState<State>({
    openSnack: false,
    vertical: "top",
    horizontal: "right",
  });
  const { openSnack } = state;

  const { data } = useQuery("passWordPolicy", getPasswordPolicy, {
    onSuccess: (data: any) => {
      setPolicy(data.data.passwordPolicy[0]);
    },
  });

  const passList = [
    {
      primary:
        "At least " + policy.uppercaseAlphabet + " upper case letter (A-Z)",
    },
    {
      primary: "At least " + policy.lowerAlphabet + " lower case letter (a-z)",
    },
    { primary: "At least " + policy.numNumeric + " number (0-9)" },
    { primary: "At least " + 1 + " special character" },
  ];

  function handlePassword(event: any) {
    let new_pass = event.target.value;
    if (new_pass.length === 0) {
      setErrorMessage("");
      setPassword("");
    } else {
      setPassword(new_pass);
      // regular expressions to validate password
      var lowerCase = /[a-z]/g;
      var upperCase = /[A-Z]/g;
      var numbers = /[0-9]/g;
      if (!new_pass.match(upperCase)) {
        setErrorMessage("Password should contains upperCase letters!");
      } else if (!new_pass.match(lowerCase)) {
        setErrorMessage("Password should contain lowerCase letters!");
      } else if (!new_pass.match(numbers)) {
        setErrorMessage("Password should contains numbers also!");
      } else {
        setErrorMessage("");
      }
    }
  }

  function handleConfirm(event: any) {
    let conf = event.target.value;
    setConfPassword(conf);
    if (conf !== password) {
      setConfError("Password Does not Match");
    } else {
      setConfError("");
    }
  }

  const resolver = async (values: any) => {
    return {
      values: !values.oldPassword ? {} : values,
      errors: !values.oldPassword
        ? {
            oldPassword: {
              type: "required",
              message: "This is required.",
            },
          }
        : {},
    };
  };

  const { register, handleSubmit } = useForm({ resolver });

  const onSubmit = (data: any) => {
    if (data.newPassword === data.conformPassword) {
      axios
        .put("userProfile/updatePassword", {
          oldPassword: data.oldPassword,
          newPassword: data.newPassword,
        })
        .then(() => {
          setauthErrortype("success");
          setauthErrorMessage("Password Change successfully");
          userLogout();
        })
        .catch((err: any) => {
          setauthErrortype("error");
          setauthErrorMessage(err.response.data.status.message.title);
        });
    } else setauthErrortype("error");
    setauthErrorMessage("Password Does't Match");
    setState({ openSnack: true, vertical: "top", horizontal: "right" });
  };

  const handleCloseSnackbar = () => {
    setState({ ...state, openSnack: false });
  };

  return (
    <Container maxWidth="xl">
      <div>
        <Box
          sx={{
            paddingTop: 3,
            "& .MuiTextField-root": { m: 1 },
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Paper
            sx={{
              height: "100%",
              width: 400,
              borderRadius: "15px",
              padding: "32px",
              backgroundColor: "#e7eeff",
            }}
            elevation={3}
          >
            <Typography variant="h4" component="h3" sx={{ mb: 3 }}>
              Change Password
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <TextField
                id="outlined-basic"
                label="Old Password"
                variant="outlined"
                fullWidth
                sx={{ width: "100%" }}
                {...register("oldPassword")}
              />
              <TextField
                id="outlined-basic"
                label="New Password"
                variant="outlined"
                value={password}
                {...register("newPassword")}
                onChange={handlePassword}
                sx={{ width: "100%" }}
              />
              <div
                style={{
                  color: "red",
                  fontSize: "0.8rem",
                  marginLeft: "21%",
                  textAlign: "left",
                }}
              >
                {" "}
                {errorMessage}{" "}
              </div>
              <TextField
                id="outlined-basic"
                label="Confirm Password"
                variant="outlined"
                value={confPassword}
                {...register("conformPassword")}
                onChange={handleConfirm}
                sx={{ width: "100%" }}
              />
              <div
                style={{
                  color: "red",
                  fontSize: "0.8rem",
                  marginLeft: "21%",
                  textAlign: "left",
                }}
              >
                {" "}
                {confError}{" "}
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <h3 style={{ margin: "20px 0px 0px 0px", paddingLeft: "1rem" }}>
                  Password must contain
                </h3>
                <List dense>
                  {passList?.map((it: any) => (
                    <ListItem
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        padding: "8px 16px",
                      }}
                    >
                      <ListItemIcon sx={{ minWidth: "30px" }}>
                        <CircleIcon sx={{ fontSize: 8 }} />
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          margin: 0,
                          "& .css-1cad3dr-MuiTypography-root": {
                            fontSize: "0.825rem",
                          },
                        }}
                        primary={it.primary}
                      />
                    </ListItem>
                  ))}
                </List>
              </div>
              <Button type="submit" color="primary" variant="contained">
                Submit
              </Button>
            </form>
          </Paper>
        </Box>

        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={openSnack}
          onClose={handleCloseSnackbar}
          message="I love snacks"
          autoHideDuration={4000}
        >
          <Alert severity={authErrortype} sx={{ width: "100%" }}>
            {authErrorMessage}
          </Alert>
        </Snackbar>
      </div>
    </Container>
  );
};

export default ChangePassword;
