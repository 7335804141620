/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { withParam } from "../utils/Router.Helper";
import { useQuery } from "react-query";
import {
  product_listOfAllProductType,
  userProfile_getUserTypeDetails,
} from "../api/Api";
import { Bounce, toast } from "react-toastify";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router";

type typeOfProducts = {
  productTypeCode: number;
  productName: string;
};

const UserProfileView = (props: any) => {
  const [UserProfileCode] = useState(props.params.userCode);
  const [UserProfile, setUserProfile] = useState<any>([]);
  const [Company, setCompany] = useState<any>();
  const [edit, setedit] = useState(true);
  const [UserType, setUserType] = useState("");
  const [UserRole, setUserRole] = useState("");
  const [refresh, setRefresh] = React.useState(true);
  const [employeeManagerUserCode, setEmployeeManagerUserCode] = useState("");
  const [UserList, setUserList] = useState([]);
  // const [UserCode, SetUserCode] = useState<any>([]);
  // const [CompanyWiseProduct, setCompanyWiseProduct] = useState([]);
  const [allProducts, setAllProducts] = useState<any>([]);
  const [checkedProducts, setCheckedProducts] = useState<string[]>([]);
  const [checkedProdID, setCheckedProdID] = useState<any>("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    axios
      .get("userProfile/getUserProfileDetails/" + UserProfileCode)
      .then((res) => {
        setUserProfile(res?.data?.userProfile[0]);
        setUserType(res?.data?.userProfile[0]?.userTypeCode);
        setUserRole(res?.data?.userProfile[0]?.userRole);
        // API for showing all products with checked products
        axios.get("product/listOfProductType").then((resp: any) => {
          const productArray = resp && resp?.data?.productType;
          const compMasterCode = res?.data?.userProfile[0]?.companyMasterCode;
          // call company detail API if companyMasterCode is available
          compMasterCode
            ? axios
                .get(
                  "company/companyDetail/" +
                    res?.data?.userProfile[0]?.companyMasterCode
                )
                .then((resp1) => {
                  const filterElems =
                    resp1?.data?.companies[0]?.products?.split(",");
                  const newArray = productArray?.filter((item: any) =>
                    filterElems.includes(item.productTypeCode.toString())
                  );
                  setAllProducts(newArray);
                })
            : // call the API to get all products if companyMasterCode is not available
              setAllProducts(productArray);
          const filterProducts =
            resp &&
            resp?.data?.productType?.filter((item: typeOfProducts) =>
              res?.data?.userProfile[0]?.products
                ?.split(",")
                .includes(item.productTypeCode + "")
            );
          setCheckedProducts(filterProducts);
          setIsLoading(false);
        });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      });
  }, [UserProfileCode]);

  useEffect(() => {
    UserProfile.companyMasterCode &&
      axios
        .get("company/companyDetail/" + UserProfile.companyMasterCode)
        .then((res: any) => setCompany(res.data.companies[0]));
    UserProfile.companyMasterCode &&
      axios
        .get(
          "userProfile/listOfUserTypeUserProfile/" +
            UserType +
            "?companyMasterCode=" +
            UserProfile.companyMasterCode
        )
        .then((res) => setUserList(res.data.userProfile));
  }, [UserProfile]);

  const resolver: any = async (values: any) => {
    return {
      values: !values.firstName ? {} : values,
      errors: !values.firstName
        ? {
            firstName: {
              type: "required",
              message: "This field is required",
            },
          }
        : !values.mobileNo
        ? {
            mobileNo: {
              type: "required",
              message: "This field is required",
            },
          }
        : !values.lastName
        ? {
            lastName: {
              type: "required",
              message: "This field is required",
            },
          }
        : !values.email
        ? {
            email: {
              type: "required",
              message: "This field is required",
            },
          }
        : (UserProfile.userTypeCode === "M" ||
            UserProfile.userTypeCode === "D") &&
          !values.accountManager
        ? {
            accountManager: {
              type: "required",
              message: "This field is required",
            },
          }
        : {},
    };
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: resolver,
  });

  // const mutation:any = useMutation (async (data: any) => {
  //   const userResponse:any = await axios.put("userProfile/updateUserProfile", {
  //     ...data,
  //     companyMasterCode: Company.companyMasterCode,
  //     userCode: UserProfileCode,
  //     userTypeCode: UserProfile.userTypeCode,
  //     brokerMasterCode: UserProfile.brokerMasterCode,
  //   })
  //   return userResponse
  // });

  const onSubmit = async (data: any) => {
    const userProfileData = {
      ...data,
      companyMasterCode: Company?.companyMasterCode,
      userCode: UserProfileCode,
      userTypeCode: UserProfile?.userTypeCode,
      brokerMasterCode: UserProfile?.brokerMasterCode,
      userRole: UserRole,
      managerEmployeeCode: employeeManagerUserCode,
      managerId: UserProfile?.managerId,
      // userRole: UserProfile?.userRole,
      // managerEmployeeCode: UserProfile?.managerEmployeeCode,
      companyName: UserProfile?.companyName,
      // products: UserProfile?.products,
      products: checkedProdID ? checkedProdID : UserProfile?.products,
    };
    axios
      .put("userProfile/updateUserProfile", userProfileData)
      .then(() => {
        toast.success("User-profile updated successfully!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          transition: Bounce,
        });
        // Delay the reload by 5 seconds
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      })
      .catch((err) =>
        toast.error(
          err?.response?.data?.status?.message?.title
            ? err?.response?.data?.status?.message?.title
            : err?.response?.data?.title
        )
      );
  };

  // Function to handle checkbox change
  const handleCheckboxChange = (productTypeCode: any, currentChecked: any) => {
    // Toggle the checked state based on the current state
    const updatedCheckedProducts = currentChecked
      ? checkedProducts.filter(
          (item: any) => item.productTypeCode !== productTypeCode
        )
      : [
          ...checkedProducts,
          allProducts.find(
            (item: any) => item.productTypeCode === productTypeCode
          ),
        ];

    const productID = updatedCheckedProducts.map(
      (prod) => prod.productTypeCode
    );
    const prodIDs = productID.join(",");
    setCheckedProdID(prodIDs);
    setCheckedProducts(updatedCheckedProducts);
  };

  const activeInactiveUser = () => {
    axios
      .put("userProfile/softDeleteUserProfile/" + UserProfileCode)
      .then(() => {
        const successMessage = UserProfile.active
          ? "User in-activated successfully!"
          : "User activated successfully!";
        toast.success(successMessage, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          transition: Bounce,
        });
        // Delay the reload by 5 seconds
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      });
    axios
      .get("userProfile/getUserProfileDetails/" + UserProfileCode)
      .then((res) => setUserProfile(res.data.userProfile[0]));
    // .then(() => window.location.reload());
  };

  const xs = 12;
  const sm = 5;
  const md = 3;
  const lg = 3;
  const textFieldSize = "small";

  return (
    <>
      <Box>
        {UserProfile.length !== 0 && (
          <>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box component="form" noValidate autoComplete="off">
                {/* <Grid item md={12} textAlign="center" marginBottom={3}>
                  <Typography variant="h5">Personal Details</Typography>
                </Grid> */}
                <Grid
                  item
                  md={12}
                  marginBottom={3}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="back"
                    style={{ position: "absolute", left: "5.5rem" }}
                    onClick={() =>
                      navigate("/UserDashboard", {
                        state: {
                          userType_code: props.params.userCode,
                          userType_name: UserType,
                        },
                      })
                    }
                  >
                    <ArrowBackIcon />
                  </IconButton>
                  <Typography variant="h5">Personal Details</Typography>
                </Grid>
                <Grid container gap={2}>
                  <Grid item xs={xs} sm={sm} md={md} lg={lg}>
                    <TextField
                      id="outlined-basic"
                      label="First Name"
                      variant="outlined"
                      fullWidth
                      className={edit ? "textDisable" : ""}
                      size={textFieldSize}
                      error={errors.firstName ? true : false}
                      helperText={
                        errors.firstName === undefined
                          ? ""
                          : errors.firstName.message + ""
                      }
                      defaultValue={UserProfile.firstName}
                      InputProps={{ readOnly: edit }}
                      {...register("firstName", { required: true })}
                    />
                  </Grid>
                  <Grid item xs={xs} sm={sm} md={md} lg={lg}>
                    <TextField
                      id="outlined-basic"
                      label="Last Name"
                      variant="outlined"
                      fullWidth
                      className={edit ? "textDisable" : ""}
                      size={textFieldSize}
                      defaultValue={UserProfile.lastName}
                      InputProps={{ readOnly: edit }}
                      error={errors.lastName ? true : false}
                      helperText={
                        errors.lastName === undefined
                          ? ""
                          : errors.lastName.message + ""
                      }
                      {...register("lastName")}
                    />
                  </Grid>
                  <Grid item xs={xs} sm={sm} md={md} lg={lg}>
                    <TextField
                      id="outlined-basic"
                      label="Middle Name"
                      variant="outlined"
                      fullWidth
                      className={edit ? "textDisable" : ""}
                      size={textFieldSize}
                      defaultValue={UserProfile.middleName}
                      {...register("middleName")}
                      InputProps={{ readOnly: edit }}
                    />
                  </Grid>
                  <Grid item xs={xs} sm={sm} md={md} lg={lg}>
                    <TextField
                      id="outlined-basic"
                      label="Email"
                      variant="outlined"
                      fullWidth
                      className={edit ? "textDisable" : ""}
                      size={textFieldSize}
                      defaultValue={UserProfile.email}
                      InputProps={{ readOnly: edit }}
                      error={errors.email ? true : false}
                      helperText={
                        errors.email === undefined
                          ? ""
                          : errors.email.message + ""
                      }
                      {...register("email")}
                    />
                  </Grid>
                  <Grid item xs={xs} sm={sm} md={md} lg={lg}>
                    <TextField
                      id="outlined-basic"
                      label="Mobile"
                      variant="outlined"
                      type="number"
                      fullWidth
                      className={edit ? "textDisable" : ""}
                      size={textFieldSize}
                      defaultValue={UserProfile.mobileNo}
                      InputProps={{ readOnly: edit }}
                      error={errors.mobileNo ? true : false}
                      helperText={
                        errors.mobileNo === undefined
                          ? ""
                          : errors.mobileNo.message + ""
                      }
                      {...register("mobileNo")}
                    />
                  </Grid>
                  <Grid item xs={xs} sm={sm} md={md} lg={lg}>
                    <TextField
                      id="outlined-basic"
                      label="Employee Code"
                      variant="outlined"
                      fullWidth
                      className={edit ? "textDisable" : ""}
                      size={textFieldSize}
                      defaultValue={UserProfile.employeeCode}
                      InputProps={{ readOnly: edit }}
                      error={errors.employeeCode ? true : false}
                      helperText={
                        errors.employeeCode === undefined
                          ? ""
                          : errors.employeeCode.message + ""
                      }
                      {...register("employeeCode")}
                    />
                  </Grid>
                  <Grid item xs={xs} sm={sm} md={md} lg={lg}>
                    <TextField
                      id="outlined-basic"
                      label="Account Manager"
                      variant="outlined"
                      type="number"
                      fullWidth
                      className={edit ? "textDisable" : ""}
                      size={textFieldSize}
                      defaultValue={UserProfile.accountManager}
                      InputProps={{ readOnly: edit }}
                      error={errors.accountManager ? true : false}
                      helperText={
                        errors.accountManager === undefined
                          ? ""
                          : errors.accountManager.message + ""
                      }
                      {...register("accountManager")}
                    />
                  </Grid>
                  {UserType === "A" || UserType === "B" ? (
                    <></>
                  ) : (
                    <Grid item xs={xs} sm={sm} md={md} lg={lg}>
                      <Autocomplete
                        id="Select-user-Role"
                        size={textFieldSize}
                        className={edit ? "textDisable" : ""}
                        options={[
                          { label: "Individual", value: "INDIVIDUAL" },
                          { label: "Company Head", value: "COMPANY_HEAD" },
                          { label: "Group Head", value: "GROUP_HEAD" },
                          { label: "Team Lead", value: "TEAM_LEAD" },
                        ].map((option: any) => option)}
                        getOptionLabel={(options: any) => options.label}
                        defaultValue={
                          [
                            { label: "Individual", value: "INDIVIDUAL" },
                            { label: "Company Head", value: "COMPANY_HEAD" },
                            { label: "Group Head", value: "GROUP_HEAD" },
                            { label: "Team Lead", value: "TEAM_LEAD" },
                          ].find(
                            (option: any) =>
                              option.value === UserProfile.userRole
                          ) || null
                        }
                        onChange={(options: any, newValue) => {
                          setUserRole(newValue?.value);
                          setRefresh(!refresh);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} label="Select User Role" />
                        )}
                      />
                    </Grid>
                  )}

                  {UserRole !== "COMPANY_HEAD" && (
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Autocomplete
                        id="Manager"
                        size={textFieldSize}
                        className={edit ? "textDisable" : ""}
                        options={UserList.map((option: any) => option)}
                        getOptionLabel={(options: any) => options.employeeCode}
                        value={
                          UserList.find(
                            (option: any) =>
                              option.employeeCode ===
                              UserProfile.managerEmployeeCode
                          ) || null
                        }
                        onChange={(options: any, newValue) => {
                          setEmployeeManagerUserCode(newValue?.employeeCode);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select Manager"
                            error={errors.userTypeCode ? true : false}
                            helperText={
                              errors.userTypeCode == undefined
                                ? ""
                                : errors.userTypeCode.message + ""
                            }
                          />
                        )}
                      />
                    </Grid>
                  )}
                </Grid>
                {Company && (
                  <Grid
                    item
                    md={12}
                    textAlign="center"
                    marginBottom={3}
                    marginTop={3}
                  >
                    <Typography variant="h5">Company Details</Typography>
                  </Grid>
                )}
                <Grid container gap={2}>
                  {Company && (
                    <Grid item xs={xs} sm={sm} md={md} lg={lg}>
                      <TextField
                        id="outlined-basic"
                        label="Company Name"
                        variant="outlined"
                        fullWidth
                        className={edit ? "textDisable" : ""}
                        size={textFieldSize}
                        defaultValue={UserProfile.companyName}
                        InputProps={{ readOnly: true }}
                      />
                    </Grid>
                  )}
                  {Company && (
                    <Grid item xs={xs} sm={sm} md={md} lg={lg}>
                      <TextField
                        id="outlined-basic"
                        label="Company Type"
                        variant="outlined"
                        fullWidth
                        className={edit ? "textDisable" : ""}
                        size={textFieldSize}
                        defaultValue={Company.compType}
                        InputProps={{ readOnly: true }}
                      />
                    </Grid>
                  )}
                  {Company && (
                    <Grid item xs={xs} sm={sm} md={md} lg={lg}>
                      <TextField
                        id="outlined-basic"
                        label="Company Email"
                        variant="outlined"
                        fullWidth
                        className={edit ? "textDisable" : ""}
                        size={textFieldSize}
                        defaultValue={Company.emailId}
                        InputProps={{ readOnly: true }}
                      />
                    </Grid>
                  )}
                  {Company && (
                    <Grid item xs={xs} sm={sm} md={md} lg={lg}>
                      <TextField
                        id="outlined-basic"
                        label="Company GST Number "
                        variant="outlined"
                        fullWidth
                        className={edit ? "textDisable" : ""}
                        size={textFieldSize}
                        defaultValue={Company.gstNumber}
                        InputProps={{ readOnly: true }}
                      />
                    </Grid>
                  )}
                  {Company && (
                    <Grid item xs={xs} sm={sm} md={md} lg={lg}>
                      <TextField
                        id="outlined-basic"
                        label="Company PAN"
                        variant="outlined"
                        fullWidth
                        className={edit ? "textDisable" : ""}
                        size={textFieldSize}
                        defaultValue={Company.pancard}
                        InputProps={{ readOnly: true }}
                      />
                    </Grid>
                  )}
                  {Company && (
                    <Grid item xs={xs} sm={sm} md={md} lg={lg}>
                      <TextField
                        id="outlined-basic"
                        label="Company CIN"
                        variant="outlined"
                        fullWidth
                        className={edit ? "textDisable" : ""}
                        size={textFieldSize}
                        defaultValue={Company.cinNumber}
                        InputProps={{ readOnly: true }}
                      />
                    </Grid>
                  )}
                </Grid>
                <Grid
                  item
                  md={12}
                  textAlign="center"
                  marginBottom={3}
                  marginTop={3}
                >
                  <Typography variant="h5">Product Details</Typography>
                </Grid>
                <Grid item xs={xs} sm={sm} md={md} lg={lg}>
                  {" "}
                  {isLoading ? (
                    // Display a loading spinner while products are being fetched
                    <CircularProgress />
                  ) : allProducts?.length > 0 ? (
                    allProducts?.map((item: any) => {
                      const isProductChecked = checkedProducts.some(
                        (checkedItem: any) =>
                          Number(checkedItem.productTypeCode) ===
                          Number(item.productTypeCode)
                      );
                      return (
                        <FormControlLabel
                          key={item.productTypeCode}
                          control={<Checkbox />}
                          onChange={() =>
                            handleCheckboxChange(
                              item.productTypeCode,
                              isProductChecked
                            )
                          }
                          disabled={edit}
                          checked={isProductChecked}
                          label={item.productName}
                        />
                      );
                    })
                  ) : (
                    <Typography>
                      The Product has not been assigned to you yet.
                    </Typography>
                  )}
                </Grid>
              </Box>
              {edit && (
                <Button
                  sx={{ margin: "10px 0" }}
                  variant="contained"
                  onClick={() => setedit(false)}
                  disabled={sessionStorage.getItem("Profile") === "E"}
                >
                  Edit Details
                </Button>
              )}
              {!edit && (
                <>
                  <Stack
                    spacing={2}
                    sx={{ justifyContent: "center", margin: "10px 0" }}
                    direction="row"
                  >
                    <Button variant="contained" type="submit">
                      Save Details
                    </Button>
                    <Button variant="contained" onClick={() => setedit(true)}>
                      Cancel
                    </Button>
                    <Button variant="contained" onClick={activeInactiveUser}>
                      {UserProfile.active ? "In Active" : "Active"}
                    </Button>
                  </Stack>
                </>
              )}
            </form>
          </>
        )}
      </Box>
    </>
  );
};

export default withParam(UserProfileView);
