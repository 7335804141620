/* eslint-disable no-unreachable */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useQuery } from "react-query";
import { product_listOfAllProductType } from "../../api/Api";
import { useForm } from "react-hook-form";
import axios from "axios";
import { CREATE_BOOK_A_CALL } from "../../utils/API_Names";
import { toast } from "react-toastify";
import {
  LocalizationProvider,
  MobileDatePicker,
  MobileDateTimePicker,
  MobileTimePicker,
} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const BookACallForm = () => {
  const [orderDate, seOrderDate] = React.useState<any>(new Date());
  const [toDate, setToDate] = React.useState<any>(dayjs(new Date().toJSON()));
  const [fromDate, setFromDate] = React.useState<any>(
    dayjs(new Date().toJSON())
  );
  const { data: productData } = useQuery(
    "ProductData",
    product_listOfAllProductType,
    {
      refetchOnWindowFocus: false,
    }
  );

  // const resolver1 = async (values: any) => {
  //   return {
  //     values: values,
  //     errors: !values.productTypeCode
  //       ? {
  //           productTypeCode: {
  //             type: "required",
  //             message: "Please Select a Product",
  //           },
  //         }
  //       : !values.availabilityDate
  //       ? {
  //           availabilityDate: {
  //             type: "required",
  //             message: "Date of availability is required",
  //           },
  //         }
  //       : values.availabilityDate &&
  //         dayjs(values.availabilityDate).isBefore(dayjs(), "day")
  //       ? {
  //           availabilityDate: {
  //             type: "validate",
  //             message: "Date should be greater than or equal to today!",
  //           },
  //         }
  //       : !values.address
  //       ? {
  //           address: {
  //             type: "required",
  //             message: "Address is required",
  //           },
  //         }
  //       : !values.companyName
  //       ? {
  //           companyName: {
  //             type: "required",
  //             message: "Company Name is required",
  //           },
  //         }
  //       : !values.contactPersonFullName
  //       ? {
  //           contactPersonFullName: {
  //             type: "required",
  //             message: "Full Name is required",
  //           },
  //         }
  //       : !values.emailId
  //       ? {
  //           emailId: {
  //             type: "required",
  //             message: "Email Id is required",
  //           },
  //         }
  //       : !values.emailId.match(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/)
  //       ? {
  //           emailId: {
  //             type: "pattern",
  //             message: "Invalid Email Id",
  //           },
  //         }
  //       : !values.mobileNo
  //       ? {
  //           mobileNo: {
  //             type: "required",
  //             message: "Mobile Number is required",
  //           },
  //         }
  //       : !values.mobileNo.match(/^[0-9]+$/)
  //       ? {
  //           mobileNo: {
  //             type: "pattern",
  //             message: "Invalid Mobile Number",
  //           },
  //         }
  //       : {},
  //   };
  // };

  const resolver1 = async (values: any) => {
    const errors: any = {};
    if (!values.productTypeCode) {
      errors.productTypeCode = {
        type: "required",
        message: "Please Select a Product",
      };
    }
    if (!values.availabilityDate) {
      errors.availabilityDate = {
        type: "required",
        message: "Date of availability is required",
      };
    } else if (
      values.availabilityDate &&
      dayjs(values.availabilityDate).isBefore(dayjs(), "day")
    ) {
      errors.availabilityDate = {
        type: "validate",
        message: "Date should be greater than or equal to today!",
      };
    }
    if (!values.address) {
      errors.address = {
        type: "required",
        message: "Address is required",
      };
    }
    if (!values.companyName) {
      errors.companyName = {
        type: "required",
        message: "Company Name is required",
      };
    }
    if (!values.contactPersonFullName) {
      errors.contactPersonFullName = {
        type: "required",
        message: "Full Name is required",
      };
    }

    return {
      values: errors.length ? {} : values,
      errors: errors,
    };
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm({ mode: "onChange", resolver: resolver1 });

  const registerOptions = {
    productTypeCode: { required: "Please Select Product" },
    firstName: { required: "First Name required" },
    address: { required: "Address is required" },
    mobileNo: {
      required: "MobileNo is required",
      pattern: {
        value: /^[0-9]+$/,
        message: "Invalid Mobile Number",
      },
    },
    emailId: {
      required: "EmailId is required",
      pattern: {
        value: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/,
        message: "Invalid Email Id",
      },
    },
    contactPersonFullName: { required: "Full Name is required" },
    companyName: { required: "Company Name is required" },
    availabilityDate: {
      required: "Date of availability is required",
      validate: (value: any) => {
        const selectedDate = dayjs(value);
        const today = dayjs();
        // Check if the date is in the past
        if (selectedDate.isBefore(today, "day")) {
          return "Date should be greater than or equal to today!";
        }
        // Check if the date is today and the time is in the past
        // if (
        //   selectedDate.isSame(today, "day") &&
        //   selectedDate.isBefore(today, "minute")
        // ) {
        //   return "Time should be greater than the current time!";
        // }
        return true;
      },
    },
  };

  const selectedProduct = watch("productTypeCode", null);

  const onsubmit = (data: any) => {
    let createBookACallDto = {
      ...data,
      availabilityFromTime: fromDate?.$d + "" || null,
      availabilityToTime: toDate?.$d + "" || null,
      status: "REQUEST_RECEIVED",
    };
    axios
      .post(CREATE_BOOK_A_CALL, createBookACallDto)
      .then((res: any) =>
        toast.success("Your Call Has Been Successfully Registered")
      )
      .catch((err: any) =>
        toast.error(
          err?.response?.data?.status?.message?.title
            ? err?.response?.data?.status?.message?.title
            : "Please Select a Product" /*err?.response?.data?.title*/
        )
      );
  };
  const handleFromDateChange = (newValue: any) => {
    setFromDate(newValue);
    if (newValue.isAfter(toDate)) {
      setToDate(newValue.add(10, "minutes"));
    }
  };
  const handleToDateChange = (newValue: any) => {
    if (newValue.isAfter(fromDate)) {
      setToDate(newValue);
    } else {
      console.error("To time must be greater than From time");
    }
  };

  return (
    <Box sx={{ p: 6 }}>
      {productData && (
        <Paper
          elevation={3}
          sx={{
            p: 3,
            pl: sessionStorage.getItem("JWT") ? 3 : 3,
            m: sessionStorage.getItem("JWT")
              ? "-24px 16px 0px 72px"
              : "72px 0 0 0",
          }}
        >
          <Typography align="left" variant="h4">
            {" "}
            Welcome!{" "}
          </Typography>
          <Typography align="left" variant="subtitle1" gutterBottom>
            {" "}
            Book your appointment in a few simple steps: Choose a service, pick
            your date and time, and fill in your details. See you soon!
          </Typography>
          <Box sx={{ mt: 3 }}>
            <form onSubmit={handleSubmit(onsubmit)}>
              <Grid container columnSpacing={2} rowSpacing={2}>
                <Grid item xs={12} sm={12} md={4} lg={3}>
                  {productData && (
                    <Autocomplete
                      id="combo-box-demo"
                      options={productData.data.productType
                        .filter((option: any) => {
                          if (sessionStorage.getItem("Profile") === "D") {
                            // Filter out the object if profile is "D"
                            return option.productName !== "BOND WORK";
                          }
                          return true; // Include all options if profile is not "D"
                        })
                        .map((option: any) => option)}
                      getOptionLabel={(options: any) => options.productName}
                      onChange={(options: any, newValue) => {
                        setValue(
                          "productTypeCode",
                          newValue?.productTypeCode || null
                        );
                      }}
                      // defaultValue={productData.data.productType[0]}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Product"
                          error={errors.productTypeCode ? true : false}
                          helperText={
                            !!errors.productTypeCode &&
                            errors.productTypeCode.message + ""
                          }
                          fullWidth
                          // {...register("productTypeCode",registerOptions.productTypeCode)}
                        />
                      )}
                    />
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={3}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileDatePicker
                      label="Available Date"
                      inputFormat="YYYY-MM-DD"
                      value={orderDate}
                      disablePast
                      onChange={(newValue: any) => {
                        if (newValue && newValue.isValid()) {
                          const formattedDate =
                            dayjs(newValue).format("YYYY-MM-DD");
                          seOrderDate(formattedDate);
                          setValue("availabilityDate", formattedDate, {
                            shouldValidate: true,
                          });
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{ width: "100%" }}
                          error={errors.availabilityDate ? true : false}
                          helperText={
                            errors.availabilityDate === undefined
                              ? ""
                              : errors.availabilityDate.message + ""
                          }
                          {...register(
                            "availabilityDate",
                            registerOptions.availabilityDate
                          )}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={12} sm={12} md={4} lg={3}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileTimePicker
                      label="From"
                      openTo="minutes"
                      value={fromDate}
                      onChange={handleFromDateChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{ width: "100%" }}
                          error={errors.availabilityFromTime ? true : false}
                          helperText={
                            errors.availabilityFromTime === undefined
                              ? ""
                              : errors.availabilityFromTime.message + ""
                          }
                          {...register("availabilityFromTime")}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={12} sm={12} md={4} lg={3}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileTimePicker
                      label="To"
                      openTo="minutes"
                      value={toDate}
                      onChange={handleToDateChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{ width: "100%" }}
                          error={errors.availabilityToTime ? true : false}
                          helperText={
                            errors.availabilityToTime === undefined
                              ? ""
                              : errors.availabilityToTime.message + ""
                          }
                          {...register("availabilityToTime")}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={12} sm={12} md={4} lg={3}>
                  <TextField
                    id="outlined-basic"
                    label="Address"
                    variant="outlined"
                    error={errors.address ? true : false}
                    helperText={!!errors.address && errors.address.message + ""}
                    fullWidth
                    {...register("address", registerOptions.address)}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={4} lg={3}>
                  <TextField
                    id="outlined-basic"
                    label="Company Name"
                    variant="outlined"
                    error={errors.companyName ? true : false}
                    helperText={
                      !!errors.companyName && errors.companyName.message + ""
                    }
                    fullWidth
                    {...register("companyName", registerOptions.companyName)}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={3}>
                  <TextField
                    id="outlined-basic"
                    label="Full Name"
                    variant="outlined"
                    error={errors.contactPersonFullName ? true : false}
                    helperText={
                      !!errors.contactPersonFullName &&
                      errors.contactPersonFullName.message + ""
                    }
                    fullWidth
                    {...register(
                      "contactPersonFullName",
                      registerOptions.contactPersonFullName
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={3}>
                  <TextField
                    id="outlined-basic"
                    label="Email Id"
                    variant="outlined"
                    type="email"
                    error={errors.emailId ? true : false}
                    helperText={!!errors.emailId && errors.emailId.message + ""}
                    fullWidth
                    {...register("emailId", registerOptions.emailId)}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={3}>
                  <TextField
                    id="outlined-basic"
                    label="Mobile No"
                    variant="outlined"
                    //type="number"
                    error={errors.mobileNo ? true : false}
                    helperText={
                      !!errors.mobileNo && errors.mobileNo.message + ""
                    }
                    fullWidth
                    {...register("mobileNo", registerOptions.mobileNo)}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Button variant="contained" type="submit">
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Paper>
      )}
    </Box>
  );
};

export default BookACallForm;
