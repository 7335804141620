/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable eqeqeq */
import {
  Autocomplete,
  Box,
  Button,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Container,
  CircularProgress,
  Grid,
  TablePagination,
  IconButton,
  InputBase,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm, Resolver, Controller } from "react-hook-form";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { withParam } from "../utils/Router.Helper";
import {
  Brokers_listOfBroker,
  company_listOfCompanies,
  listOfManufactureNDistributorCompanies,
  product_listOfAllProductType,
  userProfile_getUserTypeDetails,
} from "../api/Api";
import AlertPopup from "../Constants/AlertPopup";
import States from "../Constants/States";
import SideMenu from "./SideMenu";
import MultiSelect from "../utils/MultiSelect";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import makeStyles from "@mui/styles/makeStyles";

const AdminDashboard = () => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    bgcolor: "background.paper",
    border: "1px solid gray",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
    "& .MuiTextField-root": { m: 1 },
    overflowY: "scroll",
    maxHeight: "80%",
  };

  const [UserType, setUserType] = useState<any>(
    localStorage.getItem("UserType")
  );
  const [NewUserType, setNewUserType] = useState("");
  const [UserCode, SetUserCode] = useState<any>([]);
  const [companyList, setcompanyList] = useState([]);
  const [companyCode, setCompanyCode] = useState("");
  const [UserProfile, setUserProfile] = useState([]);
  const [NewUserProfile, setNewUserProfile] = useState([]);
  const [ProductList, setProductList] = useState([]);
  const [CompanyWiseProduct, setCompanyWiseProduct] = useState([]);
  const [productlist, setproductlist] = useState<any>([]);
  const [brokerList, setBrokerList] = useState<any>();
  const [brokerCode, setBrokerCode] = useState<any>("");
  // const [selectedCountry, setSelectedCountry] = useState<any>(null);
  // const [selectedState, setSelectedState] = useState<any>(null);
  const [showCircularProgress, setshowCircularProgress] = useState(false);
  const [employeeManagerUserCode, setEmployeeManagerUserCode] = useState("");
  const [individualEmployeeCode, setIndividualEmployeeCode] = useState("");
  const [individualEmployeeData, setIndividualEmployeeData] = useState<any>("");
  const [UserRole, setUserRole] = useState("");
  const [checked, setChecked] = React.useState(true);
  const [refresh, setRefresh] = React.useState(true);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [filterUserRole, setFilterUserRole] = useState<any>([]);

  const [open, setOpen] = React.useState(false);
  const [employeeData, setEmployeeData] = React.useState([]);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = useState(0);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleSearch = (event: any) => {
    setSearchTerm(event.target.value);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    UserRole === "INDIVIDUAL" ? setChecked(false) : <></>;
  }, [checked, UserRole]);

  useEffect(() => {
    !!individualEmployeeCode &&
      axios
        .get(
          "employeeProfile/employeeProfile?employeeCode=" +
            individualEmployeeCode
        )
        .then((res) => {
          setValue("firstName", res.data.employeeProfileDTOS[0].firstName);
          setValue("lastName", res.data.employeeProfileDTOS[0].lastName);
          setValue("middleName", res.data.employeeProfileDTOS[0].middleName);
          setValue("mobileNo", res.data.employeeProfileDTOS[0].mobileNo);
          setValue("email", res.data.employeeProfileDTOS[0].email);
          setValue(
            "employeeCode",
            res.data.employeeProfileDTOS[0].employeeCode
          );
          setValue(
            "accountManager",
            res.data.employeeProfileDTOS[0].accountManager
          );
          setIndividualEmployeeData(res.data.employeeProfileDTOS[0]);
        });
  }, [individualEmployeeCode]);

  const { data: getUserTypeDetails, refetch } = useQuery(
    "getUserTypeDetail",
    userProfile_getUserTypeDetails,
    {
      onSuccess: (data: any) => {
        SetUserCode(data.data.userType);
      },
      refetchOnWindowFocus: false,
    }
  );
  const { data: listOfAllProductType } = useQuery(
    ["ListOfAllProductTypes", open],
    product_listOfAllProductType,
    {
      enabled: open,
      onSuccess: (data: any) => {
        setProductList(data.data.productType);
      },
      staleTime: Infinity,
    }
  );
  const { data: listOfBroker } = useQuery(
    ["listOfBrokers", open],
    Brokers_listOfBroker,
    {
      enabled: sessionStorage.getItem("Profile") == "A" && open ? true : false,
      onSuccess: (data: any) => {
        setBrokerList(data.data.brokers);
      },
    }
  );

  const registerOptions: any = {
    userTypeCode: { required: "Please select user type code" },
    userRole: { required: "Please select user role" },
    manager: { required: "Please select a manager" },
    companyMasterCode: { required: "Please select user company" },
    email: { required: "Email ID is required" },
    middleName: {
      pattern: {
        value: /^[A-Za-z.0-9]+$/,
        message: "Invalid Middle Name",
      },
    },
    mobileNo: {
      required: "Please enter mobile no.",
      pattern: {
        value: /^[0-9]+$/,
        message: "Invalid Mobile Number",
      },
    },
    firstName: { required: "Please enter first name" },
    lastName: { required: "Please enter last name" },
    // accountManager: {
    //   required: "Enter Account Manager No.",
    //   pattern: {
    //     value: /^[0-9]+$/,
    //     message: "Invalid Mobile Number",
    //   },
    // },
    // account manager no should be optional only when userRole is company head else mandatory and max length should be 10
    accountManager: {
      required:
        !UserRole || UserRole === "COMPANY_HEAD"
          ? ""
          : "Please enter manager's mobile no.",
      pattern: {
        value: /^[0-9]+$/,
        message: "Invalid Mobile Number",
      },
      // apply max length validation only when userRole is not company head
      maxLength: {
        value: 10,
        message: "Mobile Number should be 10-digits",
      },
    },
    employeeCode: { required: "Please enter employee code" },
    product: { required: "Please select a product" },
  };

  useEffect(() => {
    NewUserType === "D" || NewUserType === "E" || NewUserType === "M"
      ? axios
          .get(
            "company/listOfManufactureNDistributorCompanies/companyTypeCode?companyTypeCode=" +
              NewUserType
          )
          .then((res: any) => setcompanyList(res.data.companies))
      : setCompanyCode("");
  }, [NewUserType, checked, refresh]);

  useEffect(() => {
    UserType === "" ? (
      <></>
    ) : (
      axios
        .get("userProfile/listOfUserTypeUserProfile/" + UserType)
        .then((res) => setUserProfile(res.data.userProfile))
    );
  }, [UserType, checked, refresh]);

  useEffect(() => {
    NewUserType === "" ? (
      <></>
    ) : (
      axios
        .get(
          "userProfile/listOfUserTypeUserProfile/" +
            NewUserType +
            "?companyMasterCode=" +
            companyCode
        )
        .then((res) => setNewUserProfile(res.data.userProfile))
    );
  }, [NewUserType, companyCode, checked, refresh]);

  useEffect(() => {
    !!companyCode &&
      axios
        .get("sourceDestination/sourceValue?sourceValue=" + companyCode)
        .then((res: any) => setCompanyWiseProduct(res.data.sourceDes));

    !!companyCode &&
      axios
        .get(
          "employeeProfile/employeeByCompany?companyMasterCode=" + companyCode
        )
        .then((res: any) => {
          setEmployeeData(res.data.employeeProfileDTOS);
        });
  }, [companyCode]);

  useEffect(() => {
    sessionStorage.getItem("Profile") === "A" ? (
      setUserType("A")
    ) : sessionStorage.getItem("Profile") === "B" ? (
      setUserType("B")
    ) : sessionStorage.getItem("Profile") === "E" ? (
      setUserType("D")
    ) : (
      <></>
    );
    // Check if the page is being reloaded
    const isPageReload =
      window.performance && window.performance.navigation.type === 1;
    const savedUserType = localStorage.getItem("UserType");
    if (savedUserType && !isPageReload) {
      setUserType(savedUserType);
    }
    //+++Condition for page reload+++
    const navigationEntries = window.performance.getEntriesByType("navigation");
    const navigationEntry = navigationEntries[0] as PerformanceNavigationTiming;
    const isPageReload1 = navigationEntry && navigationEntry.type === "reload";
    axios.get("userProfile/getUserTypeDetails").then((res) => {
      const filteredUsers = res.data.userType.filter(
        (user: any) => user.code === savedUserType
      );
      setFilterUserRole(filteredUsers);
      // Set default user type based on page reload
      setUserType(
        isPageReload1
          ? filteredUsers.length > 0
            ? filteredUsers[0].code
            : ""
          : UserType
      );
    });
  }, []);

  // Update localStorage whenever userType changes
  useEffect(() => {
    localStorage.setItem("UserType", UserType);
  }, [UserType]);

  // const handleViewProfileClick = (userCode: any) => {
  //   // Set the user type in localStorage before navigating to the profile page
  //   localStorage.setItem("UserType", userCode);
  // };

  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    getValues,
    watch,
    formState: { errors },
  } = useForm({ mode: "all" });

  const onSubmit = (data: any) => {
    setshowCircularProgress(true);
    axios
      .post(
        "userProfile/saveUserProfile" +
          (productlist && "?productType=" + productlist),
        {
          ...data,
          companyMasterCode: companyCode,
          userTypeCode: NewUserType,
          managerEmployeeCode: employeeManagerUserCode,
          userRole: !!UserRole ? UserRole : null,
          brokerMasterCode:
            brokerCode == ""
              ? sessionStorage.getItem("BrokerMasterCode")
              : brokerCode,
          user: { active: "" },
        }
      )
      .then((res: any) => toast.success("User added successfully!"))
      .then(() => {
        setshowCircularProgress(false);
        setOpen(false);
        window.location.reload();
        refetch();
      })
      .catch((err: any) => {
        setshowCircularProgress(false);
        toast.error(
          err.response.data ? err.response.data.status.message.title : "Error"
        );
      });
  };

  const filteredNames =
    UserProfile &&
    UserProfile.filter((profile: any) => {
      const { firstName, lastName } = profile;
      const fullName = `${firstName} ${lastName}`;
      return fullName.toLowerCase().includes(searchTerm.toLowerCase());
    });

  return (
    getUserTypeDetails && (
      <>
        <Container maxWidth="xl">
          <Box sx={{ minHeight: "calc(100vh - 150px)" }}>
            <Box sx={{ paddingTop: 5 }}>
              <Button
                onClick={handleOpen}
                variant="contained"
                sx={{ textTransform: "capitalize" }}
              >
                Add New User
              </Button>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {/* User Role Box */}
                <Box sx={{ width: 300, p: 3 }}>
                  {/* USER ROLE */}
                  <FormControl variant="outlined">
                    <InputLabel
                      id="demo-simple-select-outlined-label"
                      style={{ zIndex: 0 }}
                    >
                      User Role
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={UserType}
                      onChange={(event) => {
                        const setValue = event.target.value;
                        setUserType(setValue);
                        setPage(0);
                      }}
                      label="User Role"
                      sx={{
                        width: 225,
                        textAlign: "left",
                      }}
                    >
                      {UserCode.map((option: any) => (
                        <MenuItem key={option.code} value={option.code}>
                          {option.value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                {/* Search User */}
                <Paper
                  component="form"
                  sx={{
                    p: "4px",
                    display: "flex",
                    alignItems: "center",
                    width: 400,
                    mb: "0rem",
                  }}
                >
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search Name"
                    onChange={handleSearch}
                  />
                  <IconButton
                    type="button"
                    sx={{ p: "10px" }}
                    aria-label="search"
                  >
                    <SearchIcon />
                  </IconButton>
                </Paper>
              </Box>
              <TableContainer component={Paper} elevation={3}>
                <Table sx={{ minWidth: 930 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>User Code</TableCell>
                      <TableCell align="left">Name</TableCell>
                      {UserType !== "A" ? (
                        UserType !== "B" ? (
                          <TableCell align="left">Company Name</TableCell>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                      <TableCell align="left">Phone No</TableCell>
                      <TableCell align="left">Status</TableCell>
                      <TableCell align="center">View</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredNames
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row: any) => (
                        <TableRow
                          key={row.userCode}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {row.userCode} - {row.userTypeCode}
                          </TableCell>
                          <TableCell align="left">
                            {row.firstName + " " + row.lastName}
                          </TableCell>
                          {UserType !== "A" ? (
                            UserType !== "B" ? (
                              <TableCell align="left">
                                {row.companyName}
                              </TableCell>
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )}
                          <TableCell align="left">{row.mobileNo}</TableCell>
                          <TableCell align="left">
                            {row?.active ? "Active" : "In Active"}
                          </TableCell>
                          <TableCell align="center">
                            <Link
                              to={"/A1/" + row.userCode}
                              // onClick={() =>
                              //   handleViewProfileClick(row.userTypeCode)
                              // }
                            >
                              <Button
                                size="small"
                                type="submit"
                                color="primary"
                                variant="contained"
                              >
                                View
                              </Button>
                            </Link>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 30, 50]}
                component="div"
                count={filteredNames ? filteredNames.length : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
              {/* ADD NEW USER MODAL */}
              <Box>
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    overflow: "scroll",
                  }}
                >
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Box sx={style} className="modal-scroll">
                      <Typography
                        variant="h6"
                        component="h2"
                        textAlign={"center"}
                        mb={3}
                      >
                        Add New User
                      </Typography>

                      <Typography id="keep-mounted-modal-description">
                        <Grid container spacing={2}>
                          <IconButton
                            style={{
                              position: "absolute",
                              fontSize: "18px",
                              top: "8px",
                              right: "5px",
                            }}
                            onClick={() => handleClose()}
                          >
                            <CloseIcon style={{ fontSize: "18px" }} />
                          </IconButton>
                          <Grid item xs={12} sm={12} md={4} lg={3}>
                            {/* <Typography variant="h6">Select User :</Typography> */}
                            <Autocomplete
                              disablePortal
                              id="combo-box-demo"
                              options={UserCode.map((option: any) => option)}
                              getOptionLabel={(options: any) => options.value}
                              onChange={(options: any, newValue: any) => {
                                setNewUserType(newValue ? newValue.code : "");
                              }}
                              renderInput={(params: any) => (
                                <TextField
                                  {...params}
                                  label="Select User Type"
                                  error={errors.userTypeCode ? true : false}
                                  helperText={
                                    errors.userTypeCode == undefined
                                      ? ""
                                      : errors.userTypeCode.message + ""
                                  }
                                  {...register(
                                    "userTypeCode",
                                    registerOptions.userTypeCode
                                  )}
                                />
                              )}
                            />
                          </Grid>

                          {NewUserType === "A" || NewUserType === "B" ? (
                            <></>
                          ) : (
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                              <Autocomplete
                                id="Select-user-Role"
                                options={[
                                  { label: "Individual", value: "INDIVIDUAL" },
                                  {
                                    label: "Company Head",
                                    value: "COMPANY_HEAD",
                                  },
                                  { label: "Group Head", value: "GROUP_HEAD" },
                                  { label: "Team Lead", value: "TEAM_LEAD" },
                                ].map((option: any) => option)}
                                getOptionLabel={(options: any) => options.label}
                                onChange={(options: any, newValue: any) => {
                                  setUserRole(newValue?.value);
                                  setRefresh(!refresh);
                                }}
                                renderInput={(params: any) => (
                                  <TextField
                                    {...params}
                                    label="Select Hierarchy Level"
                                    error={errors.userRole ? true : false}
                                    helperText={
                                      errors.userRole == undefined
                                        ? ""
                                        : errors.userRole.message + ""
                                    }
                                    {...register(
                                      "userRole",
                                      registerOptions.userRole
                                    )}
                                  />
                                )}
                              />
                            </Grid>
                          )}

                          {sessionStorage.getItem("Profile") == "A" &&
                          sessionStorage.getItem("BrokerMasterCode") == "1" ? (
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                              <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={
                                  brokerList &&
                                  brokerList.map((option: any) => option)
                                }
                                getOptionLabel={(options: any) =>
                                  options.brokerName
                                }
                                onChange={(options: any, newValue: any) => {
                                  setBrokerCode(newValue?.brokerMasterCode);
                                }}
                                renderInput={(params: any) => (
                                  <TextField
                                    {...params}
                                    label="Select Broker"
                                    error={
                                      errors.brokerMasterCode ? true : false
                                    }
                                    helperText={
                                      errors.brokerMasterCode == undefined
                                        ? ""
                                        : errors.brokerMasterCode.message + ""
                                    }
                                    {...register("brokerMasterCode")}
                                  />
                                )}
                              />
                            </Grid>
                          ) : (
                            <> </>
                          )}

                          {NewUserType == "D" ||
                          NewUserType == "E" ||
                          NewUserType == "M" ? (
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                              <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                getOptionLabel={(options: any) =>
                                  options.companyName
                                }
                                options={companyList.map(
                                  (option: any) => option
                                )}
                                onChange={(options: any, newValue: any) => {
                                  setCompanyCode(newValue?.companyMasterCode);
                                  setRefresh(!refresh);
                                }}
                                renderInput={(params: any) => (
                                  <TextField
                                    {...params}
                                    label="User Company"
                                    error={
                                      errors.companyMasterCode ? true : false
                                    }
                                    helperText={
                                      errors.companyMasterCode == undefined
                                        ? ""
                                        : errors.companyMasterCode.message + ""
                                    }
                                    {...register(
                                      "companyMasterCode",
                                      registerOptions.companyMasterCode
                                    )}
                                  />
                                )}
                              />
                            </Grid>
                          ) : (
                            <></>
                          )}

                          {NewUserType === "E" && (
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                              <Autocomplete
                                id="Select Employee"
                                options={employeeData.map(
                                  (option: any) => option
                                )}
                                getOptionLabel={(options: any) =>
                                  options.employeeCode
                                }
                                onChange={(options: any, newValue: any) => {
                                  setIndividualEmployeeCode(
                                    newValue?.employeeCode
                                  );
                                  reset();
                                  setRefresh(!refresh);
                                }}
                                fullWidth
                                renderInput={(params: any) => (
                                  <TextField
                                    {...params}
                                    label="Select Employee"
                                    error={errors.userTypeCode ? true : false}
                                    helperText={
                                      errors.userTypeCode == undefined
                                        ? ""
                                        : errors.userTypeCode.message + ""
                                    }
                                    // {...register("userTypeCode")}
                                  />
                                )}
                              />
                            </Grid>
                          )}

                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <TextField
                              id="outlined-basic"
                              label="First Name"
                              variant="outlined"
                              InputLabelProps={{
                                shrink:
                                  !!watch("firstName") ||
                                  !!individualEmployeeCode,
                              }}
                              // defaultValue={individualEmployeeData.firstName}
                              error={errors.firstName ? true : false}
                              helperText={
                                errors.firstName == undefined
                                  ? ""
                                  : errors.firstName.message + ""
                              }
                              fullWidth
                              {...register(
                                "firstName",
                                registerOptions.firstName
                              )}
                            />
                          </Grid>

                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <TextField
                              id="outlined-basic"
                              label="Last Name"
                              variant="outlined"
                              InputLabelProps={{
                                shrink:
                                  !!watch("lastName") ||
                                  !!individualEmployeeCode,
                              }}
                              error={errors.lastName ? true : false}
                              helperText={
                                errors.lastName == undefined
                                  ? ""
                                  : errors.lastName.message + ""
                              }
                              fullWidth
                              {...register(
                                "lastName",
                                registerOptions.lastName
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <TextField
                              id="outlined-basic"
                              label="Middle Name"
                              InputLabelProps={{
                                shrink:
                                  !!watch("middleName") ||
                                  !!individualEmployeeCode,
                              }}
                              variant="outlined"
                              error={errors.middleName ? true : false}
                              helperText={
                                errors.middleName == undefined
                                  ? ""
                                  : errors.middleName.message + ""
                              }
                              fullWidth
                              {...register(
                                "middleName",
                                registerOptions.middleName
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <TextField
                              id="outlined-basic"
                              label="Mobile No"
                              type="number"
                              InputLabelProps={{
                                shrink:
                                  !!watch("mobileNo") ||
                                  !!individualEmployeeCode,
                              }}
                              variant="outlined"
                              error={errors.mobileNo ? true : false}
                              helperText={
                                errors.mobileNo == undefined
                                  ? ""
                                  : errors.mobileNo.message + ""
                              }
                              fullWidth
                              {...register(
                                "mobileNo",
                                registerOptions.mobileNo
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <TextField
                              id="outlined-basic"
                              label="Email"
                              type="email"
                              InputLabelProps={{
                                shrink:
                                  !!watch("email") || !!individualEmployeeCode,
                              }}
                              variant="outlined"
                              error={errors.email ? true : false}
                              helperText={
                                errors.email == undefined
                                  ? ""
                                  : errors.email.message + ""
                              }
                              fullWidth
                              {...register("email", registerOptions.email)}
                            />
                          </Grid>

                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <TextField
                              id="outlined-basic"
                              label="Employee Code"
                              variant="outlined"
                              InputLabelProps={{
                                shrink:
                                  !!watch("employeeCode") ||
                                  !!individualEmployeeCode,
                              }}
                              error={errors.employeeCode ? true : false}
                              helperText={
                                errors.employeeCode == undefined
                                  ? ""
                                  : errors.employeeCode.message + ""
                              }
                              fullWidth
                              {...register(
                                "employeeCode",
                                registerOptions.employeeCode
                              )}
                            />
                          </Grid>

                          {NewUserType !== "A" &&
                            NewUserType !== "B" &&
                            UserRole !== "COMPANY_HEAD" && (
                              <Grid item xs={12} sm={6} md={4} lg={3}>
                                <Autocomplete
                                  id="Manager"
                                  options={NewUserProfile.map(
                                    (option: any) => option
                                  )}
                                  getOptionLabel={(options: any) =>
                                    options.employeeCode
                                  }
                                  onChange={(options: any, newValue: any) => {
                                    setEmployeeManagerUserCode(
                                      newValue?.employeeCode
                                    );
                                    setRefresh(!refresh);
                                  }}
                                  renderInput={(params: any) => (
                                    <TextField
                                      {...params}
                                      label="Select Manager"
                                      error={errors.manager ? true : false}
                                      helperText={
                                        errors.manager == undefined
                                          ? ""
                                          : errors.manager.message + ""
                                      }
                                      {...register(
                                        "manager",
                                        registerOptions.manager
                                      )}
                                    />
                                  )}
                                />
                              </Grid>
                            )}

                          {NewUserType == "B" ? (
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                              <Controller
                                control={control}
                                name="Products"
                                rules={{
                                  required: "Select A Product",
                                }}
                                render={({ field: { onChange } }) => (
                                  <MultiSelect
                                    items={ProductList}
                                    error={false}
                                    helperText={errors?.productsType?.message}
                                    label="Products"
                                    selectAllLabel="Select All"
                                    onChange={(e: any) => {
                                      setValue(
                                        "Products",
                                        e.map((i: any) => i.productTypeCode)
                                      );
                                      setproductlist(
                                        e.map((i: any) => i.productTypeCode)
                                      );
                                    }}
                                  />
                                )}
                              />
                            </Grid>
                          ) : (
                            <></>
                          )}

                          {NewUserType === "D" ||
                          NewUserType === "E" ||
                          NewUserType === "M" ? (
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                              <TextField
                                id="outlined-basic"
                                label="Manager's Mobile No."
                                type="number"
                                InputLabelProps={{
                                  shrink:
                                    !!watch("accountManager") ||
                                    !!individualEmployeeCode,
                                }}
                                error={errors.accountManager ? true : false}
                                helperText={
                                  errors.accountManager === undefined
                                    ? ""
                                    : errors.accountManager.message + ""
                                }
                                variant="outlined"
                                fullWidth
                                {...register(
                                  "accountManager",
                                  registerOptions.accountManager
                                )}
                              />
                            </Grid>
                          ) : (
                            <></>
                          )}

                          {NewUserType === "D" ||
                          NewUserType === "E" ||
                          NewUserType === "M" ? (
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                              <Autocomplete
                                multiple
                                id="combo-box-demo1"
                                getOptionLabel={(options: any) =>
                                  options.productName
                                }
                                options={CompanyWiseProduct.map((it: any) =>
                                  ProductList.filter(
                                    (i: any) =>
                                      parseInt(i.productTypeCode) ===
                                      parseInt(it.destinationValue)
                                  )
                                ).map((options: any) => options[0])}
                                onChange={(options: any, newValue: any) => {
                                  setproductlist(
                                    newValue.map((i: any) => i.productTypeCode)
                                  );
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Products"
                                    // error={ProductList?true:false}
                                    helperText={
                                      errors.products === undefined
                                        ? ""
                                        : errors.products.message + ""
                                    }
                                  />
                                )}
                              />
                            </Grid>
                          ) : (
                            <></>
                          )}

                          <Grid item xs={12}>
                            <div style={{ textAlign: "center", width: "100%" }}>
                              <Button
                                type="submit"
                                color="primary"
                                variant="contained"
                                sx={{ marginTop: "10px" }}
                                disabled={showCircularProgress}
                              >
                                {showCircularProgress === true ? (
                                  <CircularProgress
                                    color="secondary"
                                    size={20}
                                    thickness={5}
                                  />
                                ) : (
                                  ""
                                )}{" "}
                                Submit
                              </Button>
                            </div>
                          </Grid>
                        </Grid>
                      </Typography>
                    </Box>
                  </form>
                </Modal>
              </Box>
            </Box>
          </Box>
        </Container>
      </>
    )
  );
};

export default withParam(AdminDashboard);
