/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Autocomplete,
  Grid,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  TableBody,
  Link,
  Typography,
  InputBase,
  IconButton,
} from "@mui/material";
import React, { useState } from "react";
import { useQuery } from "react-query";
import {
  GetAllClient,
  GetAllUnlistedWithQuantity,
  GetBrokeragePayoutByCompanyMasterCode,
  GetByIdProductList,
  GetListOfUserByCompanyMasterCodeAndUserTypeCode,
  listOfManufactureNDistributorCompanies,
  product_listOfAllProductType,
} from "../../api/Api";
import TableHeadData from "../TableHeadData";
import SearchIcon from "@mui/icons-material/Search";

const OrderWindowForUnlisted = () => {
  const [companyMasterCode, setCompanyMasterCode] = useState();
  const [companyTypeCode, setCompanyTypeCode] = React.useState();
  const [distributorCode, setDistributorCode] = React.useState("");
  const [distributorUserCode, setDistributorUserCode] = React.useState("");
  const [unlistedID, setUnlistedID] = React.useState("");
  const [clientId, setClientId] = useState("");
  const [clientDetails, setClientDetails] = useState<any>("");
  // filtered clients data
  const [filteredClientsData, setFilteredClientsData] = useState([]);
  const [selectedClient, setSelectedClient] = useState<any>(null);
  const [selectedUser, setSelectedUser] = React.useState(null);

  const { data: distributorCompanyList } = useQuery(
    "ManufactureNDistributorCompanie",
    () => listOfManufactureNDistributorCompanies("D,E"),
    {
      enabled:
        sessionStorage.getItem("Profile") === "B" ||
        sessionStorage.getItem("Profile") === "E",
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  const { data: UnlistedByID } = useQuery(
    ["UnlistedProductListByID", unlistedID, companyMasterCode],
    () =>
      GetByIdProductList(unlistedID, "?companyMasterCode=" + companyMasterCode),
    {
      enabled: !!unlistedID,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  const { data: unlistedList } = useQuery(
    ["GetAllUnlistedWithQuantity", companyMasterCode],
    () =>
      GetAllUnlistedWithQuantity(
        (!!companyMasterCode && sessionStorage.getItem("Profile") === "B") ||
          (!!companyMasterCode && sessionStorage.getItem("Profile") === "E")
          ? "?companyMasterCode=" + companyMasterCode
          : ""
      ),
    {
      enabled:
        (sessionStorage.getItem("Profile") === "B" && !!companyMasterCode) ||
        (sessionStorage.getItem("Profile") === "E" && !!companyMasterCode) ||
        sessionStorage.getItem("Profile") === "D",
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  // const { data: UserProfilesByCompanyMasterCode } = useQuery(
  //   ["userProfileByCompany", companyMasterCode, companyTypeCode],
  //   () =>
  //     GetListOfUserByCompanyMasterCodeAndUserTypeCode(
  //       companyTypeCode === "S" ? "E" : companyTypeCode,
  //       companyMasterCode
  //     ),
  //   {
  //     enabled: !!companyMasterCode,
  //     keepPreviousData: false,
  //     refetchOnWindowFocus: false,
  //   }
  // );

  // LIST OF ALL PRODUCT TYPES
  const listOfAllProductType =
    sessionStorage.getItem("listOfAllProducts") ?? "";
  const listOfAllProductTypeParsed = JSON.parse(listOfAllProductType);

  // fetch UNLISTED product type code
  let productTypeCodeFromList = listOfAllProductTypeParsed?.filter(
    (productObj: any) => productObj.productName === "UNLISTED"
  )[0].productTypeCode;

  // FETCH BROKERAGE PAYOUT
  const { data: fetchBrokeragePayout } = useQuery(
    "GetBrokeragePayoutByCompanyMasterCode",
    () => GetBrokeragePayoutByCompanyMasterCode(companyMasterCode),
    {
      enabled: !!companyMasterCode,
      refetchOnWindowFocus: false,
    }
  );
  const filteredUnlistedBrokeragePayout =
    fetchBrokeragePayout?.data?.companyBrokerage?.filter(
      (brokerageObj: any) =>
        brokerageObj.productTypeCode === productTypeCodeFromList
    );
  // destructuring brokerage payout from the filtered array and assign null if the array is empty
  let brokeragePayout = filteredUnlistedBrokeragePayout?.length
    ? filteredUnlistedBrokeragePayout[0]
    : null;

  const data2 = useQuery(
    ["getAllClients", distributorCode],
    () => GetAllClient(companyMasterCode, distributorCode),
    {
      refetchOnWindowFocus: false,
    }
  );

  const handleSearchClientFromPAN = (e: any) => {
    const val = e.target.value;
    const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    if (val.length === 10 && panRegex.test(val)) {
      const filteredData = data2?.data?.data?.clients?.filter(
        (client: any) => client.panCardNo.toLowerCase() === val.toLowerCase()
      );
      setFilteredClientsData(filteredData);
    }
    // search by client name
    else if (val.length > 0) {
      const filteredClientData = data2?.data?.data?.clients?.filter(
        (client: any) =>
          client.fullName.toLowerCase().includes(val.toLowerCase())
      );
      setFilteredClientsData(filteredClientData);
    } else {
      setFilteredClientsData([]);
    }
  };

  return (
    <>
      <Typography variant="h5" textAlign={"center"}>
        {" "}
        Unlisted Order{" "}
      </Typography>
      <Grid container rowSpacing={1} columnSpacing={1}>
        {/* {sessionStorage.getItem("Profile") === "B" ||
        sessionStorage.getItem("Profile") === "E" ? (
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            style={{ paddingRight: "8px" }}
          >
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={
                distributorCompanyList &&
                distributorCompanyList?.data?.companies?.map(
                  (options: any) => options
                )
              }
              getOptionLabel={(options: any) =>
                options.companyName + " - " + options.companyTypeCode
              }
              onChange={(options: any, newValue) => {
                setCompanyMasterCode(
                  newValue ? newValue.companyMasterCode : ""
                );
                setCompanyTypeCode(newValue ? newValue.companyTypeCode : "");
                sessionStorage.setItem(
                  "CompanyData",
                  newValue?.companyMasterCode
                );
                sessionStorage.setItem(
                  "CompanyTypeCode",
                  newValue?.companyTypeCode
                );
                setUnlistedID("");
              }}
              renderInput={(params: any) => (
                <TextField {...params} label="Select Company" size={"small"} />
              )}
            />
          </Grid>
        ) : (
          <></>
        )} */}
        {/* // handle search clients from PAN number */}
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          style={{ display: "flex", alignItems: "center", padding: "12px" }}
        >
          {/* // Search Client from PAN Number */}
          <Paper
            component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: "100%",
              boxShadow: "none",
              border: "1px solid darkgrey",
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search Clients from Client Name & PAN No."
              onChange={handleSearchClientFromPAN}
            />
            <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
              <SearchIcon />
            </IconButton>
          </Paper>
        </Grid>
        {/* {unlistedList &&
          (sessionStorage.getItem("Profile") === "B" ||
          sessionStorage.getItem("Profile") === "E" ? (
            <>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={
                    UserProfilesByCompanyMasterCode
                      ? UserProfilesByCompanyMasterCode?.data?.userProfile?.map(
                          (options: any) => options
                        )
                      : []
                  }
                  getOptionLabel={(options: any) =>
                    options?.userCode +
                    "- " +
                    options?.firstName +
                    " " +
                    options?.lastName
                  }
                  onChange={(options: any, newValue) => {
                    setDistributorCode(newValue ? newValue.employeeCode : "");
                    setDistributorUserCode(newValue ? newValue.userCode : "");
                  }}
                  renderInput={(params: any) => (
                    <TextField {...params} label="Select User" size={"small"} />
                  )}
                />
              </Grid>
            </>
          ) : (
            <></>
          ))} */}

        {/* // SELECT CLIENT WITH COMPANY NAME */}
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          style={{ display: "flex", alignItems: "center", padding: "12px" }}
        >
          <Autocomplete
            sx={{ width: "100%" }}
            options={filteredClientsData}
            getOptionLabel={(option: any) =>
              option.companyName
                ? `${option.fullName} - ${option.companyName} - ${option.companyTypeCode}`
                : `${option.fullName} - ${option.contactPersonName} - ${option.companyTypeCode}`
            }
            // onChange={handleSelectClientDetails}
            onChange={(options: any, newValue) => {
              setCompanyMasterCode(newValue ? newValue.companyMasterCode : "");
              sessionStorage.setItem(
                "CompanyData",
                newValue?.companyMasterCode
              );
              sessionStorage.setItem(
                "CompanyTypeCode",
                newValue?.companyTypeCode
              );
              // setCompanyData(newValue);
              // set client details in session storage
              // sessionStorage.setItem(
              //   "selectedClientDetails",
              //   JSON.stringify(newValue)
              // );
              setSelectedClient(newValue);
              // set client id
              setClientId(newValue?.clientId);
            }}
            disableClearable
            renderInput={(params) => (
              <TextField {...params} label="Select Client" variant="outlined" />
            )}
          />
        </Grid>

        {unlistedList && (
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            style={{ display: "flex", alignItems: "center", padding: "12px" }}
          >
            <Autocomplete
              sx={{ width: "100%" }}
              disablePortal
              id="combo-box-demo"
              options={unlistedList?.data?.unlistedList?.map(
                (options: any) => options
              )}
              getOptionLabel={(options: any) => options.scriptName}
              onChange={(options: any, newValue) => {
                setUnlistedID(newValue?.unlistedMasterCode || "");
              }}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  label="Select Unlisted"
                  variant="outlined"
                />
              )}
            />
          </Grid>
        )}
      </Grid>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 1470 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center" sx={{ width: 150 }}>
                Order Date
              </TableCell>
              {/* manufacture cost price */}
              <TableCell align="center" sx={{ width: 150 }}>
                {sessionStorage.getItem("Profile") === "B"
                  ? "Manufacture Cost Price"
                  : "Buy Rate"}
              </TableCell>
              {/* <TableCell align="center" sx={{ width: 150 }}>
                Manufacture Carrying Cost
              </TableCell> */}
              {sessionStorage.getItem("Profile") === "B" && (
                <TableCell align="center" sx={{ width: 150 }}>
                  Manufacturer Landing Price
                </TableCell>
              )}
              <TableCell align="center" sx={{ width: 150 }}>
                Sell Price
              </TableCell>
              {/* <TableCell align='center' sx={{width:50}}>TCS (0.1%)</TableCell> */}
              <TableCell align="center">Total</TableCell>
              <TableCell align="center" sx={{ width: 150 }}>
                Bid Quantity
              </TableCell>
              <TableCell align="center" sx={{ width: 350 }}>
                Client
              </TableCell>
              {sessionStorage.getItem("Profile") === "E" &&
              sessionStorage.getItem("CompanyTypeCode") === "S" ? null : (
                <>
                  <TableCell align="center">RM Revenue</TableCell>
                  <TableCell align="center">Distributor Payout</TableCell>
                </>
              )}
              <TableCell align="center"></TableCell>
              <TableCell align="center" sx={{ width: 50 }}>
                Order
              </TableCell>
              {/* <TableCell align="center" sx={{ width: 50 }}>
                Add To Proposal
              </TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {UnlistedByID &&
              UnlistedByID.data.unlistedList.map((item: any) => (
                <TableHeadData
                  item={item}
                  distributorCode={distributorCode}
                  JV={""}
                  companyMasterCode={companyMasterCode}
                  distributorUserCode={distributorUserCode}
                  brokeragePayout={brokeragePayout ? brokeragePayout : null}
                  data2={data2}
                  clientId={clientId}
                  setClientId={setClientId}
                  clientDetails={clientDetails || selectedClient}
                  setClientDetails={setClientDetails}
                  setselectedUser={setSelectedUser}
                  selectedUser={selectedUser}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default OrderWindowForUnlisted;
