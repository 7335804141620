/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import Calendy from '../Calendy'
import InvestmentProd from '../InvestmentProd'
import BookACallForm from './BookACallForm';

const bookacallmain = () => {
  window.scrollTo(0, 0);
  return (
    <>
    <BookACallForm/>
    {/* <Calendy/> */}
    <InvestmentProd/>
    </>
  )
}

export default bookacallmain