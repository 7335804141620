/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Button,
  CircularProgress,
  Grid,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { product_ProductTypeByID } from "../api/Api";

const ProductTypeModel = (props: any) => {
  const ProductID = props.data;
  const [open, setOpen] = React.useState(false);
  const [action, setAction] = React.useState("");
  const [productTypeData, setProductTypeData] = React.useState<any>("");
  const [showCircularProgress, setshowCircularProgress] = useState(false);

  const resolver = async (values: any) => {
    return {
      values: !values.productName ? {} : values,
      errors: !values.productName
        ? {
            productName: {
              type: "required",
              message: "This is required.",
            },
          }
        : !values.description
        ? {
            description: {
              type: "required",
              message: "This is required.",
            },
          }
        : !values.gstValue
        ? {
            gstValue: {
              type: "required",
              message: "This is required.",
            },
          }
        : !values.tcsValue
        ? {
            tcsValue: {
              type: "required",
              message: "This is required.",
            },
          }
        : !values.stampDutyValue
        ? {
            stampDutyValue: {
              type: "required",
              message: "This is required.",
            },
          }
        : {},
    };
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: resolver,
  });

  const { data } = useQuery(
    ["ProductGetByID", ProductID],
    () => product_ProductTypeByID(ProductID),
    { enabled: false }
  );
  const onSubmit = (data: any) => {
    setshowCircularProgress(true);
    axios
      .post("product/saveProductType", data)
      .then(() => {
        setshowCircularProgress(false);
        setOpen(false);
        toast.success("Product Type added Successfully");
      })
      .catch((err: any) => {
        setshowCircularProgress(false);
        setOpen(false);
        console.log("err--", err);
        toast.error(
          err?.response?.data?.status?.message?.title
            ? err?.response?.data?.status?.message?.title
            : err?.response?.data?.title
        );
      });
  };

  const handleOpen = (id: any) => {
    if (id === "") {
      setAction("Create");
      setOpen(true);
    } else {
      setProductTypeData(id);
      setAction("Update");
      setOpen(true);
    }
  };
  const handleClose = () => {
    setProductTypeData(null);
    setAction("");
    setOpen(false);
  };

  return (
    <>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Product Type
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <Box
              component="form"
              sx={{ "& .MuiTextField-root": { m: 1, width: "30ch" } }}
              noValidate
              autoComplete="off"
            >
              <Grid container sx={{ justifyContent: "center" }}>
                <form>
                  <TextField
                    id={"outlined-basic" + props.data}
                    label="Product Name"
                    variant="outlined"
                    // value={props.data}
                    error={errors.productName ? true : false}
                    helperText={
                      errors.productName === undefined
                        ? ""
                        : errors.productName.message + ""
                    }
                    {...register("productName")}
                  />
                  <TextField
                    id={"outlined-basic" + props.data}
                    label="Description"
                    variant="outlined"
                    // value={props.data}
                    error={errors.description ? true : false}
                    helperText={
                      errors.description === undefined
                        ? ""
                        : errors.description.message + ""
                    }
                    {...register("description")}
                  />
                  <TextField
                    id={"outlined-basic" + props.data}
                    label="GST"
                    variant="outlined"
                    // value={props.data}
                    error={errors.gstValue ? true : false}
                    helperText={
                      errors.gstValue === undefined
                        ? ""
                        : errors.gstValue.message + ""
                    }
                    {...register("gstValue")}
                  />
                  <TextField
                    id={"outlined-basic" + props.data}
                    label="TCS"
                    variant="outlined"
                    // value={props.data}
                    error={errors.tcsValue ? true : false}
                    helperText={
                      errors.tcsValue === undefined
                        ? ""
                        : errors.tcsValue.message + ""
                    }
                    {...register("tcsValue")}
                  />
                  <TextField
                    id={"outlined-basic" + props.data}
                    label="Stamp Duty "
                    variant="outlined"
                    // value={props.data}
                    error={errors.stampDutyValue ? true : false}
                    helperText={
                      errors.stampDutyValue === undefined
                        ? ""
                        : errors.stampDutyValue.message + ""
                    }
                    {...register("stampDutyValue")}
                  />
                  <Stack
                    spacing={3}
                    direction="row"
                    justifyContent={"center"}
                    mt={3}
                  >
                    <Button
                      variant="contained"
                      type="submit"
                      onClick={handleSubmit(onSubmit)}
                    >
                      {showCircularProgress === true ? (
                        <CircularProgress
                          color="secondary"
                          size={20}
                          thickness={5}
                        />
                      ) : (
                        ""
                      )}{" "}
                      Submit
                    </Button>
                  </Stack>
                </form>
              </Grid>
            </Box>
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: "10px",
  maxHeight: "80%",
  overflowY: "auto",
};

export default ProductTypeModel;
