/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { SnackbarOrigin } from "@mui/material/Snackbar";
import {
  AppBar,
  Box,
  Collapse,
  Drawer,
  Grid,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Stack,
  useMediaQuery,
} from "@mui/material";
import {
  Button,
  CircularProgress,
  Menu,
  MenuItem,
  Modal,
  TextField,
} from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.min.css";
import logo from "../images/Neofindesk-Logo.png";
import "../css/navbar.css";
import {
  ExpandLess,
  ExpandMore,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import Fade from "@mui/material/Fade";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import OrderNow from "./OrderNow/OrderNow";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";

const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%",
  bgcolor: "background.paper",
  border: "1px solid #000",
  borderRadius: "20px",
  boxShadow: 24,
  p: 4,
  "& .MuiTextField-root": { m: 1 },
};

export interface State extends SnackbarOrigin {
  openSnack: boolean;
}

// Define your items in arrays
const productItemsNavbar = [
  { text: "Fixed Income", productNumber: "0" },
  { text: "Mutual Fund", productNumber: "1" },
  { text: "PMS", productNumber: "2" },
  { text: "AIF", productNumber: "3" },
  { text: "Research Services", productNumber: "4" },
  { text: "Unlisted", productNumber: "5" },
  { text: "Loan", productNumber: "6" },
  { text: "Insurance", productNumber: "7" },
  { text: "Non Convertible Debentures", productNumber: "8" },
  { text: "InvestKul", productNumber: "9" },
];

const Navbar = () => {
  const isMobile = useMediaQuery("(max-width:730px)");
  // use states
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [showCircularProgress, setshowCircularProgress] = useState(false);
  const [state, setState] = React.useState<State>({
    openSnack: false,
    vertical: "top",
    horizontal: "right",
  });
  // const { vertical, horizontal, openSnack } = state;
  const [authErrorMessage, setauthErrorMessage] = useState("");
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const [showPassword, setShowPassword] = React.useState(false);
  const [ForgotPasswordModel, setForgotPasswordModel] = React.useState(false);
  const [ForgotUserId, setForgotUserId] = React.useState<any>();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorE2, setAnchorE2] = React.useState<null | HTMLElement>(null);
  const [formValue, setFormValue] = useState({
    userName: sessionStorage.getItem("Pro")
      ? sessionStorage.getItem("Pro")
      : "",
    password: "",
  });
  const [formError, setFormError] = useState<any>({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const openMenu = Boolean(anchorEl);
  const [aboutUsOpen, setAboutUsOpen] = useState(false);
  const [productsOpen, setProductsOpen] = useState(false);

  // Handle closing the drawer only for items that don't have sub-items
  const handleDrawerClose = (hasSubItems: boolean) => {
    if (!hasSubItems) {
      setOpenDrawer(false);
    }
  };
  // Toggle "About Us" submenu in drawer
  const toggleAboutUs = () => {
    setAboutUsOpen(!aboutUsOpen);
  };
  // Toggle "Products" submenu in drawer
  const toggleProducts = () => {
    setProductsOpen(!productsOpen);
  };

  // Toggle drawer (open/close)
  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      setOpenDrawer(open);
    };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const openProductMenu = Boolean(anchorE2);
  const handleProductClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorE2(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
    setAnchorE2(null);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const userLogout = () => {
    axios.post("authentication/logout");
    sessionStorage.clear();
    window.location.href = "/";
  };

  function ProfileView() {
    window.location.href = "/selfProfile";
  }

  const initialValue = { userName: "", password: "" };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setFormError(validate(formValue));
    setIsSubmit(true);
    userLogin();
  };

  const validate = (values: any) => {
    const error: any = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.userName) {
      error.userName = "Username is required!";
    } else if (values.userName.length < 4) {
      error.userName = "User name is must be more then 4 word!";
    }
    if (!values.password) {
      error.password = "Password is required!";
    } else if (values.password.length < 4) {
      error.password = "Password must be more then 4!";
    }
    return error;
  };

  const userType = sessionStorage.getItem("Profile");

  let UserDashboardUrl = "";

  useEffect(() => {
    if (Object.keys(formError).length === 0 && isSubmit) {
      userLogin();
    }
  }, [isSubmit]);

  switch (userType) {
    case "A":
      UserDashboardUrl = "/UserDashBoard";
      break;
    case "B":
      UserDashboardUrl = "/BackOfficeDashBoard";
      break;
    case "D":
      UserDashboardUrl = "/DistributorDashBoard";
      break;
    case "M":
      UserDashboardUrl = "";
      break;
    case "E":
      UserDashboardUrl = "/employeeDashboard";
      break;
    default:
      UserDashboardUrl = "";
  }

  const userLogin = () => {
    if (Object.keys(formError).length === 0 && isSubmit) {
      var obj = formValue;
      setshowCircularProgress(true);
      axios
        .post("authentication", obj)
        .then((res) => {
          sessionStorage.setItem("JWT", res.data.jwt);
          sessionStorage.setItem("ForcePassword", res.data.isForcePassword);
        })
        .then(() =>
          axios.get("userProfile/profileView", {
            headers: {
              Authorization: sessionStorage.getItem("JWT"),
            },
          })
        )
        .then((res) => {
          sessionStorage.setItem("Profile", res.data.userProfile.userTypeCode);
          sessionStorage.setItem("Pro", res.data.userProfile.userCode);
          sessionStorage.setItem(
            "CompanyMasterCode",
            res.data.userProfile.companyMasterCode
          );
          sessionStorage.setItem(
            "BrokerMasterCode",
            res.data.userProfile.brokerMasterCode
          );
          sessionStorage.setItem(
            "LastLogin",
            res.data.lastLoginTime ? res.data.lastLoginTime : ""
          );
          sessionStorage.setItem("logoPath", res.data.logoPath);
          if (sessionStorage.getItem("ForcePassword") === "false") {
            window.location.href = "/ChangePassword";
          } else if (sessionStorage.getItem("Profile") === "D") {
            window.location.href = "/DistributorDashBoard";
          } else if (sessionStorage.getItem("Profile") === "M") {
            window.location.href = "/products";
          } else if (sessionStorage.getItem("Profile") === "B") {
            window.location.href = "/BackOfficeDashBoard";
          } else if (sessionStorage.getItem("Profile") === "E") {
            window.location.href = "/employeeDashboard";
          } else {
            window.location.href = "/UserDashboard";
          }
        })
        .catch((Err: any) => {
          toast.error(
            Err.response ? Err.response.data.code : "Something Went Wrong"
          );
          setshowCircularProgress(false);
          setState({ openSnack: true, vertical: "top", horizontal: "right" });
        });
    }
  };

  const date: any = sessionStorage.getItem("LastLogin");
  const dateAndTime = new Date(date);

  const userForgotPassword = () => {
    // eslint-disable-next-line no-lone-blocks
    {
      ForgotUserId &&
        axios
          .get("userProfile/forgetPassword/" + ForgotUserId)
          .then(() =>
            toast.success("Password send on your registered Email Id")
          )
          .catch(() => setauthErrorMessage("Something Went Wrong"));
      setState({ openSnack: true, vertical: "top", horizontal: "right" });
      setForgotPasswordModel(false);
    }
  };

  function clickPress(event: any) {
    if (event.key === "Enter") {
      handleSubmit(event);
    }
  }

  const handleProductClickSetItem = (productNumber: string) => {
    sessionStorage.setItem("ProductNumber", productNumber);
    window.location.href = "/products";
  };

  // automatically close the drawer when the screen size is >= 730px
  useEffect(() => {
    if (!isMobile) {
      setOpenDrawer(false);
    }
  }, [isMobile]);

  return (
    <>
      <AppBar
        component="nav"
        sx={{ backgroundColor: "#212529", zIndex: "12", height: "60px" }}
      >
        <Toolbar>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, textAlign: "left", color: "#00b7b7" }}
          >
            <Link to="/">
              {sessionStorage.getItem("logoPath") ? (
                <img
                  src={sessionStorage.getItem("logoPath") + ""}
                  className="nav_comp_logo"
                  alt="Neo Fin Desk"
                />
              ) : (
                <img src={logo} className="nav_logo" alt="Neo Fin Desk" />
              )}
            </Link>
          </Typography>

          {/* Hamburger menu icon for screens < 730px */}
          {isMobile ? (
            <Box>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={toggleDrawer(true)}
              >
                <MenuIcon />
              </IconButton>
            </Box>
          ) : (
            // Full menu for screens >= 730px
            <Box>
              {sessionStorage.getItem("JWT") && (
                <OrderNow variant="contained" sx={{ color: "" }} />
              )}
              {/* PRODUCTS */}
              {sessionStorage.getItem("JWT") && (
                <>
                  <Button
                    id="product-button"
                    aria-controls={openProductMenu ? "product-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openProductMenu ? "true" : undefined}
                    onClick={handleProductClick}
                    sx={{ color: "#fff" }}
                  >
                    Products <ArrowDropDownIcon />
                  </Button>
                  <Menu
                    id="product-menu"
                    MenuListProps={{
                      "aria-labelledby": "product-button",
                    }}
                    anchorEl={anchorE2}
                    open={openProductMenu}
                    onClose={handleCloseMenu}
                    TransitionComponent={Fade}
                  >
                    {sessionStorage.getItem("Profile") !== "D" ? (
                      <>
                        <MenuItem
                          onClick={() => {
                            sessionStorage.setItem("ProductNumber", "0");
                            window.location.href = "/products";
                          }}
                        >
                          Fixed Income
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            sessionStorage.setItem("ProductNumber", "1");
                            window.location.href = "/products";
                          }}
                        >
                          Mutual Fund
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            sessionStorage.setItem("ProductNumber", "2");
                            window.location.href = "/products";
                          }}
                        >
                          PMS
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            sessionStorage.setItem("ProductNumber", "3");
                            window.location.href = "/products";
                          }}
                        >
                          AIF
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            sessionStorage.setItem("ProductNumber", "4");
                            window.location.href = "/products";
                          }}
                        >
                          Research Services
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            sessionStorage.setItem("ProductNumber", "5");
                            window.location.href = "/products";
                          }}
                        >
                          Unlisted
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            sessionStorage.setItem("ProductNumber", "6");
                            window.location.href = "/products";
                          }}
                        >
                          Loan
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            sessionStorage.setItem("ProductNumber", "7");
                            window.location.href = "/products";
                          }}
                        >
                          Insurance
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            sessionStorage.setItem("ProductNumber", "8");
                            window.location.href = "/products";
                          }}
                        >
                          Non Convertible Debentures
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            sessionStorage.setItem("ProductNumber", "9");
                            window.location.href = "/products";
                          }}
                        >
                          InvestKul
                        </MenuItem>
                      </>
                    ) : (
                      <>
                        <MenuItem
                          onClick={() => {
                            sessionStorage.setItem("ProductNumber", "0");
                            window.location.href = "/products";
                          }}
                        >
                          Mutual Fund
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            sessionStorage.setItem("ProductNumber", "1");
                            window.location.href = "/products";
                          }}
                        >
                          PMS
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            sessionStorage.setItem("ProductNumber", "2");
                            window.location.href = "/products";
                          }}
                        >
                          AIF
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            sessionStorage.setItem("ProductNumber", "3");
                            window.location.href = "/products";
                          }}
                        >
                          Research Services
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            sessionStorage.setItem("ProductNumber", "4");
                            window.location.href = "/products";
                          }}
                        >
                          Unlisted
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            sessionStorage.setItem("ProductNumber", "5");
                            window.location.href = "/products";
                          }}
                        >
                          Loan
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            sessionStorage.setItem("ProductNumber", "6");
                            window.location.href = "/products";
                          }}
                        >
                          Insurance
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            sessionStorage.setItem("ProductNumber", "7");
                            window.location.href = "/products";
                          }}
                        >
                          Non Convertible Debentures
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            sessionStorage.setItem("ProductNumber", "8");
                            window.location.href = "/products";
                          }}
                        >
                          InvestKul
                        </MenuItem>
                      </>
                    )}
                  </Menu>
                </>
              )}
              <Button
                id="fade-button"
                aria-controls={openMenu ? "fade-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openMenu ? "true" : undefined}
                onClick={handleClick}
                sx={{ color: "#fff" }}
              >
                About Us <ArrowDropDownIcon />
              </Button>
              <Menu
                id="fade-menu"
                MenuListProps={{
                  "aria-labelledby": "fade-button",
                }}
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleCloseMenu}
                TransitionComponent={Fade}
              >
                <MenuItem onClick={() => (window.location.href = "/whyus")}>
                  Why Us
                </MenuItem>
                <MenuItem onClick={() => (window.location.href = "/#Teams")}>
                  Team
                </MenuItem>
              </Menu>
              {sessionStorage.getItem("JWT") && !!UserDashboardUrl ? (
                <Link
                  to={UserDashboardUrl}
                  style={{
                    color: "#fff",
                    padding: "6px 8px",
                    fontSize: "0.875rem",
                    fontWeight: 500,
                  }}
                >
                  Dashboard
                </Link>
              ) : (
                ""
              )}
              {sessionStorage.getItem("JWT") ? (
                <>
                  <Button onClick={handleOpenUserMenu} sx={{ color: "#fff" }}>
                    {sessionStorage.getItem("Pro") +
                      " - " +
                      sessionStorage.getItem("Profile")}
                  </Button>
                  <Box>
                    <Menu
                      sx={{ mt: "45px", color: "#fff" }}
                      id="menu-appbar"
                      anchorEl={anchorElUser}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      open={Boolean(anchorElUser)}
                      onClose={handleCloseUserMenu}
                    >
                      <MenuItem onClick={ProfileView}>
                        <Typography textAlign="center">Profile</Typography>
                      </MenuItem>
                      <MenuItem onClick={userLogout}>
                        <Typography textAlign="center">Logout</Typography>
                      </MenuItem>
                      <Link
                        to={"/ChangePassword"}
                        // underline="none"
                        color="black"
                      >
                        <MenuItem>
                          <Typography textAlign="center">
                            Change Password
                          </Typography>
                        </MenuItem>
                      </Link>
                      <MenuItem disabled>
                        <Typography textAlign="center">
                          Last Login : {dateAndTime.toLocaleString("en-US")}
                        </Typography>
                      </MenuItem>
                    </Menu>
                  </Box>
                </>
              ) : (
                <Button sx={{ color: "#fff" }} onClick={handleOpen}>
                  Login
                </Button>
              )}
            </Box>
          )}
        </Toolbar>
      </AppBar>

      {/* Drawer component for the hamburger menu */}
      <Drawer
        anchor="top"
        open={openDrawer}
        onClose={toggleDrawer(false)}
        // sx={{ width: 250 }}
        sx={{
          height: "calc(100% - 64px)", // Adjust height as needed
          top: "64px", // Adjust top position to match AppBar height
          width: "100%",
        }}
      >
        <Box
          // sx={{ width: 250 }}
          role="presentation"
          // onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <List>
            {/* // Order Now button */}
            {sessionStorage.getItem("JWT") && (
              <ListItem>
                <OrderNow variant="contained" sx={{ color: "" }} />
              </ListItem>
            )}
            {/* "Products" section with collapsible sublist */}
            {sessionStorage.getItem("JWT") && (
              <>
                <ListItem button onClick={toggleProducts}>
                  <ListItemText primary="Products" />
                  {productsOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={productsOpen} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {productItemsNavbar.map(({ text, productNumber }) => (
                      <ListItem
                        key={productNumber}
                        button
                        sx={{ pl: 3 }}
                        // onClick={() => handleProductClickSetItem(productNumber)}
                        onClick={() => handleDrawerClose(true)}
                      >
                        <ListItemText>
                          <Button
                            onClick={() =>
                              handleProductClickSetItem(productNumber)
                            }
                            sx={{
                              color: "#00d5d5",
                              textDecorationColor: "rgba(0, 213, 213, 0.4)",
                              font: "inherit",
                              margin: "0",
                              paddingLeft: 0,
                              justifyContent: "flex-start",
                              fontSize: "14px",
                            }}
                          >
                            {text}
                          </Button>
                        </ListItemText>
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              </>
            )}
            {/* "About Us" section with collapsible sublist */}
            <ListItem button onClick={toggleAboutUs}>
              <ListItemText primary="About Us" />
              {aboutUsOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={aboutUsOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  button
                  sx={{ pl: 4 }}
                  onClick={() => handleDrawerClose(true)}
                >
                  <ListItemText>
                    <Link
                      to="/whyus"
                      // sx={{ textDecoration: "none" }}
                      style={{ textDecoration: "none" }}
                    >
                      Why Us
                    </Link>
                  </ListItemText>
                </ListItem>
                <ListItem
                  button
                  sx={{ pl: 4 }}
                  onClick={() => handleDrawerClose(true)}
                >
                  <ListItemText>
                    <Link
                      to="/#Teams"
                      // sx={{ textDecoration: "none" }}
                      style={{ textDecoration: "none" }}
                    >
                      Team
                    </Link>
                  </ListItemText>
                </ListItem>
              </List>
            </Collapse>
            {/* // DASHBOARD button */}
            {sessionStorage.getItem("JWT") && !!UserDashboardUrl ? (
              <ListItem>
                <Button
                  href={UserDashboardUrl}
                  sx={{
                    color: "inherit",
                    textDecorationColor: "rgba(0, 213, 213, 0.4)",
                    font: "inherit",
                    margin: "0",
                    paddingLeft: 0,
                    justifyContent: "flex-start",
                  }}
                >
                  Dashboard
                </Button>
              </ListItem>
            ) : (
              ""
            )}
            {/* <ListItem button>
              <ListItemText>
                <Link onClick={userLogout}>Logout</Link>
              </ListItemText>
            </ListItem> */}
            {/* // if session storage is empty then show login button else show logout button */}
            {sessionStorage.getItem("JWT") ? (
              <ListItem button onClick={userLogout}>
                <ListItemText primary="Logout" />
              </ListItem>
            ) : (
              <ListItem button sx={{ paddingLeft: "8px" }}>
                <ListItemText>
                  <Button
                    sx={{
                      color: "inherit",
                      textDecorationColor: "rgba(0, 213, 213, 0.4)",
                      font: "inherit",
                      margin: "0",
                      paddingLeft: "8px",
                      justifyContent: "flex-start",
                    }}
                    onClick={handleOpen}
                  >
                    Login
                  </Button>
                </ListItemText>
              </ListItem>
            )}
          </List>
        </Box>
      </Drawer>

      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
            Login
          </Typography>
          <form>
            <Box>
              <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
                <TextField
                  id="outlined-basic"
                  label="User ID"
                  variant="outlined"
                  fullWidth
                  value={formValue.userName}
                  name="userName"
                  onChange={handleChange}
                  error={formError.userName}
                  helperText={formError.userName}
                />

                <TextField
                  id="outlined-basic"
                  label="Password"
                  variant="outlined"
                  fullWidth
                  value={formValue.password}
                  name="password"
                  onKeyPress={(event: any) => clickPress(event)}
                  type={showPassword ? "text" : "password"}
                  onChange={handleChange}
                  error={formError.password}
                  helperText={formError.password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Typography>
              <Stack direction="row" spacing={2}>
                <Button
                  variant="contained"
                  onClick={handleSubmit}
                  disabled={showCircularProgress}
                >
                  {" "}
                  {showCircularProgress === true ? (
                    <CircularProgress
                      color="secondary"
                      size={20}
                      thickness={5}
                    />
                  ) : (
                    ""
                  )}{" "}
                  Login
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    setForgotPasswordModel(true);
                  }}
                  disabled={showCircularProgress}
                >
                  Forgot password
                </Button>
              </Stack>
            </Box>
          </form>
        </Box>
      </Modal>
      <Modal
        open={ForgotPasswordModel}
        onClose={() => setForgotPasswordModel(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid
            container
            rowSpacing={2}
            columnSpacing={2}
            alignContent={"center"}
            alignItems={"center"}
            textAlign={"center"}
          >
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography variant="h5"> Forgot Password </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextField
                id="outlined-basic"
                label="Please Enter User Id"
                variant="outlined"
                fullWidth
                sx={{ maxWidth: 300 }}
                onChange={(event) => setForgotUserId(event.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Button variant="contained" onClick={userForgotPassword}>
                {" "}
                Send Password{" "}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default Navbar;
