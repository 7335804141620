/* eslint-disable no-unreachable */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box,
  Button,
  Link,
  FormControlLabel,
  Modal,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Grid,
  Container,
  Autocomplete,
  CircularProgress,
  TablePagination,
  IconButton,
  InputBase,
} from "@mui/material";
import { Stack } from "@mui/system";
import axios from "axios";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import {
  GetAllClient,
  GetListOfUserByCompanyMasterCodeAndUserTypeCode,
  listOfManufactureNDistributorCompanies,
} from "../../api/Api";
import States from "../../Constants/States";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs, { Dayjs } from "dayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import ImportClient from "../ImportClient";
import { useDispatch } from "react-redux";
import { getQuarterDates } from "../../utils/Helper";
import CloseIcon from "@mui/icons-material/Close";
import ClientsAddModal from "../ClientsAddModal";

const EmployeeClientData = () => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [Clients, setClients] = useState<any>([]);
  const [openImportModal, setOpenImportModal] = useState(false);
  const [nomineePancard, setNomineePancard] = useState<any>();
  const [cancelCheque, setCancelCheque] = useState<any>();
  const [profileImage, setProfileImage] = useState<any>();
  const [aadhaarCard, setAadhaarCard] = useState<any>();
  const [selectedCountry, setSelectedCountry] = useState<any>(null);
  const [selectedState, setSelectedState] = useState<any>(null);
  const [showCircularProgress, setshowCircularProgress] = useState(false);
  const [CMLdocument, setCMLdocument] = useState<any>();
  const [value, setValueDate] = React.useState<Dayjs | null | any>(
    dayjs("2022-04-07")
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOrder, setSortOrder] = useState("asc"); // "asc" or "desc"
  const [userType, setUserType] = useState("");
  // state for RM NAME
  const [rmName, setRmName] = React.useState("");
  // state for RM EMPLOYEE CODE
  const [rmEmployeeCode, setRmEmployeeCode] = React.useState("");
  const [filteredClientsData, setFilteredClientsData] = useState([]);
  const [selectedClient, setSelectedClient] = useState<any>({
    fullName: "",
    mobileNo: "",
    emailId: "",
    panCardNo: "",
    aadhaarCardNo: "",
    addressLine1: "",
    addressLine2: "",
    addressLine3: "",
    city: "",
    pinCode: "",
    clientCode: "",
    bankName: "",
    branchName: "",
    accountNo: "",
    ifscCode: "",
    dpName: "",
    dpId: "",
    rmName: "",
    rmBranch: "",
    rmBranchCode: "",
    rmEmpCode: "",
  });
  const [filteredDistributor, setFilteredDistributor] = useState([]);

  const { data: ListOfCompany } = useQuery(
    "ListOfManuAndDistList",
    () => listOfManufactureNDistributorCompanies("D"),
    {
      enabled: sessionStorage.getItem("Profile") === "B",
      refetchOnWindowFocus: false,
    }
  );

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = useState(0);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClose = () => setOpen(false);

  const handleSearch = (event: any) => {
    setSearchTerm(event.target.value);
    // If the search term is empty, reset the page to 0, otherwise, keep the current page
    if (event.target.value === "") {
      setPage(0);
    }
  };

  const handleSort = () => {
    // Toggle between "asc" and "desc" when clicking on the sorting button
    setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    watch,
  } = useForm();

  const onSubmit = (data: any) => {
    setshowCircularProgress(true);
    const formData: any = new FormData();
    formData.append("panCardDocument", nomineePancard);
    formData.append("cancelCheque", cancelCheque);
    formData.append("profileImage", profileImage);
    formData.append("aadhaarCardDocument", aadhaarCard);
    formData.append("clientMasterList", CMLdocument);
    formData.append("clientDto", JSON.stringify(data));
    axios
      .post("client/saveClient", formData)
      .then(() => toast.success("Client Added Successfully"))
      .then(() => {
        setshowCircularProgress(false);
        setOpen(false);
        window.location.reload();
      })
      .catch((err: any) => {
        setshowCircularProgress(false);
        toast.error(
          err.response.data ? err.response.data.status.message.title : "Error"
        );
      });
  };

  const { data } = useQuery("getAllClients", () => GetAllClient("", ""), {
    onSuccess: (data: any) => {
      const newData =
        data &&
        data.data.clients.map((item: any, index: any) => {
          return { ...item, SrNo: index + 1 };
        });
      setClients(newData);
    },
    refetchOnWindowFocus: false,
  });

  const createdAtCount: any = {};
  Clients.forEach((item: any) => {
    const createdAt = item?.createdAt?.split("T")[0];
    if (createdAt) {
      if (createdAtCount[createdAt]) {
        createdAtCount[createdAt]++;
      } else {
        createdAtCount[createdAt] = 1;
      }
    }
  });

  const startYear = new Date().getFullYear();
  const endYear = new Date().getFullYear();
  const startMonth = 1;
  const endMonth = 12;

  const monthData: any = {};
  for (let year = startYear; year <= endYear; year++) {
    for (let month = startMonth; month <= endMonth; month++) {
      const monthKey = `${year}-${String(month).padStart(2, "0")}`;
      monthData[monthKey] = 0;
    }
  }

  // Fill in the month data from your JSON
  Clients.forEach((entry: any) => {
    if (entry.createdAt) {
      const date = new Date(entry.createdAt);
      const year = date.getFullYear();
      const month = date.getMonth() + 1; // Months are zero-indexed, so add 1
      const monthKey = `${year}-${String(month).padStart(2, "0")}`;
      if (monthData[monthKey] !== undefined) {
        monthData[monthKey]++;
      }
    }
  });

  function getPreviousQuarterDates() {
    const currentDate = new Date();
    const currentQuarter = Math.ceil((currentDate.getMonth() + 1) / 3);
    const startMonth = (currentQuarter - 2) * 3;
    const startDate = new Date(currentDate.getFullYear(), startMonth, 1);
    const endMonth = (currentQuarter - 1) * 3 - 1;
    const lastDay = new Date(
      currentDate.getFullYear(),
      endMonth + 1,
      0
    ).getDate();
    const endDate = new Date(currentDate.getFullYear(), endMonth, lastDay);
    return {
      quarterStartDate: startDate.toISOString().split("T")[0],
      quarterEndDate: endDate.toISOString().split("T")[0],
    };
  }

  let quarterWiseData: any = {};
  let currMonthWiseData: any = {};
  let yearlyData: any = {};
  let prevQuarterData: any = {};
  let ytdDataClient: any = {};

  Clients &&
    Clients.forEach((item: any, index: any) => {
      const tradeDate = item?.createdAt?.split("T")[0];
      if (tradeDate) {
        const startDateStr = new Date(getQuarterDates()?.quarterStartDate);
        const endDateStr = new Date(getQuarterDates()?.quarterEndDate);
        const prevQuarterStartDate = new Date(
          getPreviousQuarterDates()?.quarterStartDate
        );
        const prevQuarterEndDate = new Date(
          getPreviousQuarterDates()?.quarterEndDate
        );

        const dateToCheck = new Date(tradeDate);
        // Check if the date is within the current quarter
        if (dateToCheck >= startDateStr && dateToCheck <= endDateStr) {
          quarterWiseData[index] = item;
        }
        // Check if the date is in the current month
        const currMonth = new Date().getMonth() + 1;
        if (currMonth === dateToCheck.getMonth() + 1) {
          currMonthWiseData[index] = item;
        } else {
          currMonthWiseData[index] = 0; // Set to 0 if no response is obtained
        }
        // Check if the date is within the current year
        const currentYear = new Date().getFullYear();
        if (dateToCheck.getFullYear() === currentYear) {
          yearlyData[index] = item;
        } else {
          yearlyData[index] = 0; // Set to 0 if no response is obtained
        }

        // Check if the date is within the previous quarter
        if (
          dateToCheck >= prevQuarterStartDate &&
          dateToCheck <= prevQuarterEndDate
        ) {
          prevQuarterData[index] = item;
        }
        // else {
        //   prevQuarterData[index] = 0;
        // }

        // Calculate YTD start and end dates dynamically
        const currentYearStart = new Date(currentYear, 3 - 1, 1); // April 1
        const currentYearEnd = new Date(currentYear + 1, 3 - 1, 31); // March 31

        if (dateToCheck >= currentYearStart && dateToCheck <= currentYearEnd) {
          ytdDataClient[index] = item;
        } else {
          ytdDataClient[index] = 0; // Set to 0 if no response is obtained
        }
      }
    });

  dispatch({ type: "quarterWiseData", payload: quarterWiseData });
  dispatch({ type: "currMonthWiseData", payload: currMonthWiseData });
  dispatch({ type: "yearlyDatacount", payload: yearlyData });
  dispatch({ type: "prevQuarterData", payload: prevQuarterData });
  dispatch({ type: "ytdDataClient", payload: ytdDataClient });

  dispatch({ type: "MonthlyClientCount", payload: monthData });
  dispatch({ type: "AddClientCount", payload: createdAtCount });
  dispatch({ type: "TotalClientCount", payload: Clients.length });

  // const { data: UserProfilesByCompanyMasterCode } = useQuery(
  //   ["userProfileByCompany", watch("companyMasterCode")],
  //   () =>
  //     GetListOfUserByCompanyMasterCodeAndUserTypeCode(
  //       "D",
  //       getValues("companyMasterCode")
  //     ),
  //   {
  //     enabled: !!getValues("companyMasterCode"),
  //     refetchOnWindowFocus: false,
  //   }
  // );

  const filteredClients = Clients.filter((item: any) => {
    return (
      item?.fullName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item?.panCardNo?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item?.clientCode?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  // Sorting function based on Clients Name
  const sortedClients = [...filteredClients].sort((a, b) => {
    const nameA = a.fullName.toLowerCase();
    const nameB = b.fullName.toLowerCase();

    if (sortOrder === "asc") {
      return nameA.localeCompare(nameB);
    } else {
      return nameB.localeCompare(nameA);
    }
  });

  return (
    <Container maxWidth="xl">
      <Box sx={{ "& .MuiTextField-root": { m: 1, width: "25ch" } }}>
        <Grid container gap={2}>
          <Grid
            item
            md={12}
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Paper
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: 400,
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search Client by name or Pan Card or Client Code"
                onChange={handleSearch}
              />
              <IconButton
                type="button"
                sx={{ p: "10px" }}
                aria-label="search"
              ></IconButton>
            </Paper>
            {/* <Button variant="contained" onClick={() => setOpenImportModal(true)}>
		  Import Client
		</Button> */}
            <Button variant="contained" onClick={() => setOpen(true)}>
              Add Client
            </Button>
          </Grid>
        </Grid>

        <TableContainer component={Paper} sx={{ mt: "2%" }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Sr No.</TableCell>
                <TableCell onClick={handleSort} style={{ cursor: "pointer" }}>
                  Client Name {sortOrder === "asc" ? "▲" : "▼"}
                </TableCell>
                <TableCell align="right">Company Name</TableCell>
                <TableCell align="right">Company Email Id</TableCell>
                <TableCell align="right">Mobile No</TableCell>
                <TableCell align="right">Client Code</TableCell>
                {/* <TableCell align="right">Status</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedClients
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item: any, index: any) => {
                  return (
                    <Link
                      key={item.clientId}
                      display={"table-row"}
                      sx={{
                        textDecoration: "none",
                        "&:hover": { backgroundColor: "lightgray" },
                      }}
                      href={"/client/" + item.clientId}
                    >
                      <TableCell align="left">
                        {page * rowsPerPage + index + 1}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {item.fullName}
                      </TableCell>
                      <TableCell align="right">{item.companyName}</TableCell>
                      <TableCell align="right">{item.emailId}</TableCell>
                      <TableCell align="right">{item.mobileNo}</TableCell>
                      <TableCell align="right">{item.clientCode}</TableCell>
                    </Link>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 30, 50]}
          component="div"
          // count={Clients ? Clients.length : 0}
          count={sortedClients ? sortedClients.length : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        {/* ADD CLIENT MODAL */}
        <ClientsAddModal
          open={open}
          setOpen={setOpen}
          handleClose={handleClose}
          Clients={Clients}
          filteredClientsData={filteredClientsData}
          setFilteredClientsData={setFilteredClientsData}
          selectedClient={selectedClient}
          setSelectedClient={setSelectedClient}
          rmName={rmName}
          setRmName={setRmName}
          rmEmployeeCode={rmEmployeeCode}
          setRmEmployeeCode={setRmEmployeeCode}
          userType={userType}
          setUserType={setUserType}
          ListOfCompany={ListOfCompany && ListOfCompany}
          setFilteredDistributor={setFilteredDistributor}
          States={States}
          selectedCountry={selectedCountry}
          setSelectedCountry={setSelectedCountry}
          selectedState={selectedState}
          setSelectedState={setSelectedState}
          value={value}
          setValueDate={setValueDate}
        />
      </Box>
      <ImportClient
        open={openImportModal}
        onClose={() => setOpenImportModal(false)}
      />
    </Container>
  );
};

export const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: "10px",
  maxHeight: "80%",
  overflowY: "auto",
};

export default EmployeeClientData;
