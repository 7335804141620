/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unreachable */
import {
  Autocomplete,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  InputBase,
  Modal,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Button } from "@mui/material";
import { Container } from "@mui/material";
import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Controller, useForm } from "react-hook-form";
import { withParam } from "../utils/Router.Helper";
import { toast } from "react-toastify";
import States from "../Constants/States";
import MultiSelect from "../utils/MultiSelect";
import MultiSelectComp from "../utils/MultiSelectComp";
import CompanyCircularProgress from "./CompanyCircularProgress";
import CloseIcon from "@mui/icons-material/Close";
import { GstRegex } from "../utils/Regex";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { set } from "date-fns";
import { Link } from "react-router-dom";
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const CompanyManagement = () => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // width: 400,
    bgcolor: "background.paper",
    border: "1px solid #000",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
    "& .MuiTextField-root": { m: 1 },
    overflowY: "auto",
    maxHeight: "80%",
    width: "90%",
  };

  const [open, setOpen] = React.useState(false);
  const [getCompanyByType, setgetCompanyByType] = useState<any>([]);
  const [products, setProducts] = useState<any>([]);
  const [CompanyType, setCompanyType] = useState("D");
  const [NewCompanyType, setNewCompanyType] = useState("");
  const [ProductTypeCode, setProductTypeCode] = useState([]);
  const [userCode, setUserCode] = useState<any>(sessionStorage.getItem("Pro"));
  const [companyData, setCompanyData] = useState<any>([]);
  const [selectedCountry, setSelectedCountry] = useState<any>(null);
  const [selectedState, setSelectedState] = useState<any>(null);
  const [registerOfCertificatefile, setregisterOfCertificatefile] =
    useState<any>();
  const [gstNumberDocfile, setgstNumberDocfile] = useState<any>();
  const [pancardDocfile, setpancardDocfile] = useState<any>();
  const [cancelChequefile, setcancelChequefile] = useState<any>();
  const [companyLogofile, setcompanyLogofile] = useState<any>();
  const [showCircularProgress, setshowCircularProgress] = useState(false);
  const [CompanyList, setCompanyList] = useState([]);
  const [companyList, setcompanyList] = useState([]);
  const [bankDetails, setBankDetails] = useState([]);
  const [refresh, setRefresh] = useState(true);
  const [employeeCompanyList, setEmployeeCompanyList] = useState([]);
  const [empCompanyCode, setEmpCompanyCode] = useState();
  const [employeeData, setEmployeeData] = useState([]);
  const [employee, setEmployee] = useState(null);
  const [isChecked, setIsChecked] = useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOrder, setSortOrder] = useState("asc"); // "asc" or "desc"
  // FROM DATE AND TO DATE
  const [fromDate, setFromDate] = React.useState<any>(
    dayjs().subtract(6, "month").format("YYYY-MM-DD")
  );
  const [toDate, setToDate] = React.useState<any>(dayjs().format("YYYY-MM-DD"));
  const [compTypeValue, setCompTypeValue] = useState<any>("INDIVIDUAL");
  const isSmallScreen = useMediaQuery("(max-width:1085px)");
  // setting default value while entering the company name in the first name field
  const [companyName, setCompanyName] = useState(
    companyData?.companyName || ""
  );
  const [firstName, setFirstName] = useState(companyData?.firstName || "");
  const [lastName, setLastName] = useState(companyData?.lastName || "");
  const [phoneNum, setPhoneNum] = useState(companyData?.phoneNo || "");
  const [emaiID, setEmailID] = useState(companyData?.emailId || "");

  const resolver = async (values: any) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneNoRegex = /^[0-9]{10}$/;
    const GstRegex = (gst: string) =>
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/.test(gst);

    const errors: any = {};

    if (!values.companyName) {
      errors.companyName = {
        type: "required",
        message: "This is required.",
      };
    }

    if (values.compType === "CORPORATE" && !values.cinNumber) {
      errors.cinNumber = {
        type: "required",
        message: "Enter CIN Number.",
      };
    }

    if (values.compType === "CORPORATE" && !values.gstNumber) {
      errors.gstNumber = {
        type: "required",
        message: "Enter GST Number.",
      };
    }

    if (
      values.compType === "CORPORATE" &&
      values.gstNumber !== "" &&
      !GstRegex(values.gstNumber)
    ) {
      errors.gstNumber = {
        type: "pattern",
        message: "Enter a valid GST Number.",
      };
    }

    if (!values.pancard) {
      errors.pancard = {
        type: "required",
        message: "This is required.",
      };
    }

    if (!values.emailId) {
      errors.emailId = {
        type: "required",
        message: "This is required.",
      };
    } else if (!emailRegex.test(values.emailId)) {
      errors.emailId = {
        type: "pattern",
        message: "Invalid Email Id format.",
      };
    }

    if (!values.phoneNo) {
      errors.phoneNo = {
        type: "required",
        message: "Enter Phone No.",
      };
    } else if (!phoneNoRegex.test(values.phoneNo)) {
      errors.phoneNo = {
        type: "pattern",
        message:
          "Invalid phone number. Please enter only numbers till 10-digits",
      };
    }

    if (!values.noOfUser) {
      errors.noOfUser = {
        type: "required",
        message: "This is required.",
      };
    } else if (parseInt(values.noOfUser) <= 0) {
      errors.noOfUser = {
        type: "validate",
        message: "Number of Users should be greater than 0",
      };
    }

    if (!values.country) {
      errors.country = {
        type: "required",
        message: "This is required.",
      };
    }

    if (!values.state) {
      errors.state = {
        type: "required",
        message: "This is required.",
      };
    }

    if (!values.city) {
      errors.city = {
        type: "required",
        message: "Enter City",
      };
    } else if (!/^[A-Za-z\s]+$/.test(values.city)) {
      errors.city = {
        type: "pattern",
        message: "City should only contain alphabets.",
      };
    }

    if (!values.addressLine1) {
      errors.addressLine1 = {
        type: "required",
        message: "This is required.",
      };
    }
    if (!values.addressLine2) {
      errors.addressLine2 = {
        type: "required",
        message: "This is required.",
      };
    }
    if (!values.pin) {
      errors.pin = {
        type: "required",
        message: "This is required.",
      };
    }
    if (ProductTypeCode.length === 0) {
      errors.productsType = {
        type: "required",
        message: "This is required.",
      };
    }
    // BANK DETAILS
    if (!values.bankName) {
      errors.bankName = {
        type: "required",
        message: "This is required.",
      };
    }
    if (!values.branchName) {
      errors.branchName = {
        type: "required",
        message: "This is required.",
      };
    }
    if (!values.accountNo || values.accountNo === "") {
      errors.accountNo = {
        type: "required",
        message: "This is required.",
      };
    } else if (!/^[0-9]{8,30}$/.test(values.accountNo)) {
      errors.accountNo = {
        type: "pattern",
        message: "Account No. should be between 8 to 30 digits (only numbers)",
      };
    }
    if (!values.ifscNo || values.ifscNo === "") {
      errors.ifscNo = {
        type: "required",
        message: "This is required.",
      };
    } else if (!/^[A-Z]{4}0[A-Z0-9]{6}$/.test(values.ifscNo)) {
      errors.ifscNo = {
        type: "pattern",
        message:
          "IFSC Code should be 11 characters long, first four characters should be uppercase alphabets, fifth character should be 0, last 6 characters should be numbers but can also have alphabets",
      };
    }

    // POINT OF CONTACT VALIDATION
    if (!values.firstName) {
      errors.firstName = {
        type: "required",
        message: "This is required.",
      };
    }
    if (!values.lastName) {
      errors.lastName = {
        type: "required",
        message: "This is required.",
      };
    }
    if (!values.mobileNo) {
      errors.mobileNo = {
        type: "required",
        message: "This is required.",
      };
    }
    if (!values.email) {
      errors.email = {
        type: "required",
        message: "This is required.",
      };
    }
    return {
      values: Object.keys(errors).length ? {} : values,
      errors: errors,
    };
  };

  // create registerOptions object with updated validation for each field
  // const registerOptions = {
  //   companyName: {
  //     required: "Enter Company Name",
  //     pattern: {
  //       value: /^[A-Za-z\s]+$/,
  //       message: "Company Name should only contain alphabets.",
  //     },
  //   },
  //   cinNumber: {
  //     required: "Enter CIN Number",
  //   },
  //   gstNumber: {
  //     required: "Enter GST number",
  //     pattern: {
  //       value: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
  //       message: "Enter a valid GST Number",
  //     },
  //   },
  //   pancard: {
  //     required: "Enter PAN Number",
  //   },
  //   adhaarNo: {
  //     required: "Enter Adhaar Number",
  //   },
  //   emailId: {
  //     required: "Enter Email Id",
  //     pattern: {
  //       value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
  //       message: "Invalid Email Id format.",
  //     },
  //   },
  //   phoneNo: {
  //     required: "Enter Phone No.",
  //     pattern: {
  //       value: /^[0-9]{10}$/,
  //       message:
  //         "Invalid phone number. Please enter only numbers till 10-digits.",
  //     },
  //   },
  //   noOfUser: {
  //     required: "Enter no. of user",
  //     validate: (value: any) =>
  //       parseInt(value) > 0 || "Number of Users must be greater than 0",
  //   },
  //   country: {
  //     required: "Enter country",
  //   },
  //   state: {
  //     required: "Enter state",
  //   },
  //   city: {
  //     required: "Enter city",
  //     pattern: {
  //       value: /^[A-Za-z\s]+$/,
  //       message: "City should only contain alphabets.",
  //     },
  //   },
  //   addressLine1: {
  //     required: "This is required.",
  //   },
  //   addressLine2: {
  //     required: "This is required.",
  //   },
  //   pin: {
  //     required: "Please enter pincode",
  //   },
  //   // Product: {
  //   //   required: "Please enter products",
  //   // },
  //   bankName: {
  //     required: "This is required.",
  //   },
  //   branchName: {
  //     required: "This is required.",
  //   },
  //   accountNo: {
  //     required: "This is required.",
  //     pattern: {
  //       value: /^[0-9]{8,30}$/,
  //       message: "Account No. should be between 8 to 30 digits (only numbers).",
  //     },
  //   },
  //   ifscNo: {
  //     required: "This is required.",
  //     pattern: {
  //       value: /^[A-Z]{4}0[A-Z0-9]{6}$/,
  //       message:
  //         "IFSC Code should be 11 characters long, first four characters should be uppercase alphabets, fifth character should be 0, last 6 characters should be numbers but can also have alphabets.",
  //     },
  //   },
  //   firstName: {
  //     required: "This is required.",
  //   },
  //   lastName: {
  //     required: "This is required.",
  //   },
  //   mobileNo: {
  //     required: "This is required.",
  //     pattern: {
  //       value: /^[0-9]{10}$/,
  //       message: "Enter a valid 10-digit mobile number.",
  //     },
  //   },
  //   email: {
  //     required: "This is required.",
  //     pattern: {
  //       value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
  //       message: "Invalid Email Id format.",
  //     },
  //   },
  // };

  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    // reset,
    watch,
  } = useForm({
    resolver: resolver,
    // mode: "all",
  });

  // Effect to update firstName and lastName based on companyName
  // useEffect(() => {
  //   if (compTypeValue === "INDIVIDUAL") {
  //     const names = companyName.trim().split(" ");
  //     if (names.length > 1) {
  //       setCompanyData({
  //         ...companyData,
  //         firstName: names[0],
  //         lastName: names.slice(1).join(" "),
  //         mobileNo: phoneNum,
  //         email: emaiID,
  //       });
  //       setValue("firstName", names[0]);
  //       setValue("lastName", names.slice(1).join(" "));
  //       setValue("mobileNo", phoneNum);
  //       setValue("email", emaiID);
  //     } else {
  //       setCompanyData({
  //         ...companyData,
  //         firstName: companyName,
  //         lastName: "",
  //         mobileNo: phoneNum,
  //         email: emaiID,
  //       });
  //       setValue("firstName", companyName);
  //       setValue("lastName", "");
  //       setValue("mobileNo", phoneNum);
  //       setValue("email", emaiID);
  //     }
  //   }
  // }, [companyName, compTypeValue, phoneNum, emaiID, setValue]);

  // Watch for changes in the first email field
  // const compName = watch("companyName");
  const mobNum = watch("phoneNo");
  const emailId = watch("emailId");

  useEffect(() => {
    if (compTypeValue === "INDIVIDUAL") {
      const names = companyName.trim().split(" ");
      // if (names.length > 1) {
      setCompanyData((prevData: any) => ({
        ...prevData,
        firstName: names[0],
        lastName: names.slice(1).join(" "),
        mobileNo: mobNum,
        email: emailId,
      }));
      setValue("firstName", names[0]);
      setValue("lastName", names.slice(1).join(" "));
      setValue("mobileNo", mobNum);
      setValue("email", emailId);
      // }
    }
  }, [companyName, emailId, mobNum, setValue]);

  useEffect(() => {
    setCompanyData({
      ...companyData,
      firstName: "",
      lastName: "",
      mobileNo: "",
      email: "",
    });
  }, [compTypeValue]);

  const handleCompTypeChange = (e: any) => {
    setCompTypeValue(e.target.value);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCheckboxChange = (event: any) => {
    setIsChecked(event.target.checked);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  function handleClose() {
    setCompanyData([]);
    setOpen(false);
  }

  const handleSearch = (event: any) => {
    setSearchTerm(event.target.value);
  };

  const handleSort = () => {
    // Toggle between "asc" and "desc" when clicking on the sorting button
    setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
  };

  // Filter clients based on search term and date range
  const filteredClients = Array.isArray(getCompanyByType)
    ? getCompanyByType?.filter((item: any) => {
        const matchesSearchTerm =
          item?.companyName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item?.pancard?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item?.cinNumber?.toLowerCase().includes(searchTerm.toLowerCase());
        // Parse the createdAt date of the item
        const createdAt = dayjs(item.createdAt);
        // Check if the createdAt date is within the selected date range
        const withinDateRange =
          (!fromDate || createdAt.isSameOrAfter(dayjs(fromDate), "day")) &&
          (!toDate || createdAt.isSameOrBefore(dayjs(toDate), "day"));
        return matchesSearchTerm && withinDateRange;
      })
    : [];

  // Sorting function based on Company Name
  const sortedClients = [...filteredClients].sort((a, b) => {
    const nameA = a.companyName.toLowerCase();
    const nameB = b.companyName.toLowerCase();
    if (sortOrder === "asc") {
      return nameA.localeCompare(nameB);
    } else {
      return nameB.localeCompare(nameA);
    }
  });

  useEffect(() => {
    axios
      .get(
        "company/listOfManufactureNDistributorCompanies/companyTypeCode?companyTypeCode=M"
      )
      .then((res: any) => setcompanyList(res.data.companies));
    axios
      .get("Brokers/brokerBankDetails")
      .then((res: any) => setBankDetails(res?.data?.bankDetailsDTOS));

    axios
      .get(
        "company/listOfManufactureNDistributorCompanies/companyTypeCode?companyTypeCode=E"
      )
      .then((res: any) => setEmployeeCompanyList(res.data.companies));
  }, [NewCompanyType]);

  useEffect(() => {
    !!empCompanyCode &&
      axios
        .get(
          "employeeProfile/employeeByCompany?companyMasterCode=" +
            empCompanyCode
        )
        .then((res: any) => {
          setEmployeeData(res.data.employeeProfileDTOS);
        });
  }, [empCompanyCode]);

  const onSubmit = (data: any) => {
    // setshowCircularProgress(true);
    const formData = new FormData();
    formData.append("registerOfCertificate", registerOfCertificatefile);
    formData.append("gstNumberDoc", gstNumberDocfile);
    formData.append("panCardDoc", pancardDocfile);
    formData.append("cancelCheque", cancelChequefile);
    formData.append("companyLogo", companyLogofile);
    // formData.append("userId", userCode);
    formData.append("companyMasterCodes", CompanyList.toString());

    // Create a copy of the data object
    const dataCopy = { ...data };

    // Extract pointOfContact and remove those keys from the original data object
    const pointOfContact = {
      firstName: dataCopy.firstName || dataCopy?.companyName?.split(" ")[0],
      lastName: dataCopy.lastName || dataCopy?.companyName?.split(" ")[1],
      mobileNo: dataCopy.mobileNo || dataCopy.phoneNo,
      email: dataCopy.email || dataCopy.emailId,
      userTypeCode: NewCompanyType === "S" ? "E" : NewCompanyType,
    };
    // Remove pointOfContact fields from the copied data object
    delete dataCopy.firstName;
    delete dataCopy.lastName;
    delete dataCopy.mobileNo;
    delete dataCopy.email;
    // modified data object
    const dto: any = {
      ...dataCopy,
      companyTypeCode: NewCompanyType,
      viewLogo: isChecked,
      products: ProductTypeCode.toString(),
      rmUserCode: employee,
      pointOfContact,
    };
    formData.append("companyDto", JSON.stringify(dto));
    axios
      .post("company/saveCompany", formData)
      .then(() => toast.success("Company added successfully!"))
      .then(() => {
        setshowCircularProgress(false);
        setOpen(false);
        window.location.reload();
      })
      .catch((err: any) => {
        setshowCircularProgress(false);
        toast.error(
          err.response.data
            ? err.response.data.status.message.title
            : err?.response?.data?.title
        );
      });
  };

  useEffect(() => {
    setgetCompanyByType("");
    axios
      .get(
        "company/listOfManufactureNDistributorCompanies/companyTypeCode?companyTypeCode=" +
          CompanyType
      )
      .then((res: any) => {
        setgetCompanyByType(res.data.companies);
      });
    axios.get("product/listOfProductType").then((res: any) => {
      setProducts(res.data.productType);
    });
  }, [CompanyType, NewCompanyType, refresh]);

  return (
    <Container maxWidth="xl">
      <Box
        sx={{ paddingTop: 4, "& .MuiTextField-root": { m: 1, width: "25ch" } }}
      >
        <Typography variant="h4" gutterBottom>
          Company Master
        </Typography>
        <Container>
          <Button variant="contained" onClick={handleOpen}>
            Add Company
          </Button>
          <Box sx={{ width: 300, p: 3 }}>
            <Typography>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={[
                  { companyType: "Manufacture", companyCode: "M" },
                  { companyType: "Distributor", companyCode: "D" },
                  { companyType: "Self", companyCode: "S" },
                ].map((option: any) => option)}
                defaultValue={{ companyType: "Distributor", companyCode: "D" }}
                getOptionLabel={(options: any) => options.companyType}
                onChange={(options: any, newValue) => {
                  setCompanyType(newValue?.companyCode);
                  setRefresh(!refresh);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Company Type" />
                )}
              />
            </Typography>
          </Box>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: isSmallScreen ? "column" : "row",
            }}
          >
            <Box sx={{ width: 350, p: 1 }}>
              <Paper
                component="form"
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  width: 400,
                }}
              >
                🔍
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search Company by Name "
                  onChange={handleSearch}
                />
                <IconButton type="button" aria-label="search"></IconButton>
              </Paper>
            </Box>
            {/* FROM DATE AND TO DATE */}
            <Stack direction={"row"} spacing={2}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDatePicker
                  label="From Date"
                  inputFormat="YYYY-MM-DD"
                  value={fromDate}
                  onChange={(newValue: any) => {
                    if (newValue && newValue.isValid()) {
                      const formattedDate =
                        dayjs(newValue).format("YYYY-MM-DD");
                      setFromDate(formattedDate);
                    } else {
                      setFromDate(null);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      {...params}
                      defaultValue={fromDate}
                      sx={{ width: "50%", marginBottom: "20px" }}
                    />
                  )}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDatePicker
                  label="To Date"
                  inputFormat="YYYY-MM-DD"
                  value={toDate}
                  onChange={(newValue: any) => {
                    if (newValue && newValue.isValid()) {
                      const formattedDate =
                        dayjs(newValue).format("YYYY-MM-DD");
                      setToDate(formattedDate);
                    } else {
                      setToDate(null);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      {...params}
                      defaultValue={toDate}
                      sx={{ width: "50%", marginBottom: "20px" }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Stack>
          </div>
          <br />
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell onClick={handleSort} style={{ cursor: "pointer" }}>
                    Company Master Code {sortOrder === "asc" ? "▲" : "▼"}
                  </TableCell>
                  <TableCell>PAN No.</TableCell>
                  <TableCell>GST No.</TableCell>
                  <TableCell>CIN No.</TableCell>
                  <TableCell>Created At</TableCell>
                  {/* <TableCell>Status</TableCell> */}
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedClients
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row: any, index: any) => (
                    <>
                      <TableRow
                        key={index}
                        // hover color on row lightgrey
                        sx={{ "&:hover": { backgroundColor: "lightgrey" } }}
                      >
                        <TableCell>
                          <Link to={"/company/" + row.companyMasterCode}>
                            {row.companyName + " - " + row.companyTypeCode}
                          </Link>
                        </TableCell>
                        <TableCell>{row.pancard}</TableCell>
                        <TableCell>{row.gstNumber}</TableCell>
                        <TableCell component="th" scope="row">
                          {row.cinNumber}
                        </TableCell>
                        <TableCell>
                          {row?.createdAt ? row?.createdAt?.split("T")[0] : "-"}
                        </TableCell>
                        {/* <TableCell>
                        {row.isActive ? "Active" : "In Active"}
                      </TableCell> */}
                        <TableCell
                        // sx={{ display: "flex", zIndex: 10 }}
                        >
                          <CompanyCircularProgress
                            companyMasterCode={row.companyMasterCode}
                            isActive={row.active}
                            // setRefresh={setRefresh}
                            // refresh={refresh}
                          />
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 30, 50]}
            component="div"
            // count={getCompanyByType ? getCompanyByType.length : 0}
            count={sortedClients.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
          ></Box>

          <Modal open={open} onClose={handleClose}>
            <Box sx={style} className="modal-scroll">
              <IconButton
                style={{
                  position: "absolute",
                  fontSize: "18px",
                  top: "8px",
                  right: "5px",
                }}
                onClick={() => handleClose()}
              >
                <CloseIcon style={{ fontSize: "18px" }} />
              </IconButton>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box>
                  <Typography
                    id="keep-mounted-modal-title"
                    variant="h6"
                    textAlign={"center"}
                  >
                    Add New Company
                  </Typography>

                  <Box>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      // defaultValue="INDIVIDUAL"
                      value={compTypeValue}
                      onChange={handleCompTypeChange}
                    >
                      <Stack direction={"row"} spacing={3}>
                        <FormControlLabel
                          value="INDIVIDUAL"
                          {...register("compType")}
                          control={<Radio />}
                          label="Individual"
                        />
                        <FormControlLabel
                          value="CORPORATE"
                          {...register("compType")}
                          control={<Radio />}
                          label="Corporate"
                        />
                      </Stack>
                    </RadioGroup>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                          id="outlined-basic"
                          label="Company Name*"
                          variant="outlined"
                          defaultValue={companyData.companyName}
                          fullWidth
                          error={errors.companyName ? true : false}
                          helperText={
                            errors.companyName == undefined
                              ? ""
                              : errors.companyName.message + ""
                          }
                          {...register(
                            "companyName"
                            // registerOptions.companyName
                          )}
                          onChange={(e) => setCompanyName(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          options={[
                            { companyType: "Manufacture", companyCode: "M" },
                            { companyType: "Distributor", companyCode: "D" },
                            { companyType: "Self", companyCode: "S" },
                          ].map((option: any) => option)}
                          getOptionLabel={(options: any) => options.companyType}
                          onChange={(options: any, newValue) => {
                            setNewCompanyType(newValue?.companyCode);
                            setValue("companyTypeCode", newValue?.companyCode);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Company Type"
                              error={errors.companyTypeCode ? true : false}
                              helperText={
                                errors.companyTypeCode == undefined
                                  ? ""
                                  : errors.companyTypeCode.message + ""
                              }
                            />
                          )}
                        />
                      </Grid>

                      {NewCompanyType == "D" && (
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            getOptionLabel={(options: any) =>
                              options.companyName
                            }
                            options={employeeCompanyList.map(
                              (option: any) => option
                            )}
                            onChange={(options: any, newValue) => {
                              setEmpCompanyCode(newValue?.companyMasterCode);
                              setRefresh(!refresh);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Select RM Company"
                              />
                            )}
                          />
                        </Grid>
                      )}
                      {empCompanyCode && (
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <Autocomplete
                            id="Select Employee"
                            options={employeeData.map((option: any) => option)}
                            getOptionLabel={(options: any) =>
                              options.employeeCode +
                              " - " +
                              options.firstName +
                              " " +
                              options.lastName
                            }
                            onChange={(options: any, newValue) => {
                              setEmployee(newValue?.employeeCode);
                              setRefresh(!refresh);
                            }}
                            fullWidth
                            renderInput={(params) => (
                              <TextField {...params} label="Select RM" />
                            )}
                          />
                        </Grid>
                      )}
                      {/* {NewCompanyType == "D" ? (
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <Controller
                            control={control}
                            name="Product"
                            rules={{
                              required: "Veuillez choisir une réponse",
                            }}
                            render={({ field: { onChange } }) => (
                              <MultiSelectComp
                                items={companyList}
                                label="Assign Company"
                                selectAllLabel="Select All"
                                onChange={(e: any) =>
                                  setCompanyList(
                                    e.map((i: any) => i.companyMasterCode)
                                  )
                                }
                              />
                            )}
                          />
                        </Grid>
                      ) : (
                        <></>
                      )} */}
                      {/* // hide cin number for Individual */}
                      {compTypeValue === "CORPORATE" && (
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <TextField
                            id="outlined-basic"
                            label="CIN Number*"
                            variant="outlined"
                            defaultValue={companyData.cinNumber}
                            fullWidth
                            error={errors.cinNumber ? true : false}
                            helperText={
                              errors.cinNumber == undefined
                                ? ""
                                : errors.cinNumber.message + ""
                            }
                            {...register(
                              "cinNumber"
                              // compTypeValue === "CORPORATE"
                              //   ? registerOptions.cinNumber
                              //   : {}
                            )}
                          />
                        </Grid>
                      )}
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                          id="outlined-basic"
                          label={
                            compTypeValue === "CORPORATE"
                              ? "GST Number*"
                              : "GST Number"
                          }
                          variant="outlined"
                          defaultValue={companyData.gstNumber}
                          fullWidth
                          error={errors.gstNumber ? true : false}
                          helperText={
                            errors.gstNumber == undefined
                              ? ""
                              : errors.gstNumber.message + ""
                          }
                          {...register(
                            "gstNumber"
                            // compTypeValue === "CORPORATE"
                            //   ? registerOptions.gstNumber
                            //   : {}
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                          id="outlined-basic"
                          label="PAN Card No*"
                          variant="outlined"
                          defaultValue={companyData.pancard}
                          fullWidth
                          error={errors.pancard ? true : false}
                          helperText={
                            errors.pancard == undefined
                              ? ""
                              : errors.pancard.message + ""
                          }
                          {...register(
                            "pancard"
                            // registerOptions.pancard
                          )}
                        />
                      </Grid>
                      {/* add new field for adhaar card */}
                      {/* <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                          id="outlined-basic"
                          label="Adhaar Card No"
                          variant="outlined"
                          defaultValue={companyData.adhaarNo}
                          fullWidth
                          error={errors.adhaarNo ? true : false}
                          helperText={
                            errors.adhaarNo == undefined
                              ? ""
                              : errors.adhaarNo.message + ""
                          }
                          {...register("adhaarNo")}
                        />
                      </Grid> */}
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                          id="outlined-basic"
                          label="Email Id*"
                          variant="outlined"
                          defaultValue={companyData.emailId}
                          fullWidth
                          error={errors.emailId ? true : false}
                          helperText={
                            errors.emailId == undefined
                              ? ""
                              : errors.emailId.message + ""
                          }
                          {...register(
                            "emailId"
                            // registerOptions.emailId
                          )}
                          // onChange={(e) => setEmailID(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                          id="outlined-basic"
                          label="Phone No*"
                          variant="outlined"
                          defaultValue={companyData.phoneNo}
                          fullWidth
                          error={errors.phoneNo ? true : false}
                          helperText={
                            errors.phoneNo == undefined
                              ? ""
                              : errors.phoneNo.message + ""
                          }
                          {...register(
                            "phoneNo"
                            // registerOptions.phoneNo
                          )}
                          // onChange={(e) => setPhoneNum(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                          id="outlined-basic"
                          label="No Of User*"
                          variant="outlined"
                          defaultValue={companyData.noOfUser}
                          fullWidth
                          type="number"
                          error={errors.noOfUser ? true : false}
                          helperText={
                            errors.noOfUser == undefined
                              ? ""
                              : errors.noOfUser.message + ""
                          }
                          {...register("noOfUser", {
                            valueAsNumber: true,
                            // ...registerOptions.noOfUser,
                          })}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Autocomplete
                          options={States.countries}
                          getOptionLabel={(option) => option.country}
                          onChange={(event, value) => {
                            setSelectedCountry(value);
                            setValue("country", value && value.country);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Country*"
                              variant="outlined"
                              error={errors.country ? true : false}
                              helperText={
                                errors.country == undefined
                                  ? ""
                                  : errors.country.message + ""
                              }
                              {...register(
                                "country"
                                // registerOptions.country
                              )}
                            />
                          )}
                        />
                      </Grid>
                      {selectedCountry && (
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <Autocomplete
                            options={selectedCountry.states}
                            onChange={(event, value) => {
                              setSelectedState(value);
                              setValue("state", value);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="State*"
                                variant="outlined"
                                error={errors.state ? true : false}
                                helperText={
                                  errors.state == undefined
                                    ? ""
                                    : errors.state.message + ""
                                }
                                {...register(
                                  "state"
                                  // registerOptions.state
                                )}
                              />
                            )}
                          />
                        </Grid>
                      )}
                      {/* <Autocomplete
                      multiple
                      id="combo-box-demo"
                      getOptionLabel={(options: any) => options.productName}
                      options={products.map((options: any) => options)}
                      onChange={(options: any, newValue: any) => {
                        setPayoutProductName(
                          newValue.map((it: any) => it.productName)
                        )
                        setProductTypeCode(
                          newValue.map((it: any) => it.productTypeCode)
                        );
                        // setValue("productType",newValue.map((it: any) => it.productTypeCode));
                      }}
                      renderInput={(params) => (
                        <TextField 
                          {...params} 
                          label="Products"
                          error={errors.productType?true:false}
                        helperText={
                        errors.productType == undefined
                          ? ""
                          : errors.productType.message + ""
                        }
                           />
                      )}
                    /> */}
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                          id="outlined-basic"
                          label="City*"
                          variant="outlined"
                          defaultValue={companyData.city}
                          fullWidth
                          error={errors.city ? true : false}
                          helperText={
                            errors.city == undefined
                              ? ""
                              : errors.city.message + ""
                          }
                          {...register(
                            "city"
                            // registerOptions.city
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                          id="outlined-basic"
                          label="Address Line 1*"
                          defaultValue={companyData.addressLine1}
                          variant="outlined"
                          fullWidth
                          error={errors.addressLine1 ? true : false}
                          helperText={
                            errors.addressLine1 == undefined
                              ? ""
                              : errors.addressLine1.message + ""
                          }
                          {...register(
                            "addressLine1"
                            // registerOptions.addressLine1
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                          id="outlined-basic"
                          label="Address Line 2*"
                          defaultValue={companyData.addressLine2}
                          variant="outlined"
                          fullWidth
                          error={errors.addressLine2 ? true : false}
                          helperText={
                            errors.addressLine2 == undefined
                              ? ""
                              : errors.addressLine2.message + ""
                          }
                          {...register(
                            "addressLine2"
                            // registerOptions.addressLine2
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                          id="outlined-basic"
                          label="Address Line 3"
                          variant="outlined"
                          defaultValue={companyData.addressLine3}
                          fullWidth
                          // error={errors.addressLine3 ? true : false}
                          // helperText={
                          //   errors.addressLine3 == undefined
                          //     ? ""
                          //     : errors.addressLine3.message + ""
                          // }
                          {...register("addressLine3")}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                          id="outlined-basic"
                          label="PIN Code*"
                          variant="outlined"
                          defaultValue={companyData.pin}
                          fullWidth
                          error={errors.pin ? true : false}
                          helperText={
                            errors.pin == undefined
                              ? ""
                              : errors.pin.message + ""
                          }
                          {...register(
                            "pin"
                            // registerOptions.pin
                          )}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Controller
                          control={control}
                          name="Product"
                          // rules={{
                          //   required: "Please select Product",
                          // }}
                          render={({ field: { onChange } }) => (
                            <MultiSelect
                              items={products}
                              error={errors?.productsType}
                              helperText={errors?.productsType?.message}
                              label="Products*"
                              selectAllLabel="Select All"
                              onChange={(e: any) =>
                                setProductTypeCode(
                                  e.map((i: any) => i.productTypeCode)
                                )
                              }
                            />
                          )}
                        />
                      </Grid>
                      {/* {compTypeValue === "CORPORATE" && (
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={bankDetails?.map((option: any) => option)}
                            getOptionLabel={(options: any) =>
                              options.bankName + " - " + options.accountNO
                            }
                            onChange={(options: any, newValue) => {
                              setValue(
                                "brokerBankDetailId",
                                newValue?.bankDetailId
                              );
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Select Broker bank"
                              />
                            )}
                          />
                        </Grid>
                      )} */}
                    </Grid>
                    <br />
                    {/* <TextField
                      id="outlined-basic"
                      label="Address Line 1"
                      defaultValue={companyData.addressLine1}
                      variant="outlined"
                      fullWidth
                      error={errors.addressLine1 ? true : false}
                      helperText={
                        errors.addressLine1 == undefined
                          ? ""
                          : errors.addressLine1.message + ""
                      }
                      {...register("addressLine1")}
                    /> */}
                    <Typography
                      id="keep-mounted-modal-title"
                      // variant="h6"
                      textAlign={"left"}
                      sx={{
                        padding: "10px 10px 0px",
                        fontSize: "1.2rem",
                        fontWeight: "600",
                      }}
                    >
                      Bank Details
                    </Typography>
                    <Grid container spacing={2}>
                      {/* BANK NAME */}
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                          id="outlined-basic"
                          label="Bank Name*"
                          variant="outlined"
                          defaultValue={companyData?.bankName}
                          fullWidth
                          error={errors.bankName ? true : false}
                          helperText={
                            !errors.bankName ? "" : errors.bankName.message + ""
                          }
                          {...register(
                            "bankName"
                            // registerOptions.bankName
                          )}
                        />
                      </Grid>
                      {/* BRANCH NAME */}
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                          id="outlined-basic"
                          label="Branch Name*"
                          variant="outlined"
                          defaultValue={companyData?.branchName}
                          fullWidth
                          error={errors.branchName ? true : false}
                          helperText={
                            !errors.branchName
                              ? ""
                              : errors.branchName.message + ""
                          }
                          {...register(
                            "branchName"
                            // registerOptions.branchName
                          )}
                        />
                      </Grid>
                      {/* ACCOUNT NO. */}
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                          id="outlined-basic"
                          label="Account No.*"
                          variant="outlined"
                          defaultValue={companyData?.accountNo}
                          fullWidth
                          error={errors.accountNo ? true : false}
                          helperText={
                            !errors.accountNo
                              ? ""
                              : errors.accountNo.message + ""
                          }
                          {...register(
                            "accountNo"
                            // registerOptions.accountNo
                          )}
                        />
                      </Grid>
                      {/* IFSC CODE */}
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                          id="outlined-basic"
                          label="IFSC Code*"
                          variant="outlined"
                          defaultValue={companyData?.ifscNo}
                          fullWidth
                          error={errors.ifscNo ? true : false}
                          helperText={
                            !errors.ifscNo ? "" : errors.ifscNo.message + ""
                          }
                          {...register(
                            "ifscNo"
                            // registerOptions.ifscNo
                          )}
                        />
                      </Grid>
                    </Grid>
                    <br />
                    {/* POINT OF CONTACT FOR CORPORATE */}
                    {/* {compTypeValue === "CORPORATE" && (
                      <> */}
                    <Typography
                      id="keep-mounted-modal-title"
                      // variant="h6"
                      textAlign={"left"}
                      sx={{
                        padding: "10px 10px 0px",
                        fontSize: "1.2rem",
                        fontWeight: "600",
                      }}
                    >
                      Point of Contact
                    </Typography>
                    <Grid container spacing={2}>
                      {/* USER FIRST NAME */}
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                          id="outlined-basic"
                          label="First Name*"
                          variant="outlined"
                          value={companyData?.firstName}
                          // value={firstName}
                          fullWidth
                          error={errors.firstName ? true : false}
                          helperText={
                            !errors.firstName
                              ? ""
                              : errors.firstName.message + ""
                          }
                          {...register(
                            "firstName"
                            // registerOptions.firstName
                          )}
                          onChange={(e) =>
                            setCompanyData({
                              ...companyData,
                              firstName: e.target.value,
                            })
                          }
                        />
                      </Grid>
                      {/* USER LAST NAME */}
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                          id="outlined-basic"
                          label="Last Name*"
                          variant="outlined"
                          value={companyData?.lastName}
                          // value={lastName}
                          fullWidth
                          error={errors.lastName ? true : false}
                          helperText={
                            !errors.lastName ? "" : errors.lastName.message + ""
                          }
                          {...register(
                            "lastName"
                            // registerOptions.lastName
                          )}
                          onChange={(e) =>
                            setCompanyData({
                              ...companyData,
                              lastName: e.target.value,
                            })
                          }
                        />
                      </Grid>
                      {/* USER MOBILE NO. */}
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                          id="outlined-basic"
                          label="Mobile No.*"
                          variant="outlined"
                          value={companyData?.mobileNo}
                          fullWidth
                          error={errors.mobileNo ? true : false}
                          helperText={
                            !errors.mobileNo ? "" : errors.mobileNo.message + ""
                          }
                          {...register(
                            "mobileNo"
                            // registerOptions.mobileNo
                          )}
                          onChange={(e) =>
                            setCompanyData({
                              ...companyData,
                              mobileNo: e.target.value,
                            })
                          }
                        />
                      </Grid>
                      {/* USER EMAIL-ID */}
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                          id="outlined-basic"
                          label="Email Id*"
                          variant="outlined"
                          value={companyData?.email}
                          fullWidth
                          type="email"
                          error={errors.email ? true : false}
                          helperText={
                            !errors.email ? "" : errors.email.message + ""
                          }
                          {...register(
                            "email"
                            // registerOptions.email
                          )}
                          onChange={(e) =>
                            setCompanyData({
                              ...companyData,
                              email: e.target.value,
                            })
                          }
                        />
                      </Grid>
                    </Grid>
                    {/* </>
                    )} */}
                    <br />

                    <Box>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isChecked}
                            onChange={handleCheckboxChange}
                          />
                        }
                        label="Show Logo"
                      />
                    </Box>
                    {compTypeValue === "CORPORATE" && (
                      <>
                        <Typography variant="subtitle1">
                          Registration Certificate :
                        </Typography>
                        <input
                          type="file"
                          accept=".pdf"
                          name="registerOfCertificate"
                          onChange={(event: any) =>
                            setregisterOfCertificatefile(event.target.files[0])
                          }
                        />
                      </>
                    )}
                    <br />
                    <Typography variant="subtitle1">
                      GST Certificate :
                    </Typography>
                    <input
                      type="file"
                      accept=".pdf"
                      name="gstNumberDoc"
                      onChange={(event: any) =>
                        setgstNumberDocfile(event.target.files[0])
                      }
                    />
                    <br />
                    <Typography variant="subtitle1">Pancard :</Typography>
                    <input
                      type="file"
                      accept=".pdf"
                      name="pancardDoc"
                      onChange={(event: any) =>
                        setpancardDocfile(event.target.files[0])
                      }
                    />
                    <br />
                    <Typography variant="subtitle1">Cancel Cheque :</Typography>
                    <input
                      type="file"
                      accept=".jpg"
                      name="cancelCheque"
                      onChange={(event: any) =>
                        setcancelChequefile(event.target.files[0])
                      }
                    />
                    <br />
                    {compTypeValue === "CORPORATE" && (
                      <>
                        <Typography variant="subtitle1">
                          Company Logo :
                        </Typography>
                        <input
                          type="file"
                          accept=".jpg"
                          name="companyLogo"
                          onChange={(event: any) =>
                            setcompanyLogofile(event.target.files[0])
                          }
                        />
                      </>
                    )}
                    <br />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        // onClick={() => setCompTypeValue(getValues("compType"))}
                        disabled={showCircularProgress}
                      >
                        {showCircularProgress == true ? (
                          <CircularProgress
                            color="secondary"
                            size={20}
                            thickness={5}
                          />
                        ) : (
                          ""
                        )}{" "}
                        Submit
                      </Button>
                    </div>
                  </Box>
                </Box>
              </form>
            </Box>
          </Modal>
        </Container>
      </Box>
    </Container>
  );
};

export default withParam(CompanyManagement);
