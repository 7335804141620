/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import { useQuery } from "react-query";
import {
  GetAllClient,
  GetBrokeragePayoutByCompanyMasterCode,
  GetListOfUserByCompanyMasterCodeAndUserTypeCode,
  AifGetById,
  listOfManufactureNDistributorCompanies,
  AifList,
} from "../../api/Api";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useForm } from "react-hook-form";
import { productTypeEnum } from "../../Constants/ProductTypeEnum";
import { CurrencyFormate, changeDate } from "../../utils/Helper";
import axios from "axios";
import { toast } from "react-toastify";
import { IncomeType } from "../../Constants/IncomeType";
import ClientModalForOrder from "../ClientModalForOrder";
import { CREATE_NEW_ORDER, SAVE_PROPOSAL } from "../../utils/API_Names";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const OrderWindowForAIF = () => {
  const [aifData, setAifData] = useState<any>();

  const [companyMasterCode, setCompanyMasterCode] = React.useState();
  const [companyTypeCode, setCompanyTypeCode] = React.useState();
  const [distributorCode, setDistributorCode] = React.useState("");
  const [clientId, setClientId] = React.useState("");
  const [orderDate, seOrderDate] = React.useState(new Date());
  const [Clients, setClients] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [clientDetails, setClientDetails] = React.useState<any>("");
  const [incomeType, setIncomeType] = React.useState<any>(false);
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [uploadForm, setUploadForm] = React.useState<any>();
  const [uploadFormRefNo, setUploadFormRefNo] = React.useState("");
  const [OrderNowBtn, setOrderNowBtn] = React.useState(false);
  const [investmentAmount, setInvestmentAmount] = React.useState("");
  const [distributorUserCode, setDistributorUserCode] = React.useState("");
  const [selectedUser, setSelectedUser] = React.useState(null);

  let aifSchemeCode = aifData?.schemeCode;

  const { data: aifList, isLoading } = useQuery("AifOrder", () =>
    AifList("", "Y")
  );

  const { data: aifDetils, isLoading: aifDetilsLoading } = useQuery(
    ["AIFGetById", aifSchemeCode],
    () => AifGetById(aifSchemeCode),
    {
      enabled: !!aifSchemeCode,
      refetchOnWindowFocus: false,
      retry: false,
      onSuccess(data) {
        setValue("buyRate", data?.data?.aifDTOList[0]?.schemeMinInvestment);
        setIncomeType(data?.data?.aifDTOList[0]?.incomeType);
      },
    }
  );

  const { data: distributorCompanyList } = useQuery(
    "ManufactureNDistributorCompanie",
    () => listOfManufactureNDistributorCompanies("D,E"),
    {
      enabled:
        sessionStorage.getItem("Profile") === "B" ||
        sessionStorage.getItem("Profile") === "E",
    }
  );
  const filteredClients = Clients.filter((item: any) => {
    return (
      item?.fullName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item?.panCardNo?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item?.clientCode?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const { data: UserProfilesByCompanyMasterCode } = useQuery(
    ["userProfileByCompany", companyMasterCode, companyTypeCode],
    () =>
      GetListOfUserByCompanyMasterCodeAndUserTypeCode(
        companyTypeCode === "S" ? "E" : companyTypeCode,
        companyMasterCode
      ),
    {
      enabled: !!companyMasterCode,
    }
  );

  const { data: clientData } = useQuery(
    ["getAllClients", companyMasterCode, distributorCode],
    () => GetAllClient(companyMasterCode, distributorCode),
    {
      enabled: !!companyMasterCode || sessionStorage.getItem("Profile") === "D",
    }
  );

  const { data: brokerageData } = useQuery(
    ["GetBrokeragePayoutByCompanyMasterCode", companyMasterCode],
    () =>
      GetBrokeragePayoutByCompanyMasterCode(
        sessionStorage.getItem("Profile") === "D"
          ? sessionStorage.getItem("CompanyMasterCode")
          : companyMasterCode
      ),
    {
      enabled: !!companyMasterCode || sessionStorage.getItem("Profile") === "D",
    }
  );

  const aifBrokerage =
    brokerageData &&
    brokerageData.data?.companyBrokerage?.filter(
      (item: any) =>
        item?.productTypeCode === productTypeEnum?.ALTERNATIVE_INVESTMENT_FUND
    )[0];

  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    getValues,
    watch,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const addToProposal = () => {
    let proposalDTO = {
      clientId: clientId,
      productMasterCode: aifDetils.data.aifDTOList[0].productMasterCode,
      productTypeCode: productTypeEnum.ALTERNATIVE_INVESTMENT_FUND,
      clientPrice: !!investmentAmount
        ? investmentAmount
        : aifDetils.data.aifDTOList[0].schemeMinInvestment,
      disCompanyMasterCode: !!companyMasterCode ? companyMasterCode : "",
      disUserCode: !!distributorUserCode ? distributorUserCode : "",
    };
    console.log("proposal-DTO: ", proposalDTO);
    axios
      .post(SAVE_PROPOSAL, proposalDTO)
      .then(
        (res: any) => res && toast.success(res?.data?.status?.message?.title)
      )
      .catch((err: any) => {
        toast.error(
          err?.response?.data?.status?.message?.title
            ? err?.response?.data?.status?.message?.title
            : err?.response?.data?.title
        );
      });
  };
  const registerOptions = {
    commitmentAmount: {
      required: "Please Enter Valid Amount",
      pattern: {
        value: /^[0-9]+$/,
        message: "Invalid Amount",
      },
      validate: {
        positive: (v: any) =>
          parseInt(v) >= aifDetils.data.aifDTOList[0]?.schemeMinInvestment ||
          `Please Enter A Amount More then ${aifDetils.data.aifDTOList[0]?.schemeMinInvestment}`,
      },
    },
    selectedDate: {
      required: "Select a Date",
    },
    clients: { required: "Select a Client" },
  };

  React.useEffect(() => {
    if (uploadForm) {
      setOrderNowBtn(true);
      const formData = new FormData();
      formData.append("document", uploadForm);
      axios.post("documentupload", formData).then((res: any) => {
        setUploadFormRefNo(res.data.documentId);
        setOrderNowBtn(false);
      });
    }
  }, [uploadForm]);

  const onSubmitInvestNow = (dataa: any) => {
    let AifInvestNowdto = {
      productMasterCode: aifDetils.data.aifDTOList[0].productMasterCode,
      clientId: clientId,
      version: "1",
      productTypeCode: productTypeEnum.ALTERNATIVE_INVESTMENT_FUND,
      orderDate: changeDate(orderDate),
      distCompanyMasterCode: companyMasterCode,
      distEmployeeCode: distributorCode,
      schemeCode: aifDetils.data.aifDTOList[0].schemeCode,
      buyRate: !!dataa.buyRate
        ? dataa.buyRate
        : aifDetils.data.aifDTOList[0].schemeMinInvestment,
      commitmentAmount: dataa?.commitmentAmount,
      acknowledgementReferenceNo:
        dataa?.acknowledgementNo === "" ? null : dataa?.acknowledgementNo,
      fromRefNo: uploadFormRefNo === "" ? null : uploadFormRefNo,
    };
    axios
      .post(CREATE_NEW_ORDER, AifInvestNowdto)
      .then((res: any) => {
        res && toast.success(res?.data?.status?.message?.title);
        window.location.reload();
      })
      .catch((err: any) => {
        toast.error(
          err?.response?.data?.status?.message?.title
            ? err?.response?.data?.status?.message?.title
            : err?.response?.data?.title
        );
      });
  };

  let income_Type = aifDetils?.data?.aifDTOList[0]?.incomeType || null;
  let userInvestmentAmount = watch("buyRate");
  let userCommitmentAmount = watch("commitmentAmount");
  let revenueCredit =
    (aifDetils?.data?.aifDTOList[0]?.managementFees *
      aifDetils?.data?.aifDTOList[0]?.revenueCreditFee) /
    100;

  let brokerRevenueRate: any;
  let disRevenueRate: any;
  let brokerUFRevenueRate: any;
  let disUFRevenueRate: any;

  let brokerRevenue: any;
  let disRevenue: any;
  let brokerUFRevenue: any;
  let disUFRevenue: any;
  // FOR TRAIL
  if (income_Type === "trail") {
    let revenueCreditAmount = (userInvestmentAmount * revenueCredit) / 100;
    let revenuePerDay: any = revenueCreditAmount / 365;
    let now = new Date(orderDate);
    let monthap = new Date(now.getFullYear(), now.getMonth() + 1, 0);
    let daysRemaining = monthap.getDate();
    let amount = revenuePerDay * daysRemaining;
    if (companyTypeCode === "D" || sessionStorage.getItem("Profile") === "D") {
      let distributorRevenue =
        amount * (aifBrokerage?.brokerageValueForCustomer / 100);
      disRevenue = CurrencyFormate(distributorRevenue);
      brokerRevenue = CurrencyFormate(amount - distributorRevenue);
      disRevenueRate =
        revenueCredit * (aifBrokerage?.brokerageValueForCustomer / 100);
      brokerRevenueRate = revenueCredit - disRevenueRate;
    } else {
      brokerRevenue = CurrencyFormate(amount);
      brokerRevenueRate = revenueCredit;
    }
  }
  // FOR UPFRONT
  else if (income_Type === "upfront") {
    let revenueCreditAmount = (userCommitmentAmount * revenueCredit) / 100;
    if (companyTypeCode === "D" || sessionStorage.getItem("Profile") === "D") {
      let distributorRevenue =
        revenueCreditAmount * (aifBrokerage?.brokerageValueForCustomer / 100);
      disRevenue = CurrencyFormate(distributorRevenue);
      brokerRevenue = CurrencyFormate(revenueCreditAmount - distributorRevenue);
      disRevenueRate =
        revenueCredit * (aifBrokerage?.brokerageValueForCustomer / 100);
      brokerRevenueRate = revenueCredit - disRevenueRate;
    } else {
      brokerRevenue = CurrencyFormate(revenueCreditAmount);
      brokerRevenueRate = revenueCredit;
    }
  }
  // FOR HYBRID
  else {
    let trailRevenueCreditAmount =
      (userInvestmentAmount * aifDetils?.data?.aifDTOList[0]?.trailPercentage) /
      100;
    let upFrontRevenueCreditAmount =
      (userCommitmentAmount *
        aifDetils?.data?.aifDTOList[0]?.upfrontPercentage) /
      100;
    if (companyTypeCode === "D" || sessionStorage.getItem("Profile") === "D") {
      let revenuePerDayTrail = trailRevenueCreditAmount / 365;
      let today = new Date(orderDate);
      let trailYear = new Date(
        today.getFullYear() + 1,
        today.getMonth() + 1,
        0
      ); // startFromNextYear (02-Feb-2025)
      let daysLeft = trailYear.getDate(); // days of that month (28)
      let monthlyAmount = revenuePerDayTrail * daysLeft;

      let distributorTrailRevenue =
        monthlyAmount * (aifBrokerage?.brokerageValueForCustomer / 100);
      disRevenue = CurrencyFormate(distributorTrailRevenue);
      brokerRevenue = CurrencyFormate(monthlyAmount - distributorTrailRevenue);

      let distributorUpfrontRevenue =
        upFrontRevenueCreditAmount *
        (aifBrokerage?.brokerageValueForCustomer / 100);
      disUFRevenue = CurrencyFormate(distributorUpfrontRevenue);
      brokerUFRevenue = CurrencyFormate(
        upFrontRevenueCreditAmount - distributorUpfrontRevenue
      );
      disRevenueRate =
        aifDetils?.data?.aifDTOList[0]?.trailPercentage *
        (aifBrokerage?.brokerageValueForCustomer / 100);
      brokerRevenueRate =
        aifDetils?.data?.aifDTOList[0]?.trailPercentage - disRevenueRate;
      disUFRevenueRate =
        aifDetils?.data?.aifDTOList[0]?.upfrontPercentage *
        (aifBrokerage?.brokerageValueForCustomer / 100);
      brokerUFRevenueRate =
        aifDetils?.data?.aifDTOList[0]?.upfrontPercentage - disUFRevenueRate;
    } else {
      let revenuePerDayTrail = trailRevenueCreditAmount / 365;
      let today = new Date(orderDate);
      let trailYear = new Date(
        today.getFullYear() + 1,
        today.getMonth() + 1,
        0
      );
      let daysLeft = trailYear.getDate();
      let monthlyAmount = revenuePerDayTrail * daysLeft;

      brokerRevenue = CurrencyFormate(monthlyAmount);
      brokerUFRevenue = CurrencyFormate(upFrontRevenueCreditAmount);
      brokerRevenueRate = aifDetils?.data?.aifDTOList[0]?.trailPercentage;
      brokerUFRevenueRate = aifDetils?.data?.aifDTOList[0]?.upfrontPercentage;
    }
  }

  const { data } = useQuery("getAllClients", () => GetAllClient(), {
    onSuccess: (data: any) => {
      const newData = data.data.clients.map((item: any, index: any) => {
        return { ...item, SrNo: index + 1 };
      });
      setClients(newData);
    },
  });
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = useState(0);

  const profit: any =
    !!watch("buyRate") && aifDetils
      ? // here it will check for is NAN
        isNaN(
          parseFloat(
            (
              watch("buyRate") *
              (parseFloat(aifDetils.data.aifDTOList[0].revenueCreditFee) /
                100) *
              (parseFloat(aifDetils.data.aifDTOList[0].managementFees) / 100)
            ).toFixed(2)
          ) -
            parseFloat(
              (
                watch("buyRate") *
                (parseFloat(aifDetils.data.aifDTOList[0].revenueCreditFee) /
                  100) *
                (parseFloat(aifDetils.data.aifDTOList[0].managementFees) /
                  100) *
                (parseFloat(aifBrokerage?.brokerageValueForIV) / 100)
              ).toFixed(2)
            )
        )
        ? "0"
        : // this will print on display
          parseFloat(
            (
              watch("buyRate") *
              (parseFloat(aifDetils.data.aifDTOList[0].revenueCreditFee) /
                100) *
              (parseFloat(aifDetils.data.aifDTOList[0].managementFees) / 100)
            ).toFixed(2)
          ) -
          parseFloat(
            (
              watch("buyRate") *
              (parseFloat(aifDetils.data.aifDTOList[0].revenueCreditFee) /
                100) *
              (parseFloat(aifDetils.data.aifDTOList[0].managementFees) / 100) *
              (parseFloat(aifBrokerage?.brokerageValueForIV) / 100)
            ).toFixed(2)
          )
      : // this is else condition for nan
        "0";
  console.log("Income Type", incomeType);

  return (
    <>
      {aifList ? (
        <Autocomplete
          sx={{ width: 500 }}
          disablePortal
          id="combo-box-demo"
          options={
            aifList && aifList.data.aifDTOList.map((option: any) => option)
          }
          getOptionLabel={(options: any) => options?.schemeName}
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
              {...props}
            >
              <img
                loading="lazy"
                width="20"
                src={option?.amcInfoDTO?.amcLogo}
                srcSet={option?.amcInfoDTO?.amcLogo}
                alt=""
              />
              {option?.schemeName}
            </Box>
          )}
          onChange={(options: any, newValue) => {
            setAifData(newValue || "");
          }}
          renderInput={(params) => (
            <TextField {...params} label="Select Scheme" size="small" />
          )}
        />
      ) : (
        <Typography variant="subtitle1" textAlign={"center"} sx={{ mt: 2 }}>
          <CircularProgress size={15} sx={{ mr: 1 }} />
          Please Wait We're Fetching AIF Schemes For You...{" "}
        </Typography>
      )}

      {aifList && aifDetils
        ? aifDetils.data.aifDTOList.map((item: any) => (
            <Box>
              <Typography variant="h6" textAlign={"center"} gutterBottom>
                Order Details
              </Typography>
              <Divider sx={{ mb: 1 }} />
              <Grid
                container
                rowGap={4}
                sx={{
                  margin: "0 auto",
                }}
              >
                <Grid item xs={12} sm={6} md={3} lg={2}>
                  <img
                    src={item?.amcInfoDTO?.amcLogo}
                    width={200}
                    alt="AMC logo"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2}></Grid>
                <Typography variant="h5" textAlign={"center"} gutterBottom>
                  {item.schemeName || "-"}
                </Typography>
              </Grid>
              <Grid container spacing={2} textAlign={"center"} sx={{ mb: 2 }}>
                <Grid item xs={12} sm={3} md={3} lg={3} textAlign={"center"}>
                  <Typography variant="subtitle1">Min Investment</Typography>
                  <Typography variant="subtitle2" gutterBottom>
                    {item.schemeMinInvestment?.toLocaleString("en-IN")
                      ? "₹ " + item.schemeMinInvestment?.toLocaleString("en-IN")
                      : "-"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3} md={3} lg={3} textAlign={"center"}>
                  <Typography variant="subtitle1">Benchmark</Typography>
                  <Typography variant="subtitle2" gutterBottom>
                    {item.schemeBenchmarkName ? item.schemeBenchmarkName : "-"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3} md={3} lg={3} textAlign={"center"}>
                  <Typography variant="subtitle1">AUM</Typography>
                  <Typography variant="subtitle2" gutterBottom>
                    {item.fundPerformance.aum ? item.fundPerformance.aum : "-"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3} md={3} lg={3} textAlign={"center"}>
                  <Typography variant="subtitle1">NAV</Typography>
                  <Typography variant="subtitle2">
                    {item.fundPerformance?.nav}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container sx={{ mb: 2 }}>
                <Grid item xs={9} sm={12} md={12} lg={12}>
                  <Typography variant="subtitle1" align="center">
                    Fund Objective
                  </Typography>
                  <Typography variant="subtitle2" align="center">
                    {item.schemeObjective || "-"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} textAlign={"center"} sx={{ mb: 2 }}>
                <Grid item xs={12} sm={3} md={3} lg={3} textAlign={"center"}>
                  <Typography variant="subtitle1">Inception Date</Typography>
                  <Typography variant="subtitle2" gutterBottom>
                    {item.schemeInceptionDate ? item.schemeInceptionDate : "-"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3} md={3} lg={3}>
                  <Typography variant="subtitle1">
                    Exit Load / Lock-in
                  </Typography>
                  <Typography variant="subtitle2">
                    {item.schemeExitLoad || "-"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3} md={3} lg={3} textAlign={"center"}>
                  <Typography variant="subtitle1">Fees Structure</Typography>
                  <Typography variant="subtitle2">
                    {item.schemeFeeStructure || "-"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3} md={3} lg={3} textAlign={"center"}>
                  <Typography variant="subtitle1">Fund Manager</Typography>
                  <Typography variant="subtitle2">
                    {item.fundManagerName || "-"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container rowGap={2} columnGap={2}>
                {sessionStorage.getItem("Profile") === "B" ||
                sessionStorage.getItem("Profile") === "E" ? (
                  <Grid item xs={12} sm={3} md={3} lg={3}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={
                        distributorCompanyList &&
                        distributorCompanyList.data.companies.map(
                          (options: any) => options
                        )
                      }
                      getOptionLabel={(options: any) =>
                        options.companyName + " - " + options.companyTypeCode
                      }
                      fullWidth
                      onChange={(options: any, newValue) => {
                        setCompanyMasterCode(
                          newValue ? newValue.companyMasterCode : ""
                        );
                        setCompanyTypeCode(
                          newValue ? newValue.companyTypeCode : ""
                        );
                      }}
                      renderInput={(params: any) => (
                        <TextField
                          {...params}
                          label="Select Company"
                          size="small"
                        />
                      )}
                    />
                  </Grid>
                ) : (
                  <></>
                )}

                {sessionStorage.getItem("Profile") === "B" ||
                sessionStorage.getItem("Profile") === "E" ? (
                  <>
                    <Grid item xs={12} sm={3} md={3} lg={3}>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={
                          UserProfilesByCompanyMasterCode
                            ? UserProfilesByCompanyMasterCode.data.userProfile.map(
                                (options: any) => options
                              )
                            : []
                        }
                        getOptionLabel={(options: any) =>
                          options?.userCode +
                          "- " +
                          options?.firstName +
                          " " +
                          options?.lastName
                        }
                        fullWidth
                        onChange={(options: any, newValue) => {
                          setDistributorCode(
                            newValue ? newValue.employeeCode : ""
                          );
                          setDistributorUserCode(
                            newValue ? newValue.userCode : ""
                          );
                          setSelectedUser(newValue ? newValue : null);
                        }}
                        value={selectedUser}
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            label="Select User"
                            size="small"
                          />
                        )}
                      />
                    </Grid>
                  </>
                ) : (
                  <></>
                )}

                <Grid item xs={12} sm={3} md={3} lg={3}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={
                      clientData
                        ? clientData?.data?.clients?.map(
                            (options: any) => options
                          )
                        : []
                    }
                    getOptionLabel={(options: any) =>
                      options.fullName + " - " + options.panCardNo
                    }
                    fullWidth
                    onChange={(options: any, newValue) => {
                      setClientId(newValue ? newValue.clientId : "");
                      setClientDetails(newValue ? newValue : "");
                    }}
                    renderInput={(params: any) => (
                      <form
                        onSubmit={handleSubmit(onSubmitInvestNow)}
                        id="form1"
                      >
                        <TextField
                          {...params}
                          label="Select Clients"
                          size="small"
                          error={errors.clients ? true : false}
                          helperText={
                            !errors.clients ? "" : errors.clients.message + ""
                          }
                          {...register("clients", registerOptions.clients)}
                        />
                      </form>
                    )}
                  />
                </Grid>
                <Grid sx={{ p: 1 }}>
                  {clientId && (
                    <ClientModalForOrder clientDetails={clientDetails} />
                  )}
                </Grid>
                <TableContainer component={Paper} elevation={3}>
                  <Table sx={{ minWidth: 1650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{ fontSize: "1rem", fontWeight: "600" }}
                          align="center"
                        >
                          Order Date
                        </TableCell>
                        <TableCell
                          sx={{ fontSize: "1rem", fontWeight: "600" }}
                          align="center"
                        >
                          Client Name
                        </TableCell>
                        <TableCell
                          sx={{ fontSize: "1rem", fontWeight: "600" }}
                          align="center"
                        >
                          PAN Card
                        </TableCell>
                        <TableCell
                          sx={{ fontSize: "1rem", fontWeight: "600" }}
                          align="center"
                        >
                          Commitment Amount
                        </TableCell>
                        <TableCell
                          sx={{ fontSize: "1rem", fontWeight: "600" }}
                          align="center"
                        >
                          Investment Amount
                        </TableCell>
                        {sessionStorage.getItem("Profile") === "D" &&
                          (incomeType === "trail" ||
                            incomeType === "hybrid") && (
                            <TableCell
                              sx={{ fontSize: "1rem", fontWeight: "600" }}
                              align="center"
                            >
                              Trail Revenue Rate (PA)
                            </TableCell>
                          )}
                        {sessionStorage.getItem("Profile") === "D" &&
                          (incomeType === "hybrid" ||
                            incomeType === "upfront") && (
                            <TableCell
                              sx={{ fontSize: "1rem", fontWeight: "600" }}
                              align="center"
                            >
                              Upfront Revenue Rate (PA)
                            </TableCell>
                          )}
                        {companyTypeCode === "D" &&
                          (incomeType === "trail" ||
                            incomeType === "hybrid") && (
                            <>
                              <TableCell
                                sx={{ fontSize: "1rem", fontWeight: "600" }}
                                align="center"
                              >
                                Trail Payout Rate (PA)
                              </TableCell>
                              <TableCell
                                sx={{ fontSize: "1rem", fontWeight: "600" }}
                                align="center"
                              >
                                Trail Revenue Rate (PA)
                              </TableCell>
                            </>
                          )}
                        {companyTypeCode === "D" &&
                          (incomeType === "hybrid" ||
                            incomeType === "upfront") && (
                            <>
                              <TableCell
                                sx={{ fontSize: "1rem", fontWeight: "600" }}
                                align="center"
                              >
                                Upfront Payout Rate (PA)
                              </TableCell>
                              <TableCell
                                sx={{ fontSize: "1rem", fontWeight: "600" }}
                                align="center"
                              >
                                Upfront Revenue Rate (PA)
                              </TableCell>
                            </>
                          )}
                        {companyTypeCode === "S" &&
                          (incomeType === "trail" ||
                            incomeType === "hybrid") && (
                            <TableCell
                              sx={{ fontSize: "1rem", fontWeight: "600" }}
                              align="center"
                            >
                              Trail Revenue Rate (PA)
                            </TableCell>
                          )}
                        {companyTypeCode === "S" &&
                          (incomeType === "hybrid" ||
                            incomeType === "upfront") && (
                            <TableCell
                              sx={{ fontSize: "1rem", fontWeight: "600" }}
                              align="center"
                            >
                              Upfront Revenue Rate (PA)
                            </TableCell>
                          )}
                        {sessionStorage.getItem("Profile") === "D" &&
                          (incomeType === "trail" ||
                            incomeType === "hybrid") && (
                            <TableCell
                              sx={{ fontSize: "1rem", fontWeight: "600" }}
                              align="center"
                            >
                              Trail Revenue
                            </TableCell>
                          )}
                        {sessionStorage.getItem("Profile") === "D" &&
                          (incomeType === "hybrid" ||
                            incomeType === "upfront") && (
                            <TableCell
                              sx={{ fontSize: "1rem", fontWeight: "600" }}
                              align="center"
                            >
                              Upfront Revenue
                            </TableCell>
                          )}
                        {companyTypeCode === "D" &&
                          (incomeType === "trail" ||
                            incomeType === "hybrid") && (
                            <>
                              <TableCell
                                sx={{ fontSize: "1rem", fontWeight: "600" }}
                                align="center"
                              >
                                Trail Payout
                              </TableCell>
                              <TableCell
                                sx={{ fontSize: "1rem", fontWeight: "600" }}
                                align="center"
                              >
                                Trail Revenue
                              </TableCell>
                            </>
                          )}
                        {companyTypeCode === "D" &&
                          (incomeType === "hybrid" ||
                            incomeType === "upfront") && (
                            <>
                              <TableCell
                                sx={{ fontSize: "1rem", fontWeight: "600" }}
                                align="center"
                              >
                                Upfront Payout
                              </TableCell>
                              <TableCell
                                sx={{ fontSize: "1rem", fontWeight: "600" }}
                                align="center"
                              >
                                Upfront Revenue
                              </TableCell>
                            </>
                          )}
                        {companyTypeCode === "S" &&
                          (incomeType === "trail" ||
                            incomeType === "hybrid") && (
                            <TableCell
                              sx={{ fontSize: "1rem", fontWeight: "600" }}
                              align="center"
                            >
                              Trail Revenue
                            </TableCell>
                          )}
                        {companyTypeCode === "S" &&
                          (incomeType === "hybrid" ||
                            incomeType === "upfront") && (
                            <TableCell
                              sx={{ fontSize: "1rem", fontWeight: "600" }}
                              align="center"
                            >
                              Upfront Revenue
                            </TableCell>
                          )}
                        <TableCell
                          sx={{ fontSize: "1rem", fontWeight: "600" }}
                          align="center"
                        >
                          Acknowledgement Reference No *
                        </TableCell>
                        <TableCell
                          sx={{ fontSize: "1rem", fontWeight: "600" }}
                          align="center"
                        >
                          Form Upload{" "}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableCell>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <MobileDatePicker
                            label="Order Date"
                            inputFormat="DD/MM/YYYY"
                            value={orderDate}
                            onChange={(newValue: any) => {
                              seOrderDate(newValue.toJSON());
                            }}
                            renderInput={(params) => (
                              <TextField
                                size="small"
                                {...params}
                                error={errors.orderDate ? true : false}
                                helperText={
                                  errors.orderDate === undefined
                                    ? ""
                                    : errors.orderDate.message + ""
                                }
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </TableCell>
                      <TableCell component="th" scope="row" align="center">
                        <Typography>{clientDetails.fullName}</Typography>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {clientDetails.panCardNo}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <form
                          onSubmit={handleSubmit(onSubmitInvestNow)}
                          id="form1"
                        >
                          <TextField
                            size="small"
                            fullWidth
                            label="Commitment Amount"
                            error={errors.commitmentAmount ? true : false}
                            helperText={
                              !errors.commitmentAmount
                                ? ""
                                : errors.commitmentAmount.message + ""
                            }
                            {...register(
                              "commitmentAmount",
                              registerOptions.commitmentAmount
                            )}
                          />
                        </form>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <form
                          onSubmit={handleSubmit(onSubmitInvestNow)}
                          id="form1"
                        >
                          <TextField
                            label="Investment Amount"
                            size="small"
                            fullWidth
                            defaultValue={item.schemeMinInvestment}
                            error={errors.buyRate ? true : false}
                            helperText={
                              !errors.buyRate ? "" : errors.buyRate.message + ""
                            }
                            {...register("buyRate")}
                          />
                        </form>
                      </TableCell>
                      {sessionStorage.getItem("Profile") === "D" &&
                        (incomeType === "trail" || incomeType === "hybrid") && (
                          <TableCell component="th" scope="row" align="center">
                            {!isNaN(disRevenueRate)
                              ? disRevenueRate.toFixed(2)
                              : 0 || 0}
                          </TableCell>
                        )}
                      {sessionStorage.getItem("Profile") === "D" &&
                        incomeType === "hybrid" && (
                          <TableCell component="th" scope="row" align="center">
                            {!isNaN(disUFRevenueRate)
                              ? disUFRevenueRate.toFixed(2)
                              : 0 || 0}
                          </TableCell>
                        )}
                      {sessionStorage.getItem("Profile") === "D" &&
                        incomeType === "upfront" && (
                          <TableCell component="th" scope="row" align="center">
                            {!isNaN(disRevenueRate)
                              ? disRevenueRate.toFixed(2)
                              : 0 || 0}
                          </TableCell>
                        )}
                      {companyTypeCode === "D" &&
                        (incomeType === "trail" || incomeType === "hybrid") && (
                          <>
                            <TableCell
                              component="th"
                              scope="row"
                              align="center"
                            >
                              {!isNaN(disRevenueRate)
                                ? disRevenueRate.toFixed(2)
                                : 0 || 0}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              align="center"
                            >
                              {!isNaN(brokerRevenueRate)
                                ? brokerRevenueRate.toFixed(2)
                                : 0 || 0}
                            </TableCell>
                          </>
                        )}
                      {companyTypeCode === "D" && incomeType === "hybrid" && (
                        <>
                          <TableCell component="th" scope="row" align="center">
                            {!isNaN(disUFRevenueRate)
                              ? disUFRevenueRate.toFixed(2)
                              : 0 || 0}
                          </TableCell>
                          <TableCell component="th" scope="row" align="center">
                            {!isNaN(brokerUFRevenueRate)
                              ? brokerUFRevenueRate.toFixed(2)
                              : 0 || 0}
                          </TableCell>
                        </>
                      )}
                      {companyTypeCode === "D" && incomeType === "upfront" && (
                        <>
                          <TableCell component="th" scope="row" align="center">
                            {!isNaN(disRevenueRate)
                              ? disRevenueRate.toFixed(2)
                              : 0 || 0}
                          </TableCell>
                          <TableCell component="th" scope="row" align="center">
                            {!isNaN(brokerRevenueRate)
                              ? brokerRevenueRate.toFixed(2)
                              : 0 || 0}
                          </TableCell>
                        </>
                      )}
                      {companyTypeCode === "S" &&
                        (incomeType === "trail" || incomeType === "hybrid") && (
                          <TableCell component="th" scope="row" align="center">
                            {!isNaN(brokerRevenueRate)
                              ? brokerRevenueRate.toFixed(2)
                              : 0 || 0}
                          </TableCell>
                        )}
                      {companyTypeCode === "S" && incomeType === "hybrid" && (
                        <TableCell component="th" scope="row" align="center">
                          {!isNaN(brokerUFRevenueRate)
                            ? brokerUFRevenueRate.toFixed(2)
                            : 0 || 0}
                        </TableCell>
                      )}
                      {companyTypeCode === "S" && incomeType === "upfront" && (
                        <TableCell component="th" scope="row" align="center">
                          {!isNaN(brokerRevenueRate)
                            ? brokerRevenueRate.toFixed(2)
                            : 0 || 0}
                        </TableCell>
                      )}
                      {sessionStorage.getItem("Profile") === "D" &&
                        (incomeType === "trail" || incomeType === "hybrid") && (
                          <TableCell component="th" scope="row" align="center">
                            {disRevenue || 0}
                          </TableCell>
                        )}
                      {sessionStorage.getItem("Profile") === "D" &&
                        incomeType === "hybrid" && (
                          <TableCell component="th" scope="row" align="center">
                            {disUFRevenue || 0}
                          </TableCell>
                        )}
                      {sessionStorage.getItem("Profile") === "D" &&
                        incomeType === "upfront" && (
                          <TableCell component="th" scope="row" align="center">
                            {disRevenue || 0}
                          </TableCell>
                        )}
                      {companyTypeCode === "D" &&
                        (incomeType === "trail" || incomeType === "hybrid") && (
                          <>
                            <TableCell
                              component="th"
                              scope="row"
                              align="center"
                            >
                              {disRevenue || 0}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              align="center"
                            >
                              {brokerRevenue || 0}
                            </TableCell>
                          </>
                        )}
                      {companyTypeCode === "D" && incomeType === "hybrid" && (
                        <>
                          <TableCell component="th" scope="row" align="center">
                            {disUFRevenue || 0}
                          </TableCell>
                          <TableCell component="th" scope="row" align="center">
                            {brokerUFRevenue || 0}
                          </TableCell>
                        </>
                      )}
                      {companyTypeCode === "D" && incomeType === "upfront" && (
                        <>
                          <TableCell component="th" scope="row" align="center">
                            {disRevenue || 0}
                          </TableCell>
                          <TableCell component="th" scope="row" align="center">
                            {brokerRevenue || 0}
                          </TableCell>
                        </>
                      )}
                      {companyTypeCode === "S" &&
                        (incomeType === "trail" || incomeType === "hybrid") && (
                          <TableCell component="th" scope="row" align="center">
                            {brokerRevenue || 0}
                          </TableCell>
                        )}
                      {companyTypeCode === "S" && incomeType === "hybrid" && (
                        <TableCell component="th" scope="row" align="center">
                          {brokerUFRevenue || 0}
                        </TableCell>
                      )}
                      {companyTypeCode === "S" && incomeType === "upfront" && (
                        <TableCell component="th" scope="row" align="center">
                          {brokerRevenue || 0}
                        </TableCell>
                      )}
                      <TableCell component="th" scope="row">
                        <form
                          onSubmit={handleSubmit(onSubmitInvestNow)}
                          id="form1"
                        >
                          <TextField
                            size="small"
                            {...register("acknowledgementNo")}
                          />
                        </form>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Button
                          component="label"
                          size="small"
                          variant="contained"
                          startIcon={<CloudUploadIcon />}
                          sx={{ mb: 1 }}
                        >
                          Form Upload
                          <input
                            hidden
                            type="file"
                            accept=".pdf"
                            onChange={(event: any) =>
                              setUploadForm(event.target.files[0])
                            }
                          />
                        </Button>
                        <Typography sx={{ fontSize: 12 }}>
                          {uploadForm?.name}
                        </Typography>
                      </TableCell>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Box
                sx={{
                  textAlign: "center",
                  mt: 2,
                  display: "flex",
                  justifyContent: "space-evenly",
                }}
              >
                <form onSubmit={handleSubmit(onSubmitInvestNow)} id="form1">
                  <Button
                    type="submit"
                    onClick={() => handleSubmit(onSubmitInvestNow)}
                    variant="contained"
                    disabled={OrderNowBtn}
                  >
                    Order Now
                  </Button>
                </form>
                <Button
                  disabled={!!!clientId}
                  onClick={() => addToProposal()}
                  variant="contained"
                >
                  Proposal
                </Button>
              </Box>
            </Box>
          ))
        : aifSchemeCode && (
            <Typography variant="subtitle1" textAlign={"center"} sx={{ mt: 2 }}>
              {" "}
              <CircularProgress size={15} sx={{ mr: 1 }} /> Please Wait We're
              Fetching AIF Schemes Details For You...{" "}
            </Typography>
          )}
    </>
  );
};

export default OrderWindowForAIF;
