import React from "react";
import { Button } from "@mui/material";

const gridStyle = {
  display: "grid",
  gridTemplateColumns: "repeat(auto-fit, minmax(min(5.25rem, 100%), 1fr))",
  gap: "0.75rem",
};

const buttonStyle = {
  height: "60px", // Adjust the height as needed
  width: "90%", // Set the width to the same value as the height
  borderRadius: "10px",
  padding: "6px 10px",
};

const buttonConfigs = [
  { label: "Fixed Income", url: "/ProductFixedIncome" },
  { label: "Mutual Fund", url: "/ProductMutualFund" },
  { label: "PMS", url: "/ProductPMS" },
  { label: "AIF", url: "/ProductAIF" },
  { label: "Private Equity", url: "/PrivateEquity" },
  { label: "Unlisted", url: "/products", productNumber: "5" },
  { label: "Loan", url: "/products", productNumber: "6" },
  { label: "Insurance", url: "/ProductInsurance" },
  { label: "NCD", url: "/products", productNumber: "8" },
  { label: "Research service", url: "/products", productNumber: "13" },
];

const ZohoLinks = () => {
  return (
    <>
      <div style={gridStyle}>
        {buttonConfigs?.map((btnConfigObj, index) => (
          <div key={index}>
            <Button
              variant="contained"
              onClick={() => {
                if (btnConfigObj.productNumber) {
                  sessionStorage.setItem(
                    "ProductNumber",
                    btnConfigObj.productNumber
                  );
                }
                window.location.href = btnConfigObj.url;
              }}
              style={buttonStyle}
            >
              {btnConfigObj.label}
            </Button>
          </div>
        ))}
      </div>
    </>
  );
};

export default ZohoLinks;
