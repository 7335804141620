/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect } from "react";
import {
  Box,
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TextField,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Autocomplete,
} from "@mui/material";
import { useQuery } from "react-query";
import {
  GetAllClient,
  GetBrokeragePayoutByCompanyMasterCode,
  GetByIdProductList,
  GetListOfUserByCompanyMasterCodeAndUserTypeCode,
  product_listOfAllProductType,
} from "../api/Api";
import { withParam } from "../utils/Router.Helper";
import TableHeadData from "./TableHeadData";
import { useLocation } from "react-router-dom";

const DistributorUnlisted = (props: any) => {
  const [distributorCode, setDistributorCode] = React.useState("");
  const [distributorUserCode, setDistributorUserCode] = React.useState("");
  const [JV, setJV] = React.useState("");
  // fetch the client id from the url
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [clientId, setClientId] = React.useState("");
  const [clientDetails, setClientDetails] = React.useState<any>("");
  const clientIdFromParams = queryParams.get("clientId");
  const [selectedUser, setSelectedUser] = React.useState(null);

  const companyMasterCode: any = !!sessionStorage.getItem("CompanyData")
    ? sessionStorage.getItem("CompanyData")
    : !!sessionStorage.getItem("CompanyMasterCode")
    ? sessionStorage.getItem("CompanyMasterCode")
    : "";

  const { data } = useQuery(
    "UnlistedProductListByID",
    () =>
      GetByIdProductList(
        props.params.unlistedMasterCode,
        "?companyMasterCode=" + companyMasterCode
      ),
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: UserProfilesByCompanyMasterCode } = useQuery(
    [
      "userProfileByCompany",
      sessionStorage.getItem("CompanyData"),
      sessionStorage.getItem("CompanyTypeCode"),
    ],
    () =>
      GetListOfUserByCompanyMasterCodeAndUserTypeCode(
        sessionStorage.getItem("CompanyTypeCode") === "S"
          ? "E"
          : sessionStorage.getItem("CompanyTypeCode"),
        sessionStorage.getItem("CompanyData")
      ),
    {
      enabled:
        !!sessionStorage.getItem("CompanyData") &&
        !!sessionStorage.getItem("CompanyTypeCode"),
      refetchOnWindowFocus: false,
    }
  );

  // LIST OF ALL PRODUCT TYPES
  const { data: listOfAllProductType } = useQuery(
    "ListOfAllProductTypes",
    product_listOfAllProductType,
    {
      refetchOnWindowFocus: false,
    }
  );
  // fetch UNLISTED product type code
  let productTypeCodeFromList = listOfAllProductType?.data?.productType.filter(
    (productObj: any) => productObj.productName === "UNLISTED"
  )[0].productTypeCode;

  // FETCH BROKERAGE PAYOUT
  const { data: fetchBrokeragePayout } = useQuery(
    "GetBrokeragePayoutByCompanyMasterCode",
    () => GetBrokeragePayoutByCompanyMasterCode(companyMasterCode),
    {
      refetchOnWindowFocus: false,
    }
  );
  const filteredUnlistedBrokeragePayout =
    fetchBrokeragePayout?.data?.companyBrokerage?.filter(
      (brokerageObj: any) =>
        brokerageObj.productTypeCode === productTypeCodeFromList
    );
  // destructuring brokerage payout from the filtered array and assign null if the array is empty
  let brokeragePayout = filteredUnlistedBrokeragePayout?.length
    ? filteredUnlistedBrokeragePayout[0]
    : null;

  const data2 = useQuery(
    ["getAllClients", distributorCode],
    () => GetAllClient(companyMasterCode, distributorCode),
    {
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (clientIdFromParams) {
      const preselectedClient = data2.data?.data?.clients?.find(
        (client: any) => client.clientId === Number(clientIdFromParams)
      );
      if (preselectedClient) {
        setClientId(preselectedClient.clientId);
        setClientDetails(preselectedClient);
        // setValue("clients", preselectedClient);

        // Find the matching user based on distributorEmployeeCode
        const distributorEmployeeCode =
          preselectedClient.distributorEmployeeCode;
        const matchedUser =
          UserProfilesByCompanyMasterCode?.data?.userProfile?.find(
            (user: any) => user.employeeCode === distributorEmployeeCode
          );
        if (matchedUser) {
          setSelectedUser(matchedUser);
        } else {
          setSelectedUser(null);
        }
      }
    }
  }, [
    clientIdFromParams,
    // setValue,
    // data2.data?.data?.clients,
    UserProfilesByCompanyMasterCode,
  ]);

  return (
    <>
      <div style={{ padding: "0rem 1rem" }}>
        <Box sx={{ minHeight: "calc(100vh - 150px)", marginTop: "3%" }}>
          {data && (
            <>
              <Grid container mb={4}>
                <Grid item md={4} sx={{ display: "flex" }}>
                  <img
                    src={data.data.unlistedList[0].scriptLogo}
                    style={{ width: "80px", height: "80px" }}
                    alt=""
                  />
                  <div style={{ marginLeft: "1rem" }}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Typography
                        variant="subtitle1"
                        fontWeight={"400"}
                        gutterBottom
                      >
                        {" "}
                        <b>Script Name :</b>{" "}
                        {data.data.unlistedList[0].scriptName}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        fontWeight={"400"}
                        gutterBottom
                      >
                        {" "}
                        <b>ISIN :</b> {data.data.unlistedList[0].isinNumber}
                      </Typography>
                    </div>

                    {sessionStorage.getItem("Profile") === "B" ||
                    sessionStorage.getItem("Profile") === "E" ? (
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          options={
                            UserProfilesByCompanyMasterCode
                              ? UserProfilesByCompanyMasterCode.data.userProfile.map(
                                  (options: any) => options
                                )
                              : []
                          }
                          getOptionLabel={(options: any) => options?.userCode}
                          sx={{ width: 308, mt: 2 }}
                          onChange={(options: any, newValue) => {
                            setDistributorCode(
                              newValue ? newValue.employeeCode : ""
                            );
                            setDistributorUserCode(
                              newValue ? newValue.userCode : ""
                            );
                            // sessionStorage.setItem("CompanyData",newValue?.employeeCode)
                            setSelectedUser(newValue);
                          }}
                          renderInput={(params: any) => (
                            <TextField
                              {...params}
                              label="Select User"
                              size="small"
                            />
                          )}
                          value={selectedUser ? selectedUser : null}
                        />
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          label="JV"
                          onChange={(e: any) => setJV(e.target.value)}
                          sx={{ width: 308, mt: 2 }}
                          size="small"
                          // {...register("clientSaleRate")}
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </Grid>
              </Grid>

              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 1070 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" sx={{ width: 150 }}>
                        Order Date
                      </TableCell>
                      {/* manufacture cost price */}
                      <TableCell align="center" sx={{ width: 150 }}>
                        {/* Manufacture Cost Price */}
                        {sessionStorage.getItem("Profile") === "B"
                          ? "Manufacture Cost Price"
                          : "Buy Rate"}
                      </TableCell>
                      {/* <TableCell align="center" sx={{ width: 150 }}>
                        Manufacture Carrying Cost
                      </TableCell> */}
                      {sessionStorage.getItem("Profile") === "B" && (
                        <TableCell align="center" sx={{ width: 150 }}>
                          Manufacturer Landing Price
                        </TableCell>
                      )}
                      <TableCell align="center" sx={{ width: 150 }}>
                        Sell Price
                      </TableCell>
                      {/* <TableCell align='center'>TCS (0.1%)</TableCell> */}
                      <TableCell align="center">Total</TableCell>
                      <TableCell align="center" sx={{ width: 150 }}>
                        Bid Quantity
                      </TableCell>
                      <TableCell align="center" sx={{ width: 350 }}>
                        Client
                      </TableCell>
                      {/* // hide rm revenue and distributor payout  */}
                      {sessionStorage.getItem("Profile") === "E" &&
                      sessionStorage.getItem("CompanyTypeCode") ===
                        "S" ? null : (
                        <>
                          <TableCell align="center">RM Revenue</TableCell>
                          <TableCell align="center">
                            Distributor Payout
                          </TableCell>
                        </>
                      )}
                      <TableCell align="center"></TableCell>
                      <TableCell align="center">Order</TableCell>
                      {/* <TableCell align="center">Add To Proposal</TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.data?.unlistedList?.map((item: any) => (
                      <TableHeadData
                        item={item}
                        distributorCode={distributorCode}
                        JV={JV}
                        companyMasterCode={companyMasterCode}
                        distributorUserCode={distributorUserCode}
                        brokeragePayout={
                          brokeragePayout ? brokeragePayout : null
                        }
                        data2={data2}
                        clientId={clientId}
                        setClientId={setClientId}
                        clientDetails={clientDetails}
                        setClientDetails={setClientDetails}
                        UserProfilesByCompanyMasterCode={
                          UserProfilesByCompanyMasterCode
                        }
                        setselectedUser={setSelectedUser}
                        selectedUser={selectedUser}
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </Box>
      </div>
    </>
  );
};

export default withParam(DistributorUnlisted);
