import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Grid, Tab } from "@mui/material";
import React from "react";
import OrderBookTableData from "./OrderBookTableData";

const OrderBookType = () => {
  const [value, setValue] = React.useState("PENDING");

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  return (
    <div>
      <Grid>
        {/* <Grid item xs={3}>
    <h1>Filter</h1>
  </Grid> */}
        <Grid item xs={12}>
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChangeTab}
                  aria-label="lab API tabs example"
                >
                  <Tab label="pending" value="PENDING" />
                  <Tab label="Completed" value="COMPLETED" />
                  <Tab label="Rejected" value="REJECTED" />
                </TabList>
              </Box>
              <TabPanel value="PENDING" sx={{ padding: "24px 2px" }}>
                <OrderBookTableData selectedTab={value} />
              </TabPanel>
              <TabPanel value="COMPLETED" sx={{ padding: "24px 2px" }}>
                <OrderBookTableData selectedTab={value} />
              </TabPanel>
              <TabPanel value="REJECTED" sx={{ padding: "24px 2px" }}>
                <OrderBookTableData selectedTab={value} />
              </TabPanel>
            </TabContext>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default OrderBookType;
