/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import {
  CSSObject,
  CssBaseline,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
  Theme,
  useTheme,
  useMediaQuery,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
} from "@mui/material";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import MailIcon from "@mui/icons-material/Mail";
import MuiDrawer from "@mui/material/Drawer";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import DashboardIcon from "@mui/icons-material/Dashboard";
import CategoryIcon from "@mui/icons-material/Category";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import UnlistedFixedRate from "./Products/UnlistedFixedRate";
import CardTravelIcon from "@mui/icons-material/CardTravel";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import SummarizeIcon from "@mui/icons-material/Summarize";
import AddIcCallIcon from "@mui/icons-material/AddIcCall";
import WarehouseOutlinedIcon from "@mui/icons-material/WarehouseOutlined";
import AddShoppingCartOutlinedIcon from "@mui/icons-material/AddShoppingCartOutlined";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import SortByAlphaSharpIcon from "@mui/icons-material/SortByAlphaSharp";
import HandshakeSharpIcon from "@mui/icons-material/HandshakeSharp";
import BorderColorSharpIcon from "@mui/icons-material/BorderColorSharp";
import CategorySharpIcon from "@mui/icons-material/CategorySharp";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import newProduct from "../images/IconeImg/newProduct.png";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";

const SideNavbar = () => {
  const drawerWidth = 240;
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  // const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = useMediaQuery("(max-width:730px)");

  // automatically close drawer when the screen size is >= 730px
  React.useEffect(() => {
    if (!isMobile) {
      setOpen(false);
    }
  }, [isMobile]);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const drawerItems: any = [
    // Admin Json
    // { title: "", link: "", icon: "", type: "A" },
    {
      title: "User Management",
      link: "/UserDashBoard",
      icon: <ManageAccountsIcon />,
      type: "A",
    },
    {
      title: "Portfolio Analyzer",
      link: "/PortfolioAnalyzer",
      icon: <QueryStatsIcon />,
      type: "A",
    },
    {
      title: "Unlisted Master",
      link: "/MasterUnlisted",
      icon: <ShowChartIcon />,
      type: "A",
    },
    {
      title: "Broker Master",
      link: "/brokerMaster",
      icon: <CardTravelIcon />,
      type: "A",
    },
    {
      title: "Bond Master",
      link: "/bondMaster",
      icon: <AttachMoneyIcon />,
      type: "A",
    },
    {
      title: "Product Type",
      link: "/productType",
      icon: <PrecisionManufacturingIcon />,
      type: "A",
    },
    // { title: "Quote Book", link: "/add-quote", icon:<RequestQuoteIcon />, type:"A" },

    // Back Office Json
    // { title: "", link: "", icon: "", type: "B" },
    {
      title: "User Management",
      link: "/UserDashBoard",
      icon: <ManageAccountsIcon />,
      type: "B",
    },
    {
      title: "Portfolio Analyzer",
      link: "/PortfolioAnalyzer",
      icon: <QueryStatsIcon />,
      type: "B",
    },
    {
      title: "Products",
      link: "/products",
      icon: <CategorySharpIcon />,
      type: "B",
    },
    {
      title: "Pre-Order",
      link: "/PreOrder",
      icon: <SortByAlphaSharpIcon />,
      type: "B",
    },
    {
      title: "Fixed Rate Unlisted",
      link: "/FixedRateUnlisted",
      icon: <TrendingUpIcon />,
      type: "B",
    },
    {
      title: "Active Unlisted",
      link: "/ActiveUnlisted",
      icon: <ShowChartIcon />,
      type: "B",
    },
    {
      title: "Unlisted Master",
      link: "/MasterUnlisted",
      icon: <ShowChartIcon />,
      type: "B",
    },
    {
      title: "Bond Master",
      link: "/bondMaster",
      icon: <AttachMoneyIcon />,
      type: "B",
    },
    {
      title: "Company",
      link: "/CompanyManagement",
      icon: <InboxIcon />,
      type: "B",
    },
    {
      title: "Order Book",
      link: "/orderbook",
      icon: <ImportContactsIcon />,
      type: "B",
    },
    {
      title: "Quote Book",
      link: "/add-quote",
      icon: <RequestQuoteIcon />,
      type: "B",
    },
    { title: "Clients", link: "/clients", icon: <AccountBoxIcon />, type: "B" },
    {
      title: "Manufacturer Unlisted",
      link: "/ManufacturerUnlisted",
      icon: <WarehouseOutlinedIcon />,
      type: "B",
    },
    {
      title: "BO Payout",
      link: "/backOffpayoutDashboard",
      icon: <CurrencyRupeeIcon />,
      type: "B",
    },
    {
      title: "Manage AMC",
      link: "/manageAmc",
      icon: <AccountBalanceIcon />,
      type: "B",
    },
    {
      title: "Report Upload",
      link: "/reportUpload",
      icon: <SummarizeIcon />,
      type: "B",
    },
    {
      title: "Book a Call",
      link: "/getAllBookACall",
      icon: <AddIcCallIcon />,
      type: "B",
    },
    {
      title: "Business Upload",
      link: "/BusinessUpload",
      icon: <AddBusinessIcon />,
      type: "B",
    },

    // Manufacture Json
    { title: "", link: "", icon: "", type: "M" },
    {
      title: "Products",
      link: "/products",
      icon: <CategorySharpIcon />,
      type: "M",
    },
    {
      title: "Manufacturer Unlisted",
      link: "/ManufacturerUnlisted",
      icon: <ShowChartIcon />,
      type: "M",
    },
    {
      title: "Portfolio Analyzer",
      link: "/PortfolioAnalyzer",
      icon: <QueryStatsIcon />,
      type: "M",
    },
    {
      title: "Order Book",
      link: "/orderbook",
      icon: <ImportContactsIcon />,
      type: "M",
    },
    {
      title: "Payout",
      link: "/payoutDashboard",
      icon: <CurrencyRupeeIcon />,
      type: "M",
    },
    {
      title: "Quote Book",
      link: "/add-quote",
      icon: <RequestQuoteIcon />,
      type: "M",
    },
    {
      title: "Bond Master",
      link: "/bondMaster",
      icon: <AttachMoneyIcon />,
      type: "M",
    },

    // Distributor Json
    // { title: "", link: "", icon: "", type: "D" },
    {
      title: "Products",
      link: "/products",
      icon: <CategorySharpIcon />,
      type: "D",
    },
    {
      title: "Portfolio Analyzer",
      link: "/PortfolioAnalyzer",
      icon: <QueryStatsIcon />,
      type: "D",
    },
    // { title: "Dashboard", link: "/distDashboard", icon:<DashboardIcon />, type:"D" },
    {
      title: "Order Book",
      link: "/orderbook",
      icon: <ImportContactsIcon />,
      type: "D",
    },
    {
      title: "Pre-Order",
      link: "/PreOrder",
      icon: <SortByAlphaSharpIcon />,
      type: "D",
    },
    {
      title: "Appointments",
      link: "/bookaCallMain",
      icon: <InsertInvitationIcon />,
      type: "D",
    },
    { title: "Clients", link: "/clients", icon: <AccountBoxIcon />, type: "D" },
    {
      title: "Payout",
      link: "/payoutDashboard",
      icon: <CurrencyRupeeIcon />,
      type: "D",
    },
    {
      title: "Term Sheet",
      link: "/termsheet",
      icon: <InsertDriveFileIcon />,
      type: "D",
    },
    {
      title: "Upload Term Sheet",
      link: "/uploadtermsheet",
      icon: <CloudUploadIcon />,
      type: "D",
    },
    { title: "Proposal", link: "/cart", icon: <ShoppingCartIcon />, type: "D" },
    {
      title: "Book a Call",
      link: "/getAllBookACall",
      icon: <AddIcCallIcon />,
      type: "D",
    },

    //Employee json
    // { title: "", link: "", icon: "", type: "E" },
    {
      title: "User Management",
      link: "/UserDashBoard",
      icon: <ManageAccountsIcon />,
      type: "E",
    },
    {
      title: "Portfolio Analyzer",
      link: "/PortfolioAnalyzer",
      icon: <QueryStatsIcon />,
      type: "E",
    },
    {
      title: "Products",
      link: "/products",
      icon: <CategorySharpIcon />,
      type: "E",
    },
    {
      title: "Pre-Order",
      link: "/PreOrder",
      icon: <SortByAlphaSharpIcon />,
      type: "E",
    },
    // { title: "Dashboard", link: "/distDashboard", icon:<DashboardIcon />, type:"E" },
    {
      title: "Partner",
      link: "/PartnerManagement",
      icon: <HandshakeSharpIcon />,
      type: "E",
    },
    {
      title: "Order Book",
      link: "/orderbook",
      icon: <ImportContactsIcon />,
      type: "E",
    },
    {
      title: "Appointments",
      link: "/bookaCallMain",
      icon: <InsertInvitationIcon />,
      type: "E",
    },
    { title: "Clients", link: "/clients", icon: <AccountBoxIcon />, type: "E" },
    {
      title: "Revenue Credit",
      link: "/revenueCreditDashboard",
      icon: <CurrencyRupeeIcon />,
      type: "E",
    },
    {
      title: "Term Sheet",
      link: "/termsheet",
      icon: <InsertDriveFileIcon />,
      type: "E",
    },
    {
      title: "Upload Term Sheet",
      link: "/uploadtermsheet",
      icon: <CloudUploadIcon />,
      type: "E",
    },
    { title: "Proposal", link: "/cart", icon: <ShoppingCartIcon />, type: "E" },
    {
      title: "Book a Call",
      link: "/getAllBookACall",
      icon: <AddIcCallIcon />,
      type: "E",
    },
  ];

  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    height: "90%",
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  }));

  const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflow: "hidden",
    overflowY: "auto",
    height: "90%",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
    // apply "top" to the drawer paper class
    top: "60px !important",
  });

  const openedMixin = (theme: Theme): CSSObject => ({
    // width: drawerWidth,
    minWidth: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    height: "90%",
    overflowY: "auto",
    // apply "top" to the drawer paper class
    top: "60px !important",
  });

  const StyledLink = styled(Link)(({ theme }) => ({
    display: "flex",
    minHeight: "48px",
    justifyContent: open ? "initial" : "center",
    alignItems: "center",
    padding: "0px 20px",
    textDecoration: "none",
    color: "inherit",
    // "&:hover": {
    //   backgroundColor: "#00d5d5",
    //   color: "#fff",
    // },
  }));

  const drawerContent = (
    <List>
      {drawerItems
        .filter((it: any) => it.type === sessionStorage.getItem("Profile"))
        .map((text: any) => {
          return (
            <ListItem
              key={text.title}
              disablePadding
              sx={{
                display: "block",
                "&:hover": {
                  backgroundColor: "#00d5d5",
                  color: "#fff",
                  "& .MuiListItemIcon-root": {
                    color: "#fff",
                  },
                  "& .MuiListItemText-root": {
                    color: "#fff",
                  },
                },
              }}
            >
              <StyledLink to={text.link} onClick={handleDrawerToggle}>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {text.icon}
                </ListItemIcon>
                <ListItemText
                  primary={text.title}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </StyledLink>
            </ListItem>
          );
        })}
    </List>
  );

  return (
    <>
      <CssBaseline />
      {isMobile && (
        <AppBar position="fixed">
          <IconButton
            color="inherit"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{
              m: 0,
              position: "absolute",
              top: "60px",
              padding: "12px",
            }}
          >
            <MenuIcon />
          </IconButton>
        </AppBar>
      )}

      {/* DRAWER for MOBILE */}
      {isMobile ? (
        <MuiDrawer
          variant="temporary"
          open={open}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile
          }}
        >
          {drawerContent}
        </MuiDrawer>
      ) : (
        // DRAWER for DESKTOP
        <Drawer
          variant="permanent"
          open={open}
          onMouseOver={() => setOpen(true)}
          onMouseOut={() => setOpen(false)}
        >
          {drawerContent}
        </Drawer>
      )}
    </>
  );
};

export default SideNavbar;
