/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Typography,
  Slider,
  TextField,
  Button,
  Modal,
  Box,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { style } from "../../Clients";
import { useForm } from "react-hook-form";
import ReactApexChart from "react-apexcharts";
import { Mode } from "@mui/icons-material";
import { getLoanAmc } from "../../../api/Api";
import { withParam } from "../../../utils/Router.Helper";
import { Link } from "react-router-dom";

interface PropsType {
  params: {
    amcId: String;
    productMasterCode: string;
  };
}

const Loan = () => {
  const [loanAmount, setLoanAmount] = useState<any>(300000);
  const [interestRate, setInterestRate] = useState<any>(8);
  const [loanTenure, setLoanTenure] = useState<number>(1);
  const [totalValue, setTotalValue] = useState<any>(0);
  const [interestAmount, setInterestAmount] = useState<any>(0);
  const [totalPayable, setTotalPayable] = useState<number>(0);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [productLoan, setProductLoan] = useState<any>([]);
  const [productMasterCode, setProductMasterCode] = useState<any>([]);

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    const value = Array.isArray(newValue) ? newValue[0] : newValue;
    setLoanAmount(value);
  };

  const handleRateChange = (event: Event, newValue: number | number[]) => {
    const rate = Array.isArray(newValue) ? newValue[0] : newValue;
    setInterestRate(rate);
  };

  const handleTenureChange = (event: Event, newValue: number | number[]) => {
    const tenure = Array.isArray(newValue) ? newValue[0] : newValue;
    setLoanTenure(tenure);
  };

  const calculateEMI = () => {
    // Calculate EMI and update state variables
    // Implement your EMI calculation logic here
    const amount = loanAmount;
    const rate = interestRate;
    const i = rate / 100 / 12;
    const interesti = rate / 100;
    const time = loanTenure;
    const N = time;
    const n = N * 12;
    const total = (amount * i * (1 + i) ** n) / ((1 + i) ** n - 1);
    const TotalPayable = total * n;
    const InterestRate = TotalPayable - amount;
    setTotalPayable(TotalPayable);
    setInterestAmount(InterestRate);
    setTotalValue(total);

    // const n = loanTenure * 12;
    // const total = (amount * i * (1 + i) * n) / ((1 + i) * n - 1);
    // const TotalPayable = total * n;
    // const InterestRate = TotalPayable - amount;
    // setTotalPayable(TotalPayable);
    // setInterestAmount(InterestRate);
    // setTotalValue(total);
  };

  useEffect(() => {
    calculateEMI();
  }, [loanAmount, interestRate, loanTenure]);

  const registerOptions: any = {
    interestRate: {
      required: "Enter Interest Rate between 8% - 18%",
      validate: {
        positive: (ir: any) =>
          parseInt(ir) <= 18 || "should not be greater than 18%",
      },
    },
  };
  const {
    register,
    formState: { errors },
    watch,
  } = useForm({ mode: "onChange" });

  const chartData: any = {
    series: [loanTenure],
    chart: {
      type: "radialBar",
      offsetY: -20,
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
      },
    },
    grid: {
      padding: {
        top: -10,
      },
    },
    labels: ["Average Results"],
  };

  // useEffect(() => {
  //   getLoanAmc().then((resp) => setproductLoan(resp.data));
  //   setproductMasterCode(resp.data.productMasterCode).then(() => {});
  // }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const resp = await getLoanAmc();
        setProductLoan(resp.data);
        setProductMasterCode(resp.data.loanDTOList[0].productMasterCode);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <Container>
      <Grid>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead style={{ background: "#a3e5e5" }}>
              <TableRow>
                <TableCell> </TableCell>
                <TableCell>Loan Type </TableCell>
                <TableCell>Product</TableCell>
                <TableCell>Loan Segment</TableCell>
                <TableCell>Tenure</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {productLoan?.loanDTOList?.map((row: any) => (
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>
                    <img
                      style={{ width: "30px" }}
                      src={row.loanLogo}
                      alt="loanLogo"
                    />
                  </TableCell>
                  <TableCell>{row.loanType}</TableCell>
                  <TableCell sx={{ textDecoration: "underline" }}>
                    <Link to={"/AmcLoanScheme/" + row.productMasterCode}>
                      {" "}
                      {row.productName}
                    </Link>
                  </TableCell>
                  <TableCell>{row.segment}</TableCell>
                  <TableCell>{row.maxTenure}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Box mt={5}>
        <form>
          <Grid container spacing={4}>
            <Grid item xs={10} md={8}>
              <Typography variant="h5">EMI Calculator</Typography>
              {/* Loan Amount Slider */}
              <Box sx={{ p: 2 }}>
                <Typography gutterBottom variant="h6" textAlign={"left"}>
                  Loan Amount (₹)
                </Typography>
                <Typography variant="h6" textAlign={"end"}>
                  {loanAmount}
                </Typography>
                <Slider
                  value={loanAmount}
                  min={300000}
                  max={20000000}
                  step={25000}
                  onChange={handleSliderChange}
                />
                <TextField
                  type="number"
                  value={loanAmount}
                  onBlur={calculateEMI}
                  onChange={(e: any) => setLoanAmount(e.target.value)}
                />
                {/* Interest Rate Slider */}
                <Typography variant="h6" gutterBottom textAlign={"left"}>
                  Interest Rate (p.a)
                </Typography>
                <Typography variant="h6" textAlign={"end"}>
                  {watch("interestRate")}%{" "}
                </Typography>
                <Slider
                  value={interestRate}
                  min={8}
                  max={18}
                  step={0.1}
                  onChange={handleRateChange}
                />
                <TextField
                  type="number"
                  defaultValue={8}
                  // value={interestRate}
                  // onChange={(e: any) => setInterestRate(e.target.value)}
                  // onBlur={calculateEMI}
                  label="Enter Interest Rate"
                  error={errors.interestRate ? true : false}
                  helperText={
                    errors.interestRate === undefined
                      ? ""
                      : errors.interestRate.message + ""
                  }
                  {...register("interestRate", registerOptions.interestRate)}
                />

                {/* Loan Tenure Slider */}
                <Typography gutterBottom variant="h6" textAlign={"left"}>
                  Tenure (Years)
                </Typography>
                <Typography variant="h6" textAlign={"end"}>
                  {loanTenure}
                </Typography>
                <Slider
                  value={loanTenure}
                  min={1}
                  max={20}
                  onChange={handleTenureChange}
                />
                <TextField
                  type="number"
                  label="Enter Tenure"
                  value={loanTenure}
                  onBlur={calculateEMI}
                  onChange={(e: any) => setLoanTenure(e.target.value)}
                />
                {/* <hr/>
              <Button
                variant="contained"
                color="primary"
                onClick={handleApplyNowClick}
                sx={{ m: 1 }}
              >
                Apply Now
              </Button> */}
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="h5">Break-up of Total Payment</Typography>
              <Paper
                elevation={8}
                sx={{ borderRadius: "20px", padding: "10px", height: 350 }}
              >
                <ReactApexChart
                  options={chartData?.plotOptions}
                  series={chartData?.series}
                  type="radialBar"
                  height={350}
                />
              </Paper>
              <Typography variant="h6">
                Principal Amount :-
                {loanAmount}
              </Typography>
              <Typography variant="h6">
                Interest Amount :- {interestAmount.toFixed(2)}
              </Typography>
              <hr />
              <Typography variant="h6">
                Total Amount Payble :-{" "}
                {(parseFloat(loanAmount) + parseFloat(interestAmount)).toFixed(
                  2
                )}
              </Typography>
              <Typography variant="h6">
                Equated Monthly Installments EMI :- {totalValue.toFixed(2)}
              </Typography>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Container>
  );
};

export default withParam(Loan);
