/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { Home } from "./Components/Home";
import appTheme from "./appTheme";
import { Box, ThemeProvider } from "@mui/material";
import "./axios.js";
import AdminDashboard from "./Components/AdminDashboard";
import { ManufractureDashbord } from "./Components/ManufractureDashbord";
import { DistributorDashboard } from "./Components/DistributorDashboard/DistributorDashboard";
import Navbar from "./Components/Navbar";
import Axios from "axios";
import UserProfileView from "./Components/UserProfileView";
import ChangePassword from "./Components/ChangePassword";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.min.css";
import CompanyManagement from "./Components/CompanyManagement";
import CompanyProfileView from "./Components/CompanyProfileView";
import Products from "./Components/Products";
import ProductScrener from "./Components/ProductScrener";
import "./css/navbar.css";
import Aif from "./Components/Products/AIF/Aif";
import FixedIncome from "./Components/Products/FixedIncome";
import PrimaryIssue from "./Components/Products/PrimaryIssue";
import Insurance from "./Components/Products/Insurance";
import RealEstate from "./Components/Products/RealEstate";
import Pms from "./Components/Products/Pms";
import Equity from "./Components/Products/Equity";
import MutualFund from "./Components/Products/MutualFund";
import StartupEco from "./Components/Products/StartupEco";
import Training from "./Components/Products/Training";
import BookACallMain from "./Components/BookACall/bookacallmain";
import { ReactQueryDevtools } from "react-query/devtools";
import SideNavbar from "./Components/SideNavbar";
import SelfProfile from "./Components/SelfProfile";
import DistDashBoard from "./Components/DistDashBoard";
import OrderBook from "./Components/OrderBook";
import Appointments from "./Components/Appointments";
import Clients from "./Components/Clients";
import UnlistedDetails from "./Components/UnlistedDetails";
import AdminUnlisted from "./Components/Products/AdminUnlisted";
import AdminUnlistedMasterDetails from "./Components/Products/AdminUnlistedMasterDetails";
import ManufacturerUnlisted from "./Components/Products/ManufacturerUnlisted";
import ActiveUnlisted from "./Components/Products/ActiveUnlisted";
import DistributorUnlisted from "./Components/DistributorUnlisted";
import UnlistedFixedRate from "./Components/Products/UnlistedFixedRate";
import FixedRateDetail from "./Components/FixedRateDetail";
import BrokerMaster from "./Components/BrokerMaster";
import BrokerDetails from "./Components/BrokerDetails";
import BondMaster from "./Components/Products/BondMaster";
import ClientById from "./Components/ClientById";
import Test from "./Components/Test";
import BondDetails from "./Components/BondDetails";
import BondTransaction from "./Components/BondTransaction";
import MenufactureOrderDetails from "./Components/Products/MenufactureOrderDetails";
import ProductType from "./Components/ProductType";
import ProductTypeByID from "./Components/ProductTypeByID";
import PayoutDashboard from "./Components/PayoutDashboard/PayoutDashboard";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import UploadTermSheet from "./Components/Termsheet/UploadTermSheet/UploadTermSheet";
import Test2 from "./Components/Test2";
import TermSheetGeneration from "./Components/Termsheet/TermSheetGeneration";
import Blog1 from "./Components/Blog1";
import Blog2 from "./Components/Blog2";
import Blog3 from "./Components/Blog3";
import Blog4 from "./Components/Blog4";
import Disclaimer from "./Components/Disclaimer";
import WhyUs from "./Components/WhyUs";
import RequestForQuote from "./Components/RequestForQuote";
import UnderProgress from "./Components/UnderProgress";
import AddBond from "./Components/Products/AddBond";
import UpdateCashflow from "./Components/UpdateCashflow";
import AIFScreener from "./Components/Products/AIFScreener";
import Layout from "./utils/Layout";
import AdminQuote from "./Components/QuoteBook/AdminQuote";
import QuoteDetailsById from "./Components/QuoteBook/QuoteDetailsById";
import Unlisted from "./Components/Products/Unlisted";
import UpdateBond from "./Components/Products/UpdateBond";
import BackOffPayoutDash from "./Components/BOPayoutDashBoard/BackOffPayoutDash";
import Cart from "./Components/Cart/Cart";
import ManageAmc from "./Components/ManageAmc/ManageAmc";
import ManageAmcById from "./Components/ManageAmc/ManageAmcById";
import PMSScreener from "./Components/Products/PMSScreener";
import EmployeeDashBoard from "./Components/EmployeeDashBoard/EmployeeDashBoard";
import MFScreener from "./Components/Products/MutualFund/MFScreener";
import UploadReport from "./Components/ReportUpload/UploadReport";
import CompareFundPage from "./Components/Products/AIF/CompareFundPage";
import ComparePMSFundPage from "./Components/Products/ComparePMSFundPage";
import CompareMFFundPage from "./Components/Products/CompareMFFundPage";
import ListOfBookACall from "./Components/BookACall/ListOfBookACall";
import BookACallGetByID from "./Components/BookACall/BookACallGetByID";
import NcdScreener from "./Components/Products/NCD/NcdScreener";
import PortfolioAnalyzer from "./Components/PortfolioAnalyzer/PortfolioAnalyzer";
import PortfolioAnalysisDetails from "./Components/PortfolioAnalyzer/PortfolioAnalysisDetails";

import { Worker } from "@react-pdf-viewer/core";
import PreOrder from "./Components/PreOrder/PreOrder";
import NewOrderWorkFlow from "./Components/newOrderWorkFlow";
import PartnerManagement from "./Components/PartnerManagement";
import ProductFixedIncome from "./Components/InvestmentProducts/ProductFixedIncome";
import ProductMutualFund from "./Components/InvestmentProducts/ProductMutualFund";
import ProductPMS from "./Components/InvestmentProducts/ProductPMS";
import ProductAIF from "./Components/InvestmentProducts/ProductAIF";
import PrivateEquity from "./Components/InvestmentProducts/PrivateEquity";
import ProductInsurance from "./Components/InvestmentProducts/ProductInsurance";
import ProductRealEstate from "./Components/InvestmentProducts/ProductRealEstate";
import StartupEcosystem from "./Components/InvestmentProducts/StartupEcosystem";
import ProductTraining from "./Components/InvestmentProducts/ProductTraining";
import ProductEquity from "./Components/InvestmentProducts/ProductEquity";
import AmcLoan from "./Components/ManageAmc/AmcLoan";
import AmcInsurance from "./Components/ManageAmc/AmcInsurance";
import AmcLoanScheme from "./Components/ManageAmc/AmcLoanScheme";
import AmcInsuranceScheme from "./Components/ManageAmc/AmcInsuranceScheme";
import BusinessUpload from "./Components/BusinessUpload";

export const contextVersion = "v1";

Axios.defaults.baseURL = process.env.REACT_APP_BASE_URL + contextVersion;
Axios.defaults.headers.common = {
  Authorization: sessionStorage.getItem("JWT"),
  Accept: "*/*",
};

function App() {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div className="App">
            <ThemeProvider theme={appTheme}>
              <ToastContainer />
              <Router>
                {/* render APP function defined below */}
                <RenderAppContent />
              </Router>
            </ThemeProvider>
          </div>
          <ReactQueryDevtools />
        </LocalizationProvider>
      </Worker>
    </QueryClientProvider>
  );
}

function RenderAppContent() {
  const pathLocation = useLocation();
  return (
    <Box sx={{ display: "flex" }}>
      {sessionStorage.getItem("JWT") &&
        pathLocation.pathname !== "/ChangePassword" &&
        pathLocation.pathname !== "/" &&
        pathLocation.pathname !== "/whyus" && <SideNavbar />}
      <Box sx={{ position: "absolute", width: "100%" }}>
        <Box
          component="main"
          // sx={{ marginLeft:sessionStorage.getItem("JWT") ? '5%' : '' }}
        >
          <Navbar />
          <Layout>
            <Routes>
              {/* <Route path='/' element={<Home />} /> */}
              <Route
                path="/UserDashBoard"
                element={
                  sessionStorage.getItem("ForcePassword") === "true" ? (
                    <AdminDashboard />
                  ) : (
                    <ChangePassword />
                  )
                }
              />
              <Route
                path="/BackOfficeDashBoard"
                element={
                  sessionStorage.getItem("ForcePassword") === "true" ? (
                    <EmployeeDashBoard />
                  ) : (
                    <ChangePassword />
                  )
                }
              />
              <Route
                path="/DistributorDashBoard"
                element={
                  sessionStorage.getItem("ForcePassword") === "true" ? (
                    <DistributorDashboard />
                  ) : (
                    <ChangePassword />
                  )
                }
              />
              <Route
                path="/EmployeeDashBoard"
                element={
                  sessionStorage.getItem("ForcePassword") === "true" ? (
                    <EmployeeDashBoard />
                  ) : (
                    <ChangePassword />
                  )
                }
              />
              <Route
                path="/A1/:userCode"
                element={
                  sessionStorage.getItem("ForcePassword") === "true" ? (
                    <UserProfileView />
                  ) : (
                    <ChangePassword />
                  )
                }
              />
              <Route
                path="/Company/:CompanyCode"
                element={
                  sessionStorage.getItem("ForcePassword") === "true" ? (
                    <CompanyProfileView />
                  ) : (
                    <ChangePassword />
                  )
                }
              />
              <Route
                path="/ManufactureDashBoard"
                element={
                  sessionStorage.getItem("ForcePassword") === "true" ? (
                    <ManufractureDashbord />
                  ) : (
                    <ChangePassword />
                  )
                }
              />
              <Route
                path="/BackOfficeDashBoard"
                element={
                  sessionStorage.getItem("ForcePassword") === "true" ? (
                    <AdminDashboard />
                  ) : (
                    <ChangePassword />
                  )
                }
              />
              <Route
                path="/manageAmc"
                element={
                  sessionStorage.getItem("ForcePassword") === "true" ? (
                    <ManageAmc />
                  ) : (
                    <ChangePassword />
                  )
                }
              />
              <Route
                path="/manageAmc/:amcId"
                element={
                  sessionStorage.getItem("ForcePassword") === "true" ? (
                    <ManageAmcById />
                  ) : (
                    <ChangePassword />
                  )
                }
              />
              <Route
                path="/AmcLoan/:amcId"
                element={
                  sessionStorage.getItem("ForcePassword") === "true" ? (
                    <AmcLoan />
                  ) : (
                    <ChangePassword />
                  )
                }
              />
              <Route
                path="/AmcInsurance/:amcId"
                element={
                  sessionStorage.getItem("ForcePassword") === "true" ? (
                    <AmcInsurance />
                  ) : (
                    <ChangePassword />
                  )
                }
              />
              <Route
                path="/AmcLoanScheme/:productMasterCode"
                element={
                  sessionStorage.getItem("ForcePassword") === "true" ? (
                    <AmcLoanScheme />
                  ) : (
                    <ChangePassword />
                  )
                }
              />
              <Route
                path="/AmcInsuranceScheme/:ProductMasterCode"
                element={
                  sessionStorage.getItem("ForcePassword") === "true" ? (
                    <AmcInsuranceScheme />
                  ) : (
                    <ChangePassword />
                  )
                }
              />

              <Route
                path="/DistDashBoard"
                element={
                  sessionStorage.getItem("ForcePassword") === "true" ? (
                    <UnderProgress />
                  ) : (
                    <ChangePassword />
                  )
                }
              />
              <Route path="/ChangePassword" element={<ChangePassword />} />
              <Route
                path="/CompanyManagement"
                element={
                  sessionStorage.getItem("ForcePassword") === "true" ? (
                    <CompanyManagement />
                  ) : (
                    <ChangePassword />
                  )
                }
              />
              <Route
                path="/PartnerManagement"
                element={
                  sessionStorage.getItem("ForcePassword") === "true" ? (
                    <PartnerManagement />
                  ) : (
                    <ChangePassword />
                  )
                }
              />
              <Route
                path="/products"
                element={
                  sessionStorage.getItem("ForcePassword") === "true" ? (
                    <Products />
                  ) : (
                    <ChangePassword />
                  )
                }
              />
              <Route
                path="/productScrener/:productId"
                element={
                  sessionStorage.getItem("ForcePassword") === "true" ? (
                    <ProductScrener />
                  ) : (
                    <ChangePassword />
                  )
                }
              />
              <Route
                path="/aif"
                element={
                  sessionStorage.getItem("ForcePassword") === "true" ? (
                    <Aif />
                  ) : (
                    <ChangePassword />
                  )
                }
              />
              <Route
                path="/fixedIncome"
                element={
                  sessionStorage.getItem("ForcePassword") === "true" ? (
                    <FixedIncome />
                  ) : (
                    <ChangePassword />
                  )
                }
              />
              <Route
                path="/primaryIssue"
                element={
                  sessionStorage.getItem("ForcePassword") === "true" ? (
                    <PrimaryIssue />
                  ) : (
                    <ChangePassword />
                  )
                }
              />
              <Route
                path="/insurance"
                element={
                  sessionStorage.getItem("ForcePassword") === "true" ? (
                    <Insurance />
                  ) : (
                    <ChangePassword />
                  )
                }
              />
              <Route
                path="/realEstate"
                element={
                  sessionStorage.getItem("ForcePassword") === "true" ? (
                    <RealEstate />
                  ) : (
                    <ChangePassword />
                  )
                }
              />
              <Route
                path="/pms"
                element={
                  sessionStorage.getItem("ForcePassword") === "true" ? (
                    <Pms />
                  ) : (
                    <ChangePassword />
                  )
                }
              />
              <Route
                path="/equity"
                element={
                  sessionStorage.getItem("ForcePassword") === "true" ? (
                    <Equity />
                  ) : (
                    <ChangePassword />
                  )
                }
              />
              <Route
                path="/mutualFund"
                element={
                  sessionStorage.getItem("ForcePassword") === "true" ? (
                    <MutualFund />
                  ) : (
                    <ChangePassword />
                  )
                }
              />
              <Route
                path="/startupeco"
                element={
                  sessionStorage.getItem("ForcePassword") === "true" ? (
                    <StartupEco />
                  ) : (
                    <ChangePassword />
                  )
                }
              />
              <Route
                path="/training"
                element={
                  sessionStorage.getItem("ForcePassword") === "true" ? (
                    <Training />
                  ) : (
                    <ChangePassword />
                  )
                }
              />
              <Route
                path="/selfProfile"
                element={
                  sessionStorage.getItem("ForcePassword") === "true" ? (
                    <SelfProfile />
                  ) : (
                    <ChangePassword />
                  )
                }
              />
              <Route
                path="/distDashboard"
                element={
                  sessionStorage.getItem("ForcePassword") === "true" ? (
                    <DistDashBoard />
                  ) : (
                    <ChangePassword />
                  )
                }
              />
              <Route
                path="/orderbook"
                element={
                  sessionStorage.getItem("ForcePassword") === "true" ? (
                    <OrderBook />
                  ) : (
                    <ChangePassword />
                  )
                }
              />
              <Route
                path="/appointments"
                element={
                  sessionStorage.getItem("ForcePassword") === "true" ? (
                    <Appointments />
                  ) : (
                    <ChangePassword />
                  )
                }
              />
              <Route
                path="/bondTransaction/:isin"
                element={
                  sessionStorage.getItem("ForcePassword") === "true" ? (
                    <BondTransaction />
                  ) : (
                    <ChangePassword />
                  )
                }
              />
              <Route
                path="/test2"
                element={
                  sessionStorage.getItem("ForcePassword") === "true" ? (
                    <Test2 />
                  ) : (
                    <ChangePassword />
                  )
                }
              />
              <Route
                path="/disclaimer"
                element={
                  sessionStorage.getItem("ForcePassword") === "true" ? (
                    <Disclaimer />
                  ) : (
                    <ChangePassword />
                  )
                }
              />
              {/* <Route
                path="/whyus"
                element={
                  sessionStorage.getItem("ForcePassword") === "true" ? (
                    <WhyUs />
                  ) : (
                    <ChangePassword />
                  )
                }
              /> */}
              <Route
                path="/requestForQuote"
                element={
                  sessionStorage.getItem("ForcePassword") === "true" ? (
                    <RequestForQuote />
                  ) : (
                    <ChangePassword />
                  )
                }
              />
              <Route
                path="/addbond"
                element={
                  sessionStorage.getItem("ForcePassword") === "true" ? (
                    <AddBond />
                  ) : (
                    <ChangePassword />
                  )
                }
              />
              <Route
                path="/CompanyManagement"
                element={<CompanyManagement />}
              />
              <Route path="/products" element={<Products />} />
              <Route
                path="/productScrener/:productId"
                element={<ProductScrener />}
              />
              <Route
                path="/unlisted"
                element={
                  sessionStorage.getItem("ForcePassword") === "true" ? (
                    <Unlisted />
                  ) : (
                    <ChangePassword />
                  )
                }
              />
              <Route
                path="/bondUpdate/:isin"
                element={
                  sessionStorage.getItem("ForcePassword") === "true" ? (
                    <UpdateBond />
                  ) : (
                    <ChangePassword />
                  )
                }
              />
              <Route path="/aif" element={<Aif />} />
              <Route
                path="/aifScreener/:schemeCode"
                element={<AIFScreener />}
              />
              <Route
                path="/pmsScreener/:schemeCode"
                element={<PMSScreener />}
              />

              <Route path="/MfScreener/:schemeCode" element={<MFScreener />} />
              <Route path="/PreOrder" element={<PreOrder />} />

              <Route path="/fixedIncome" element={<FixedIncome />} />
              <Route path="/primaryIssue" element={<PrimaryIssue />} />
              <Route path="/insurance" element={<Insurance />} />
              <Route path="/realEstate" element={<RealEstate />} />
              <Route path="/pms" element={<Pms />} />
              <Route path="/equity" element={<Equity />} />
              <Route path="/mutualfund" element={<MutualFund />} />
              <Route path="/startupeco" element={<StartupEco />} />
              <Route path="/training" element={<Training />} />
              <Route path="/selfProfile" element={<SelfProfile />} />
              <Route path="/distDashboard" element={<DistDashBoard />} />
              <Route path="/orderbook" element={<OrderBook />} />
              <Route path="/appointments" element={<Appointments />} />
              <Route path="/bondTransaction" element={<BondTransaction />} />
              <Route path="/test2" element={<Test2 />} />
              <Route path="/disclaimer" element={<Disclaimer />} />
              {/* <Route path="/whyus" element={<WhyUs />} /> */}
              <Route
                path="/PortfolioAnalyzer"
                element={<PortfolioAnalyzer />}
              />
              <Route
                path="/PortfolioAnalysisDetails"
                element={<PortfolioAnalysisDetails />}
              />
              <Route path="/BusinessUpload" element={<BusinessUpload />} />

              <Route path="/fixedIncome" element={<FixedIncome />} />
              <Route path="/primaryIssue" element={<PrimaryIssue />} />
              <Route path="/insurance" element={<Insurance />} />
              <Route path="/realEstate" element={<RealEstate />} />
              <Route path="/pms" element={<Pms />} />
              <Route path="/equity" element={<Equity />} />
              <Route path="/mutualfund" element={<MutualFund />} />
              <Route path="/startupeco" element={<StartupEco />} />
              <Route path="/training" element={<Training />} />
              <Route path="/selfProfile" element={<SelfProfile />} />
              <Route path="/distDashboard" element={<DistDashBoard />} />
              <Route path="/orderbook" element={<OrderBook />} />
              <Route path="/appointments" element={<Appointments />} />
              <Route path="/bondTransaction" element={<BondTransaction />} />
              <Route path="/test2" element={<Test2 />} />
              <Route path="/disclaimer" element={<Disclaimer />} />
              {/* <Route path="/whyus" element={<WhyUs />} /> */}

              {/* Client  */}
              <Route
                path="/clients"
                element={
                  sessionStorage.getItem("ForcePassword") === "true" ? (
                    <Clients />
                  ) : (
                    <ChangePassword />
                  )
                }
              />
              <Route
                path="/client/:clientId"
                element={
                  sessionStorage.getItem("ForcePassword") === "true" ? (
                    <ClientById />
                  ) : (
                    <ChangePassword />
                  )
                }
              />

              <Route
                path="/unlistedDetails"
                element={
                  sessionStorage.getItem("ForcePassword") === "true" ? (
                    <UnlistedDetails />
                  ) : (
                    <ChangePassword />
                  )
                }
              />
              <Route
                path="/unlistedDetails/:productMasterCode"
                element={
                  sessionStorage.getItem("ForcePassword") === "true" ? (
                    <UnlistedDetails />
                  ) : (
                    <ChangePassword />
                  )
                }
              />
              <Route
                path="/unlistedMasterDetails/:unlistedMasterCode"
                element={
                  sessionStorage.getItem("ForcePassword") === "true" ? (
                    <AdminUnlistedMasterDetails />
                  ) : (
                    <ChangePassword />
                  )
                }
              />
              <Route
                path="/NcdDetails/:productMasterCode"
                element={
                  sessionStorage.getItem("ForcePassword") === "true" ? (
                    <NcdScreener />
                  ) : (
                    <ChangePassword />
                  )
                }
              />

              <Route
                path="orderbook/:newOrderBookId/:productTypeCode"
                element={
                  sessionStorage.getItem("ForcePassword") === "true" ? (
                    <NewOrderWorkFlow />
                  ) : (
                    <ChangePassword />
                  )
                }
              />

              <Route
                path="/MasterUnlisted"
                element={
                  sessionStorage.getItem("ForcePassword") === "true" ? (
                    <AdminUnlisted />
                  ) : (
                    <ChangePassword />
                  )
                }
              />
              <Route
                path="/getAllBookACall"
                element={
                  sessionStorage.getItem("ForcePassword") === "true" ? (
                    <ListOfBookACall />
                  ) : (
                    <ChangePassword />
                  )
                }
              />
              <Route
                path="/BookACallByID/:ID"
                element={
                  sessionStorage.getItem("ForcePassword") === "true" ? (
                    <BookACallGetByID />
                  ) : (
                    <ChangePassword />
                  )
                }
              />
              <Route
                path="/ManufacturerUnlisted"
                element={
                  sessionStorage.getItem("ForcePassword") === "true" ? (
                    <ManufacturerUnlisted />
                  ) : (
                    <ChangePassword />
                  )
                }
              />
              <Route
                path="/ActiveUnlisted"
                element={
                  sessionStorage.getItem("ForcePassword") === "true" ? (
                    <ActiveUnlisted />
                  ) : (
                    <ChangePassword />
                  )
                }
              />
              <Route
                path="/DistUnlistedDetails/:unlistedMasterCode"
                element={
                  sessionStorage.getItem("ForcePassword") === "true" ? (
                    <DistributorUnlisted />
                  ) : (
                    <ChangePassword />
                  )
                }
              />
              <Route
                path="/FixedRateUnlisted"
                element={
                  sessionStorage.getItem("ForcePassword") === "true" ? (
                    <UnlistedFixedRate />
                  ) : (
                    <ChangePassword />
                  )
                }
              />
              <Route
                path="/fixedRateDetail/:unlistedBackOfficeCode"
                element={
                  sessionStorage.getItem("ForcePassword") === "true" ? (
                    <FixedRateDetail />
                  ) : (
                    <ChangePassword />
                  )
                }
              />
              <Route
                path="/brokerMaster"
                element={
                  sessionStorage.getItem("ForcePassword") === "true" ? (
                    <BrokerMaster />
                  ) : (
                    <ChangePassword />
                  )
                }
              />
              <Route
                path="/brokerDetails/:brokerMasterCode"
                element={
                  sessionStorage.getItem("ForcePassword") === "true" ? (
                    <BrokerDetails />
                  ) : (
                    <ChangePassword />
                  )
                }
              />
              <Route
                path="/bondMaster"
                element={
                  sessionStorage.getItem("ForcePassword") === "true" ? (
                    <BondMaster />
                  ) : (
                    <ChangePassword />
                  )
                }
              />
              <Route path="/test" element={<Test />} />
              <Route
                path="/updateCashflow/:isin"
                element={<UpdateCashflow />}
              />
              <Route
                path="/bondDetails/:isin"
                element={
                  sessionStorage.getItem("ForcePassword") === "true" ? (
                    <BondDetails />
                  ) : (
                    <ChangePassword />
                  )
                }
              />
              <Route
                path="/MenufactureOrderUnlisted/:productMasterCode"
                element={
                  sessionStorage.getItem("ForcePassword") === "true" ? (
                    <MenufactureOrderDetails />
                  ) : (
                    <ChangePassword />
                  )
                }
              />

              {/* Blogs */}
              {/* <Route path="/blog1" element={<Blog1 />} />
                          <Route path="/blog2" element={<Blog2 />} />
                          <Route path="/blog3" element={<Blog3 />} />
                          <Route path="/blog4" element={<Blog4 />} /> */}

              {/* Product Type  */}
              <Route
                path="/productType"
                element={
                  sessionStorage.getItem("ForcePassword") === "true" ? (
                    <ProductType />
                  ) : (
                    <ChangePassword />
                  )
                }
              />
              <Route
                path="/productType/:productTypeCode"
                element={
                  sessionStorage.getItem("ForcePassword") === "true" ? (
                    <ProductTypeByID />
                  ) : (
                    <ChangePassword />
                  )
                }
              />

              {/* Payout Dashboard */}
              <Route
                path="/payoutDashboard"
                element={
                  sessionStorage.getItem("ForcePassword") === "true" ? (
                    <PayoutDashboard />
                  ) : (
                    <ChangePassword />
                  )
                }
              />
              <Route
                path="/revenueCreditDashboard"
                element={
                  sessionStorage.getItem("ForcePassword") === "true" ? (
                    <PayoutDashboard />
                  ) : (
                    <ChangePassword />
                  )
                }
              />
              <Route
                path="/backOffpayoutDashboard"
                element={
                  sessionStorage.getItem("ForcePassword") === "true" ? (
                    <BackOffPayoutDash />
                  ) : (
                    <ChangePassword />
                  )
                }
              />

              {/* TermSheet Generation */}
              <Route
                path="/termsheet"
                element={
                  sessionStorage.getItem("ForcePassword") === "true" ? (
                    <TermSheetGeneration />
                  ) : (
                    <ChangePassword />
                  )
                }
              />

              {/* Upload Term Sheet */}
              <Route
                path="/uploadtermsheet"
                element={
                  sessionStorage.getItem("ForcePassword") === "true" ? (
                    <UploadTermSheet />
                  ) : (
                    <ChangePassword />
                  )
                }
              />

              {/* Admin Quote Screen */}
              <Route
                path="/add-quote"
                element={
                  sessionStorage.getItem("ForcePassword") === "true" ? (
                    <AdminQuote />
                  ) : (
                    <ChangePassword />
                  )
                }
              />
              <Route
                path="/add-quote/:quoteId"
                element={
                  sessionStorage.getItem("ForcePassword") === "true" ? (
                    <QuoteDetailsById />
                  ) : (
                    <ChangePassword />
                  )
                }
              />

              {/* Cart */}
              <Route
                path="/cart"
                element={
                  sessionStorage.getItem("ForcePassword") === "true" ? (
                    <Cart />
                  ) : (
                    <ChangePassword />
                  )
                }
              />
              {/*  */}
              <Route
                path="/reportUpload"
                element={
                  sessionStorage.getItem("ForcePassword") === "true" ? (
                    <UploadReport />
                  ) : (
                    <ChangePassword />
                  )
                }
              />
              <Route
                path="/CompareFund"
                element={
                  sessionStorage.getItem("ForcePassword") === "true" ? (
                    <CompareFundPage />
                  ) : (
                    <ChangePassword />
                  )
                }
              />
              <Route
                path="/ComparePMSFund"
                element={
                  sessionStorage.getItem("ForcePassword") === "true" ? (
                    <ComparePMSFundPage />
                  ) : (
                    <ChangePassword />
                  )
                }
              />
              <Route
                path="/CompareMFFund"
                element={
                  sessionStorage.getItem("ForcePassword") === "true" ? (
                    <CompareMFFundPage />
                  ) : (
                    <ChangePassword />
                  )
                }
              />
            </Routes>
          </Layout>
          <Routes>
            <Route path="/bookACallMain" element={<BookACallMain />} />
            <Route path="/" element={<Home />} />
            <Route
              path="/ProductFixedIncome"
              element={<ProductFixedIncome />}
            />
            <Route path="/ProductMutualFund" element={<ProductMutualFund />} />
            <Route path="/ProductPMS" element={<ProductPMS />} />
            <Route path="/ProductAIF" element={<ProductAIF />} />
            <Route path="/PrivateEquity" element={<PrivateEquity />} />
            <Route path="/ProductInsurance" element={<ProductInsurance />} />
            <Route path="/ProductRealEstate" element={<ProductRealEstate />} />
            <Route path="/StartupEcosystem" element={<StartupEcosystem />} />
            <Route path="/ProductTraining" element={<ProductTraining />} />
            <Route path="/ProductEquity" element={<ProductEquity />} />
            {/* Blogs */}
            <Route path="/blog1" element={<Blog1 />} />
            <Route path="/blog2" element={<Blog2 />} />
            <Route path="/blog3" element={<Blog3 />} />
            <Route path="/blog4" element={<Blog4 />} />

            <Route path="/whyus" element={<WhyUs />} />
          </Routes>
        </Box>
      </Box>
    </Box>
  );
}

export default App;
