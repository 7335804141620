/* eslint-disable no-unreachable */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import { useQuery } from "react-query";
import { GetAllClient } from "../api/Api";
import axios from "axios";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { changeDate } from "../utils/Helper";
import { SAVE_PROPOSAL } from "../utils/API_Names";
import { productTypeEnum } from "../Constants/ProductTypeEnum";
import ClientModalForOrder from "./ClientModalForOrder";
import { useLocation } from "react-router-dom";

const TableHeadData = (props: any) => {
  const handleViewPreOrders = () => {
    window.location.href = "/preOrder";
  };

  const item: any = props.item;
  const distributorCode = props.distributorCode;
  const distributorUserCode = props.distributorUserCode;
  const companyMasterCode = props.companyMasterCode;
  const JV = props.JV;
  const data2 = props?.data2;
  const clientId = props?.clientId;
  const setClientId = props?.setClientId;
  const clientDetails = props?.clientDetails;
  const setClientDetails = props?.setClientDetails;
  const UserProfilesByCompanyMasterCode =
    props?.UserProfilesByCompanyMasterCode;
  const setSelectedUser = props?.setselectedUser;
  // const selectedClient = props?.selectedClient;

  // const [clientId, setClientId] = useState("");
  // const [clientDetails, setClientDetails] = useState<any>("");
  const [bitAmount, setBitAmount] = useState(0);
  const [BuyRate, setBuyRate] = useState(item.manufactureSaleRate);
  const [SellRate, setSellRate] = useState(item.clientSaleRate);
  const [orderDate, seOrderDate] = useState(new Date());
  const [manufactureCostPrice, setManufactureCostPrice] = useState(
    item.manufacturerBuyRate
  );
  const [rmRevenue, setRmRevenue] = useState("");
  const [distPayout, setDistPayout] = useState("");
  // const [manufactureCarryingCostPrice, setManufactureCarryingCostPrice] =
  //   useState(item.manufacturerCarryingCost);
  // fetch the client id from the url
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const clientIdFromParams = queryParams.get("clientId");

  // const data2 = useQuery(
  //   ["getAllClients", distributorCode],
  //   () => GetAllClient(companyMasterCode, distributorCode),
  //   {
  //     refetchOnWindowFocus: false,
  //   }
  // );

  const unlistedOrder = () => {
    if (
      (!manufactureCostPrice || manufactureCostPrice === 0) &&
      (!BuyRate || BuyRate === 0)
    ) {
      toast.error("Please enter Manufacture Cost Price or Buy Rate");
      return;
    }
    if (!manufactureCostPrice || manufactureCostPrice === 0) {
      toast.error("Please enter manufacture cost price");
      return;
    }
    if (!BuyRate || BuyRate === 0) {
      toast.error("Please enter Buy Rate");
      return;
    }
    if (!SellRate || SellRate === 0) {
      toast.error("Please enter Sell Rate");
      return;
    }
    let unlistedOrderData = {
      productMasterCode: item.productMasterCode,
      clientId: clientId || clientIdFromParams,
      version: 1,
      productTypeCode: item.productTypeCode,
      quantity: Number(bitAmount),
      tcs: 0,
      //   clientId === ""
      //     ? bitAmount * BuyRate * (item.tcsValue / 100)
      //     : bitAmount * SellRate * (item.tcsValue / 100),
      totalValue: clientId === "" ? bitAmount * BuyRate : bitAmount * SellRate,
      userRate: clientId === "" ? Number(BuyRate) : Number(SellRate),
      distCompanyMasterCode: !!sessionStorage.getItem("CompanyData")
        ? sessionStorage.getItem("CompanyData")
        : companyMasterCode,
      distEmployeeCode: distributorCode,
      orderDate: changeDate(orderDate),
      jv: JV,
      manufacturerBuyRate:
        Number(manufactureCostPrice) || Number(item.manufacturerBuyRate),
      // manufacturerCarryingCost:
      //   Number(manufactureCarryingCostPrice) ||
      //   Number(item.manufacturerCarryingCost),
      buyRate: Number(BuyRate) || Number(item.manufactureSaleRate),
      // set rmRevenue and distributorPayout
      rmRevenueCredit: Number(rmRevenue) || Number(RMCommissionSharing()),
      distributorPayout: Number(distPayout) || Number(DistributorPayout()),
    };
    axios
      .post("product/createNewOrder", unlistedOrderData)
      .then((res: any) => {
        if (res) {
          toast.success(res?.data?.status?.message?.title);
          setTimeout(() => {
            window.location.reload();
          }, 5000);
        }
      })
      .catch((err: any) => {
        toast.error(
          err?.response?.data?.status?.message?.title
            ? err?.response?.data?.status?.message?.title
            : err?.response?.data?.title
        );
      });
  };

  const addToProposal = () => {
    let proposalDTO = {
      clientId: clientId,
      productMasterCode: item.productMasterCode,
      productTypeCode: productTypeEnum.UNLISTED,
      clientPrice: SellRate,
      disCompanyMasterCode: !!companyMasterCode ? companyMasterCode : "",
      disUserCode: !!distributorUserCode ? distributorUserCode : "",
    };
    axios
      .post(SAVE_PROPOSAL, proposalDTO)
      .then(
        (res: any) => res && toast.success(res?.data?.status?.message?.title)
      )
      .catch((err: any) => {
        toast.error(
          err?.response?.data?.status?.message?.title
            ? err?.response?.data?.status?.message?.title
            : err?.response?.data?.title
        );
      });
  };

  const resolver: any = async (values: any) => {
    return {
      values: !values.distributorBuyRate ? {} : console.log("values", values),
      errors:
        clientId === "" && !values.distributorBuyRate
          ? {
              distributorBuyRate: {
                type: "required",
                message: "This is required.",
              },
            }
          : parseFloat(values.distributorBuyRate) <= 0
          ? {
              distributorBuyRate: {
                type: "required",
                message: "Price Should Be More Than 0 ",
              },
            }
          : parseFloat(values.manufactureSaleRate) <= 0
          ? {
              manufactureSaleRate: {
                type: "required",
                message: "Price Should Be More Than 0 ",
              },
            }
          : parseFloat(values.clientSaleRate) <= 0
          ? {
              clientSaleRate: {
                type: "required",
                message: "Price Should Be More Than 0 ",
              },
            }
          : //  : (item.manufactureSaleRate-(item.minValue/100) > values.distributorBuyRate && values.distributorBuyRate > (item.manufactureSaleRate+(item.maxValue/100))) ? {
          //: clientId === "" &&
          //   !(
          //     parseFloat(values.distributorBuyRate) >
          //       item.manufactureSaleRate -
          //         (item.manufactureSaleRate * item.minValue) / 100 &&
          //     parseFloat(values.distributorBuyRate) <
          //       item.manufactureSaleRate +
          //         (item.manufactureSaleRate * item.minValue) / 100
          //   )
          // ? {
          //     distributorBuyRate: {
          //       type: "required",
          //       message:
          //         "Price Range Between " +
          //         (
          //           item.manufactureSaleRate -
          //           (item.manufactureSaleRate * item.minValue) / 100
          //         ).toFixed(2) +
          //         " - " +
          //         (
          //           item.manufactureSaleRate +
          //           (item.manufactureSaleRate * item.minValue) / 100
          //         ).toFixed(2),
          //     },
          //   }
          // : clientId !== "" && !values.clientSaleRate
          // ? {
          //     clientSaleRate: {
          //       type: "required",
          //       message: "This is required.",
          //     },
          //   }
          // : clientId !== "" &&
          //   !(
          //     parseFloat(values.clientSaleRate) >
          //       item.clientSaleRate -
          //         (item.clientSaleRate * item.minValue) / 100 &&
          //     parseFloat(values.clientSaleRate) <
          //       item.clientSaleRate +
          //         (item.clientSaleRate * item.minValue) / 100
          //   )
          // ? {
          //     clientSaleRate: {
          //       type: "required",
          //       message:
          //         "Price Range Between " +
          //         (
          //           item.clientSaleRate -
          //           (item.clientSaleRate * item.minValue) / 100
          //         ).toFixed(2) +
          //         " - " +
          //         (
          //           item.clientSaleRate +
          //           (item.clientSaleRate * item.minValue) / 100
          //         ).toFixed(2),
          //     },
          //   }
          !values.quantity
          ? {
              quantity: {
                type: "required",
                message: "This is required.",
              },
            }
          : isNaN(parseInt(values.quantity)) ||
            parseFloat(values.quantity) % 1 !== 0
          ? {
              quantity: {
                type: "required",
                message: "Quantity should be a whole number.",
              },
            }
          : parseInt(values.quantity) <= 0
          ? {
              quantity: {
                type: "required",
                message: "Quantity Should be more then 0",
              },
            }
          : !values.orderDate
          ? {
              quantity: {
                type: "required",
                message: "This is required.",
              },
            }
          : !values.clients
          ? {
              clients: {
                type: "required",
                message: "Select a Client",
              },
            }
          : {},
    };
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: resolver,
  });

  useEffect(() => {
    setManufactureCostPrice(item.manufacturerBuyRate);
    setBuyRate(item.manufactureSaleRate);
    setSellRate(item.clientSaleRate);
    // setManufactureCarryingCostPrice(item.manufacturerCarryingCost);
  }, [item]);

  useEffect(() => {
    setValue("manufacturerBuyRate", manufactureCostPrice);
    setValue("distributorBuyRate", BuyRate);
    setValue("clientSaleRate", SellRate);
    // setValue("manufacturerCarryingCost", manufactureCarryingCostPrice);
    // set the value of clients
    // setValue("clients", clientDetails);
    // setClientId(clientDetails?.clientId);
  }, [BuyRate, SellRate, manufactureCostPrice]);

  useEffect(() => {
    if (clientIdFromParams) {
      const preselectedClient = data2.data?.data?.clients?.find(
        (client: any) => client.clientId === Number(clientIdFromParams)
      );
      if (preselectedClient) {
        setClientId(preselectedClient.clientId);
        setClientDetails(preselectedClient);
        setValue("clients", preselectedClient);
      }
    } else {
      setValue("clients", clientDetails);
    }
  }, [clientIdFromParams, data2.data?.data?.clients, setValue]);

  const onSubmit = (data: any, item: any) => {
    console.log("data--", data);
    console.log("item--", item);
  };

  // RM Commission Sharing would be the selling price - buying price * rm commission sharing percentage and then multiply by quantity
  const RMCommissionSharing = () => {
    const priceDiff =
      ((parseFloat(SellRate) - parseFloat(BuyRate)) / 100) *
      parseFloat(props?.brokeragePayout?.rmCommSharing);
    const result = priceDiff * bitAmount;
    // return isNaN(result) ? "0.00" : result.toFixed(2);
    return isNaN(result) || result < 0 ? "0.00" : result.toFixed(2);
  };

  const DistributorPayout = () => {
    const priceDiff =
      ((parseFloat(SellRate) - parseFloat(BuyRate)) / 100) *
      parseFloat(props?.brokeragePayout?.distCommSharing);
    const result = priceDiff * bitAmount;
    // return isNaN(result) ? "0.00" : result.toFixed(2);
    return isNaN(result) || result < 0 ? "0.00" : result.toFixed(2);
  };

  return (
    <>
      <TableRow>
        <TableCell
          align="center"
          sx={{
            // width: 150,
            minWidth: 150,
          }}
        >
          <form onSubmit={handleSubmit(onSubmit)} id="form1">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDatePicker
                label="Order Date"
                inputFormat="DD/MM/YYYY"
                value={orderDate}
                onChange={(newValue: any) => {
                  seOrderDate(newValue.toJSON());
                }}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    {...params}
                    sx={{ width: "100%" }}
                    error={errors.orderDate ? true : false}
                    helperText={
                      errors.orderDate === undefined
                        ? ""
                        : errors.orderDate.message + ""
                    }
                    {...register("orderDate")}
                  />
                )}
              />
            </LocalizationProvider>
          </form>
        </TableCell>

        <TableCell
          align="center"
          sx={{
            // width: 150,
            minWidth: 120,
          }}
        >
          <form onSubmit={handleSubmit(onSubmit)} id="form1">
            <TextField
              id="outlined-basic"
              variant="outlined"
              label={
                sessionStorage.getItem("Profile") === "B"
                  ? "Manufacture Cost Price"
                  : "Buy Rate"
              }
              size="small"
              defaultValue={item.manufacturerBuyRate}
              value={manufactureCostPrice}
              disabled={
                sessionStorage.getItem("Profile") === "B" ? false : true
              }
              onChangeCapture={(e: any) =>
                setManufactureCostPrice(e.target.value)
              }
              {...register("manufacturerBuyRate")}
            />
          </form>
        </TableCell>

        {/* <TableCell
          align="center"
          sx={{
            minWidth: 120,
          }}
        >
          <form onSubmit={handleSubmit(onSubmit)} id="form1">
            <TextField
              id="outlined-basic"
              variant="outlined"
              label="Manufacture Carrying Cost"
              size="small"
              defaultValue={item.manufacturerCarryingCost}
              value={manufactureCarryingCostPrice}
              disabled={
                sessionStorage.getItem("Profile") === "B" ? false : true
              }
              onChangeCapture={(e: any) =>
                setManufactureCarryingCostPrice(e.target.value)
              }
            />
          </form>
        </TableCell> */}

        {sessionStorage.getItem("Profile") === "B" && (
          <TableCell
            align="center"
            sx={{
              // width: 150,
              minWidth: 100,
            }}
          >
            <form onSubmit={handleSubmit(onSubmit)} id="form1">
              <TextField
                id="outlined-basic"
                variant="outlined"
                label="Manufacturer Landing Price"
                size="small"
                defaultValue={item.manufactureSaleRate}
                value={BuyRate}
                error={errors.distributorBuyRate ? true : false}
                helperText={
                  errors.distributorBuyRate === undefined
                    ? ""
                    : errors.distributorBuyRate.message + ""
                }
                onChangeCapture={(e: any) => setBuyRate(e.target.value)}
                // disabled={clientId === "" ? false : true}
                InputProps={{ inputProps: { min: "0", max: "10", step: "1" } }}
                {...register("distributorBuyRate")}
                disabled={
                  sessionStorage.getItem("Profile") === "B" ? false : true
                }
              />
            </form>
          </TableCell>
        )}

        <TableCell
          align="center"
          sx={{
            // width: 150,
            minWidth: 100,
          }}
        >
          <form onSubmit={handleSubmit(onSubmit)} id="form1">
            <TextField
              id="outlined-basic"
              variant="outlined"
              label="Sell Price"
              size="small"
              defaultValue={SellRate}
              value={SellRate}
              {...register("clientSaleRate")}
              error={errors.clientSaleRate ? true : false}
              helperText={
                errors.clientSaleRate === undefined
                  ? ""
                  : errors.clientSaleRate.message + ""
              }
              onChangeCapture={(e: any) => setSellRate(e.target.value)}
              // disabled={clientId === "" ? true : false}
            />
          </form>
        </TableCell>
        {/* <TableCell align="center">
          {clientId === ""
            ? (bitAmount * BuyRate * (item.tcsValue / 100)).toFixed(2)
            : (bitAmount * SellRate * (item.tcsValue / 100)).toFixed(2)}{" "}
        </TableCell> */}
        {/* <TableCell align="center">
          {clientId === ""
            ? (bitAmount * BuyRate).toFixed(2)
            : (bitAmount * SellRate).toFixed(2)}
        </TableCell> */}
        <TableCell
          align="center"
          sx={{
            padding: 0,
          }}
        >
          {clientId === ""
            ? isNaN(bitAmount * BuyRate)
              ? "0.00"
              : (bitAmount * BuyRate).toFixed(2)
            : isNaN(bitAmount * SellRate)
            ? "0.00"
            : (bitAmount * SellRate).toFixed(2)}
        </TableCell>
        <TableCell
          align="center"
          sx={{
            // width: 150,
            minWidth: 90,
          }}
        >
          <form onSubmit={handleSubmit(onSubmit)} id="form1">
            <TextField
              id="outlined-basic"
              variant="outlined"
              type="number"
              size="small"
              onChangeCapture={(e: any) =>
                setBitAmount(parseInt(e.target.value))
              }
              // onClick={(e:any)=>setBitAmount(e.target.value)}
              label="Quantity"
              error={errors.quantity ? true : false}
              helperText={
                errors.quantity === undefined
                  ? ""
                  : errors.quantity.message + ""
              }
              {...register("quantity")}
            />
          </form>
        </TableCell>
        <TableCell
          align="center"
          sx={{
            padding: 0,
          }}
        >
          {data2.data && (
            <>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={data2.data.data.clients.map((options: any) => options)}
                getOptionLabel={(options: any) =>
                  options.fullName + " - " + options.panCardNo
                }
                onChange={(options: any, newValue) => {
                  setClientId(newValue ? newValue.clientId : "");
                  setClientDetails(newValue ? newValue : "");
                  newValue
                    ? setValue("clientSaleRate", SellRate)
                    : setValue("distributorBuyRate", BuyRate);
                  setValue("clients", newValue);
                  // set vals
                  if (newValue) {
                    const distributorEmployeeCode =
                      newValue.distributorEmployeeCode;
                    const matchedUser =
                      UserProfilesByCompanyMasterCode?.data?.userProfile?.find(
                        (user: any) =>
                          user.employeeCode === distributorEmployeeCode
                      );
                    setSelectedUser(matchedUser ? matchedUser : null);
                  } else {
                    setSelectedUser(null);
                  }
                }}
                renderInput={(params: any) => (
                  <form onSubmit={handleSubmit(onSubmit)} id="form1">
                    <TextField
                      {...params}
                      label="Select Clients"
                      size="small"
                      fullWidth
                      error={errors.clients ? true : false}
                      helperText={
                        !errors.clients ? "" : errors.clients.message + ""
                      }
                      {...register("clients")}
                    />
                  </form>
                )}
                // fetch pre filled client value from session storage and set it to the value of the autocomplete
                // value={
                //   clientDetails ||
                //   data2.data?.data?.clients?.find(
                //     (client: any) =>
                //       client.clientId === Number(clientIdFromParams)
                //   ) ||
                //   null
                // }
                value={clientDetails || null}
              />
            </>
          )}
        </TableCell>
        {sessionStorage.getItem("Profile") === "E" &&
        sessionStorage.getItem("CompanyTypeCode") === "S" ? null : (
          <>
            {/* RM REVENUE */}
            <TableCell
              align="center"
              sx={{
                // width: 150,
                minWidth: 150,
              }}
            >
              <form onSubmit={handleSubmit(onSubmit)} id="form1">
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  label="RM Revenue"
                  size="small"
                  defaultValue={item.manufactureSaleRate}
                  value={rmRevenue || RMCommissionSharing() || 0}
                  onChangeCapture={(e: any) => setRmRevenue(e.target.value)}
                  InputProps={{
                    inputProps: { min: "0", max: "10", step: "1" },
                  }}
                  disabled={
                    sessionStorage.getItem("Profile") === "B" ? false : true
                  }
                />
              </form>
            </TableCell>
            {/* DISTRIBUTOR PAYOUT */}
            <TableCell
              align="center"
              sx={{
                // width: 150,
                minWidth: 150,
              }}
            >
              <form onSubmit={handleSubmit(onSubmit)} id="form1">
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  label="Distributor Payout"
                  size="small"
                  defaultValue={item.manufactureSaleRate}
                  value={distPayout || DistributorPayout() || 0}
                  onChangeCapture={(e: any) => setDistPayout(e.target.value)}
                  InputProps={{
                    inputProps: { min: "0", max: "10", step: "1" },
                  }}
                  disabled={
                    sessionStorage.getItem("Profile") === "B" ? false : true
                  }
                />
              </form>
            </TableCell>
          </>
        )}
        <TableCell>
          {clientId && <ClientModalForOrder clientDetails={clientDetails} />}
        </TableCell>
        <TableCell align="center" sx={{ p: 0.5, width: 140 }}>
          <form onSubmit={handleSubmit(unlistedOrder)} id="form1">
            <Button
              size="small"
              variant="contained"
              type="submit"
              id="form1"
              // onClick={()=>unlistedOrder(item)}
            >
              Order
            </Button>
          </form>
          <Button
            size="small"
            variant="contained"
            onClick={() => handleViewPreOrders()}
            sx={{ mt: 0.5 }}
          >
            View Pre Orders
          </Button>
        </TableCell>
        {/* <TableCell align="center">
          <Button
            size="small"
            variant="contained"
            disabled={!!!clientId}
            onClick={() => addToProposal()}
          >
            Proposal
          </Button>
        </TableCell> */}
      </TableRow>
    </>
  );
};

export default TableHeadData;
