/* eslint-disable no-unreachable */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import {
  Box,
  Button,
  Link,
  FormControlLabel,
  Modal,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Grid,
  Container,
  Autocomplete,
  CircularProgress,
  TablePagination,
  IconButton,
  InputBase,
  MenuItem,
  Menu,
  Toolbar,
  Stack,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useForm } from "react-hook-form";
import axios from "axios";
import { toast } from "react-toastify";
import { isValidUsername } from "../utils/Helper";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { GetListOfUserByCompanyMasterCodeAndUserTypeCode } from "../api/Api";
import { useQuery } from "react-query";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: "10px",
  maxHeight: "80%",
  overflowY: "auto",
};

const ClientsAddModal = ({
  open,
  setOpen,
  handleClose,
  Clients,
  filteredClientsData,
  setFilteredClientsData,
  selectedClient,
  setSelectedClient,
  rmName,
  setRmName,
  rmEmployeeCode,
  setRmEmployeeCode,
  userType,
  setUserType,
  ListOfCompany,
  setFilteredDistributor,
  States,
  selectedCountry,
  setSelectedCountry,
  selectedState,
  setSelectedState,
  value,
  setValueDate,
  //   UserProfilesByCompanyMasterCode,
}) => {
  //   const [open, setOpen] = useState(false);
  const [showCircularProgress, setshowCircularProgress] = useState(false);
  const [nomineePancard, setNomineePancard] = useState(null);
  const [cancelCheque, setCancelCheque] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const [aadhaarCard, setAadhaarCard] = useState(null);
  const [CMLdocument, setCMLdocument] = useState(null);

  const handleSearchClientFromPAN = (e) => {
    const val = e.target.value;
    const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    if (val.length === 10 && panRegex.test(val)) {
      const filteredData = Clients?.filter(
        (client) => client?.panCardNo?.toLowerCase() === val.toLowerCase()
      );
      setFilteredClientsData(filteredData);
    }
    // search by client name
    else if (val.length > 0) {
      const filteredClientData = Clients?.filter((client) =>
        client?.fullName?.toLowerCase().includes(val.toLowerCase())
      );
      setFilteredClientsData(filteredClientData);
    } else {
      setFilteredClientsData([]);
    }
  };

  const registerOptions = {
    // companyCode : { required: "Select Company Name is required" },
    fullName: {
      required: "Full Name required",
      validate: (value) => {
        if (!isValidUsername(value)) {
          return "Only letters, numbers, special characters(not more than 3) are allowed!";
        }
        return true;
      },
    },
    companyName: { required: "Please select distributor Company" },
    distributorEmployeeCode: { required: "Please select Distributor" },
    pancard: { required: "Pan Card No is required" },
    clientcode: { required: "Client Code is required" },
    bankName: { required: "Bank Name is required" },
    accountNo: {
      required: "Account No is required",
      validate: (value) => {
        const accountNoRegex = /^[0-9]{8,30}$/;
        if (!accountNoRegex.test(value)) {
          return "Account No. should be between 8 to 30 digits (only numbers)";
        }
        return true;
      },
    },
    ifscCode: {
      required: "IFSC Code is required",
      validate: (value) => {
        const ifscCodeRegex = /^[A-Z]{4}0[A-Z0-9]{6}$/;
        if (!ifscCodeRegex.test(value)) {
          return "IFSC Code should be 11 characters long, first four characters should be uppercase alphabets, fifth character should be 0, last 6 characters should be numbers but can also have alphabets";
        }
        return true;
      },
    },
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    watch,
    clearErrors,
  } = useForm();

  const onSubmit = (data) => {
    setshowCircularProgress(true);
    const formData = new FormData();
    formData.append("panCardDocument", nomineePancard);
    formData.append("cancelCheque", cancelCheque);
    formData.append("profileImage", profileImage);
    formData.append("aadhaarCardDocument", aadhaarCard);
    formData.append("clientMasterList", CMLdocument);
    formData.append("clientDto", JSON.stringify(data));
    axios
      .post("client/saveClient", formData)
      .then(() => toast.success("Client Added Successfully"))
      .then(() => {
        setshowCircularProgress(false);
        setOpen(false);
        window.location.reload();
      })
      .catch((err) => {
        setshowCircularProgress(false);
        toast.error(
          err.response.data ? err.response.data.status.message.title : "Error"
        );
      });
  };

  const { data: UserProfilesByCompanyMasterCode } = useQuery(
    ["userProfileByCompany", watch("companyMasterCode"), userType],
    () =>
      GetListOfUserByCompanyMasterCodeAndUserTypeCode(
        userType === "S" ? "E" : userType,
        getValues("companyMasterCode")
      ),
    { enabled: !!getValues("companyMasterCode"), refetchOnWindowFocus: false }
  );

  const handleSelectClientDetails = (e, value) => {
    if (value) {
      setSelectedClient({
        fullName: value.fullName,
        mobileNo: value.mobileNo,
        emailId: value.emailId,
        panCardNo: value.panCardNo,
        aadhaarCardNo: value.aadhaarCardNo,
        addressLine1: value.addressLine1,
        addressLine2: value.addressLine2,
        addressLine3: value.addressLine3,
        city: value.city,
        state: value.state,
        country: value.country,
        pinCode: value.pinCode,
        clientCode: value.clientCode,
        bankName: value.bankName,
        branchName: value.branchName,
        accountNo: value.accountNo,
        ifscCode: value.ifscCode,
        dpName: value.dpName,
        dpId: value.dpId,
        rmName: value.rmName,
        rmBranch: value.rmBranch,
        rmBranchCode: value.rmBranchCode,
        rmEmpCode: value.rmEmpCode,
      });
      setRmName(value.rmName);
      setRmEmployeeCode(value.rmEmpCode);
      setUserType(value.companyTypeCode);
      // set the value of rmName and rmEmployeeCode once I get the companyMasterCode using ListOfCompany
      const filteredRMName = ListOfCompany?.data?.companies?.find(
        (company) => company.companyMasterCode === value.companyMasterCode
      );
      setRmName(filteredRMName?.rmName || "");
      setRmEmployeeCode(filteredRMName?.rmUserCode || "");
      // filter the distributor companyName from companyMasterCode
      const filteredDistributor = ListOfCompany?.data?.companies?.filter(
        (company) => company.companyMasterCode === value.companyMasterCode
      );
      setFilteredDistributor(filteredDistributor);
      // Pre-fill the companyName in the Autocomplete based on the filtered result
      // setValue("companyName", filteredDistributor[0]?.companyName || "");
      // setValue(
      //   "companyMasterCode",
      //   filteredDistributor[0]?.companyMasterCode || ""
      // );
      setValue("fullName", value.fullName);
      setValue("panCardNo", value.panCardNo);
      setValue("emailId", value.emailId);
      setValue("addressLine1", value.addressLine1);
      setValue("bankName", value.bankName);
      setValue("branchName", value.branchName);
      setValue("accountNo", value.accountNo);
      setValue("ifscCode", value.ifscCode);
      // set the value of clientCode
      setValue("clientCode", value.clientCode);
      const filteredResidentialStatus = [
        {
          label: "RESIDENT INDIVIDUAL",
          value: "RESIDENT_INDIVIDUAL",
        },
        {
          label: "NON RESIDENT INDIVIDUAL",
          value: "NON_RESIDENT_INDIVIDUAL",
        },
      ].find((option) => option.value === value.residentialStatus);
      setValue("residentialStatus", filteredResidentialStatus?.value || "");
      // +++++changes+++++++
      // Handling country and state selection based on client details
      const filteredCountry = States.countries.find(
        (country) => country.country === value.country
      );
      setSelectedCountry(filteredCountry);
      setValue("country", filteredCountry?.country || "");

      if (filteredCountry) {
        const filteredState = filteredCountry.states.find(
          (state) => state.toUpperCase() === value.state.toUpperCase()
        );
        setSelectedState(filteredState);
        setValue("state", filteredState || "");
      } else {
        setSelectedState(null); // If no valid state, clear the state
        setValue("state", "");
      }
      // clear the errors once the data gets filled like panCardNo, fullName, etc.
      clearErrors(["fullName", "panCardNo"]);
    } else {
      setSelectedClient({
        fullName: "",
        panCardNo: "",
        mobileNo: "",
        emailId: "",
        aadhaarCardNo: "",
        addressLine1: "",
        addressLine2: "",
        addressLine3: "",
        city: "",
        state: "",
        country: "",
        pinCode: "",
        clientCode: "",
        bankName: "",
        branchName: "",
        accountNo: "",
        ifscCode: "",
        dpName: "",
        dpId: "",
        rmName: "",
        rmBranch: "",
        rmBranchCode: "",
        rmEmpCode: "",
      });
      setSelectedCountry(null); // Reset the country and state
      setSelectedState(null);
      setValue("country", "");
      setValue("state", "");
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="modal-scroll">
          <IconButton
            style={{
              position: "absolute",
              fontSize: "18px",
              top: "8px",
              right: "5px",
            }}
            onClick={() => handleClose()}
          >
            <CloseIcon style={{ fontSize: "18px" }} />
          </IconButton>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Typography
              variant="h5"
              sx={{ marginBottom: 3, fontWeight: "500" }}
            >
              Client Details
            </Typography>
            <Box
              sx={{
                display: "flex",
                // flexDirection: "column",
                // justifyContent: "space-between",
              }}
            >
              {/* <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="PUBLIC"
                >
                  <Stack direction={"row"} spacing={3}>
                    <FormControlLabel
                      value="PRIVATE"
                      {...register("accessType")}
                      control={<Radio />}
                      label="Private"
                    />
                    <FormControlLabel
                      value="PUBLIC"
                      {...register("accessType")}
                      control={<Radio />}
                      label="Public"
                    />
                  </Stack>
                </RadioGroup> */}
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="INDIVIDUAL"
              >
                <Stack direction={"row"} spacing={3}>
                  <FormControlLabel
                    value="INDIVIDUAL"
                    {...register("clientType")}
                    control={<Radio />}
                    label="Individual"
                  />
                  <FormControlLabel
                    value="CORPORATE"
                    {...register("clientType")}
                    control={<Radio />}
                    label="Corporate"
                  />
                </Stack>
              </RadioGroup>
              <Grid
                container
                spacing={2}
                sx={{ marginBottom: 2, marginLeft: 2 }}
              >
                <Grid item md={4}>
                  {/* // add one text field for searching the PAN card number */}
                  <Paper
                    component="form"
                    sx={{
                      p: "9px",
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      boxShadow: "none",
                      border: "1px solid darkgrey",
                    }}
                  >
                    <InputBase
                      sx={{ ml: 1, flex: 1 }}
                      placeholder="Search Clients from Client Name & PAN No."
                      onChange={handleSearchClientFromPAN}
                    />
                    <IconButton
                      type="button"
                      sx={{ p: "10px" }}
                      aria-label="search"
                    ></IconButton>
                  </Paper>
                </Grid>
                <Grid item md={4}>
                  {/* // after searching the PAN card number, display the client in the AutoComplete dropdown list */}
                  <Autocomplete
                    options={filteredClientsData}
                    getOptionLabel={(option) =>
                      option.companyName
                        ? `${option.fullName} - ${option.companyName}`
                        : `${option.fullName} - ${option.contactPersonName}`
                    }
                    // onChange={(event, value) => {
                    //   setValue("panCardNo", value.panCardNo);
                    // }}
                    onChange={handleSelectClientDetails}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Client"
                        variant="outlined"
                        // error={errors.panCardNo ? true : false}
                        // helperText={
                        //   errors.panCardNo === undefined
                        //     ? ""
                        //     : errors.panCardNo.message + ""
                        // }
                        // {...register("panCardNo", registerOptions.pancard)}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Box>

            <Grid container spacing={2} sx={{ marginBottom: 2 }}>
              {sessionStorage.getItem("Profile") === "B" ? (
                <Grid item md={3}>
                  <Autocomplete
                    disableClearable
                    options={ListOfCompany ? ListOfCompany.data.companies : []}
                    loading={
                      ListOfCompany ? !ListOfCompany.data.companies : true
                    }
                    getOptionLabel={(option) =>
                      option.companyName + " - " + option.companyTypeCode
                    }
                    onChange={(event, value) => {
                      setValue(
                        "companyMasterCode",
                        !!value ? value.companyMasterCode : ""
                      );
                      setUserType(value.companyTypeCode);
                      // Set RM Name and RM User Code values
                      if (value) {
                        setValue("rmName", value.rmName || "");
                        setRmName(value.rmName || "");
                        setValue("rmEmpCode", value.rmUserCode || "");
                        setRmEmployeeCode(value.rmUserCode || "");
                      } else {
                        setValue("rmName", "");
                        setRmName("");
                        setValue("rmEmpCode", "");
                        setRmEmployeeCode("");
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Distributor Company *"
                        variant="outlined"
                        error={errors.companyName ? true : false}
                        helperText={
                          errors.companyName == undefined
                            ? ""
                            : errors.companyName.message + ""
                        }
                        {...register(
                          "companyName",
                          registerOptions.companyName
                        )}
                      />
                    )}
                    // value={
                    //   ListOfCompany?.data?.companies?.find(
                    //     (company) =>
                    //       company.companyMasterCode ===
                    //       watch("companyMasterCode")
                    //   ) || null
                    // }
                  />
                </Grid>
              ) : (
                <></>
              )}
              {sessionStorage.getItem("Profile") === "B" ? (
                <Grid item md={3}>
                  <Autocomplete
                    options={
                      UserProfilesByCompanyMasterCode
                        ? UserProfilesByCompanyMasterCode.data.userProfile
                        : []
                    }
                    getOptionLabel={(option) => option.employeeCode}
                    onChange={(event, value) => {
                      setValue(
                        "distributorEmployeeCode",
                        !!value ? value.employeeCode : ""
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Distributor"
                        variant="outlined"
                        error={errors.distributorEmployeeCode ? true : false}
                        helperText={
                          !!errors.distributorEmployeeCode &&
                          errors.distributorEmployeeCode.message + ""
                        }
                        {...register(
                          "distributorEmployeeCode",
                          registerOptions.distributorEmployeeCode
                        )}
                      />
                    )}
                  />
                </Grid>
              ) : (
                <></>
              )}
            </Grid>

            <Grid container spacing={2} sx={{ marginBottom: 2 }}>
              <Grid item md={3}>
                <TextField
                  id="outlined-basic"
                  label="Full Name *"
                  variant="outlined"
                  fullWidth
                  error={errors.fullName ? true : false}
                  helperText={
                    errors.fullName === undefined
                      ? ""
                      : errors.fullName.message + ""
                  }
                  {...register("fullName", registerOptions.fullName)}
                  onChange={(e) =>
                    setSelectedClient({
                      ...selectedClient,
                      fullName: e.target.value,
                    })
                  }
                  value={selectedClient.fullName}
                />
              </Grid>

              <Grid item md={3}>
                <TextField
                  id="outlined-basic"
                  label="Mobile No"
                  variant="outlined"
                  fullWidth
                  error={errors.mobileNo ? true : false}
                  helperText={
                    errors.mobileNo === undefined
                      ? ""
                      : errors.mobileNo.message + ""
                  }
                  {...register("mobileNo", registerOptions.mobileNo)}
                  onChange={(e) =>
                    setSelectedClient({
                      ...selectedClient,
                      mobileNo: e.target.value,
                    })
                  }
                  value={selectedClient.mobileNo}
                />
              </Grid>

              <Grid item md={3}>
                <TextField
                  id="outlined-basic"
                  label="Email Id"
                  variant="outlined"
                  type="email"
                  fullWidth
                  // error={errors.emailId ? true : false}
                  // helperText={
                  //   errors.emailId === undefined
                  //     ? ""
                  //     : errors.emailId.message + ""
                  // }
                  {...register("emailId", registerOptions.emailId)}
                  onChange={(e) =>
                    setSelectedClient({
                      ...selectedClient,
                      emailId: e.target.value,
                    })
                  }
                  value={selectedClient.emailId}
                />
              </Grid>

              <Grid item md={3}>
                <TextField
                  id="outlined-basic"
                  label="PAN Card No *"
                  variant="outlined"
                  fullWidth
                  error={errors.panCardNo ? true : false}
                  helperText={
                    errors.panCardNo === undefined
                      ? ""
                      : errors.panCardNo.message + ""
                  }
                  {...register("panCardNo", registerOptions.pancard)}
                  onChange={(e) =>
                    setSelectedClient({
                      ...selectedClient,
                      panCardNo: e.target.value,
                    })
                  }
                  value={selectedClient.panCardNo}
                />
              </Grid>

              <Grid item md={3}>
                <TextField
                  id="outlined-basic"
                  label="Aadhaar Card No"
                  variant="outlined"
                  fullWidth
                  error={errors.aadhaarCardNo ? true : false}
                  helperText={
                    errors.aadhaarCardNo === undefined
                      ? ""
                      : errors.aadhaarCardNo.message + ""
                  }
                  {...register("aadhaarCardNo", registerOptions.aadhaarCardNo)}
                  onChange={(e) =>
                    setSelectedClient({
                      ...selectedClient,
                      aadhaarCardNo: e.target.value,
                    })
                  }
                  value={selectedClient.aadhaarCardNo}
                />
              </Grid>

              <Grid item md={3}>
                <TextField
                  id="outlined-basic"
                  label="Address Line 1"
                  variant="outlined"
                  fullWidth
                  error={errors.addressLine1 ? true : false}
                  helperText={
                    errors.addressLine1 === undefined
                      ? ""
                      : errors.addressLine1.message + ""
                  }
                  {...register("addressLine1", registerOptions.addressL1)}
                  onChange={(e) =>
                    setSelectedClient({
                      ...selectedClient,
                      addressLine1: e.target.value,
                    })
                  }
                  value={selectedClient.addressLine1}
                />
              </Grid>

              <Grid item md={3}>
                <TextField
                  id="outlined-basic"
                  label="Address Line 2"
                  variant="outlined"
                  fullWidth
                  error={errors.addressLine2 ? true : false}
                  helperText={
                    errors.addressLine2 === undefined
                      ? ""
                      : errors.addressLine2.message + ""
                  }
                  {...register("addressLine2", registerOptions.addressL2)}
                  onChange={(e) =>
                    setSelectedClient({
                      ...selectedClient,
                      addressLine2: e.target.value,
                    })
                  }
                  value={selectedClient.addressLine2}
                />
              </Grid>

              <Grid item md={3}>
                <TextField
                  id="outlined-basic"
                  label="Address Line 3"
                  variant="outlined"
                  fullWidth
                  error={errors.addressLine3 ? true : false}
                  helperText={
                    errors.addressLine3 === undefined
                      ? ""
                      : errors.addressLine3.message + ""
                  }
                  {...register("addressLine3", registerOptions.addressL3)}
                  onChange={(e) =>
                    setSelectedClient({
                      ...selectedClient,
                      addressLine3: e.target.value,
                    })
                  }
                  value={selectedClient.addressLine3}
                />
              </Grid>

              <Grid item md={3}>
                <TextField
                  id="outlined-basic"
                  label="City"
                  variant="outlined"
                  fullWidth
                  error={errors.city ? true : false}
                  helperText={
                    errors.city === undefined ? "" : errors.city.message + ""
                  }
                  {...register("city", registerOptions.city)}
                  onChange={(e) =>
                    setSelectedClient({
                      ...selectedClient,
                      city: e.target.value,
                    })
                  }
                  value={selectedClient.city}
                />
              </Grid>

              <Grid item md={3}>
                <TextField
                  id="outlined-basic"
                  label="Pin Code"
                  variant="outlined"
                  fullWidth
                  error={errors.pinCode ? true : false}
                  helperText={
                    errors.pinCode === undefined
                      ? ""
                      : errors.pinCode.message + ""
                  }
                  {...register("pinCode", registerOptions.pincode)}
                  onChange={(e) =>
                    setSelectedClient({
                      ...selectedClient,
                      pinCode: e.target.value,
                    })
                  }
                  value={selectedClient.pinCode}
                />
              </Grid>

              <Grid item md={3}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={[
                    {
                      label: "RESIDENT INDIVIDUAL",
                      value: "RESIDENT_INDIVIDUAL",
                    },
                    {
                      label: "NON RESIDENT INDIVIDUAL",
                      value: "NON_RESIDENT_INDIVIDUAL",
                    },
                  ].map((option) => option)}
                  getOptionLabel={(options) => options.label}
                  onChange={(options, newValue) => {
                    setValue("residentialStatus", newValue?.value);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="User Role" />
                  )}
                  value={
                    [
                      {
                        label: "RESIDENT INDIVIDUAL",
                        value: "RESIDENT_INDIVIDUAL",
                      },
                      {
                        label: "NON RESIDENT INDIVIDUAL",
                        value: "NON_RESIDENT_INDIVIDUAL",
                      },
                    ].find(
                      (option) => option.value === watch("residentialStatus")
                    ) || null
                  }
                />
              </Grid>

              <Grid item md={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileDatePicker
                    label={
                      watch("clientType") === "CORPORATE"
                        ? "Date of Incorporation"
                        : "Date of Birth"
                    }
                    inputFormat="DD/MM/YYYY"
                    value={value}
                    onChange={(newValue) => {
                      setValueDate(newValue.toJSON());
                      setValue("dateOfBirth", newValue.toJSON());
                    }}
                    renderInput={(params) => (
                      <TextField {...params} sx={{ width: "100%" }} />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item md={3}>
                {/* <Autocomplete
                    options={States.countries}
                    getOptionLabel={(option) => option.country}
                    onChange={(event, value) =>
                      setSelectedCountry(value)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Country"
                        variant="outlined"
                        error={errors.country ? true : false}
                        helperText={
                          errors.country === undefined
                            ? ""
                            : errors.country.message + ""
                        }
                        {...register("country", registerOptions.country)}
                      />
                    )}
                    value={
                      States.countries.find(
                        (country) =>
                          country.country === selectedClient.country
                      ) || null
                    }
                  /> */}
                <Autocomplete
                  options={States.countries}
                  getOptionLabel={(option) => option.country}
                  onChange={(event, value) => {
                    setSelectedCountry(value);
                    setSelectedState(null); // Reset state when country is changed
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Country"
                      variant="outlined"
                      error={errors.country ? true : false}
                      helperText={
                        errors.country === undefined
                          ? ""
                          : errors.country.message + ""
                      }
                      {...register("country", registerOptions.country)}
                    />
                  )}
                  value={selectedCountry || null}
                />
              </Grid>

              <Grid item md={3}>
                {/* {selectedCountry && (
                    <Autocomplete
                      options={selectedCountry.states}
                      getOptionLabel={(option) => option}
                      onChange={(event, value) => {
                        setSelectedState(value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="State"
                          variant="outlined"
                          error={errors.state ? true : false}
                          helperText={
                            errors.state === undefined
                              ? ""
                              : errors.state.message + ""
                          }
                          {...register("state", registerOptions.state)}
                        />
                      )}
                      value={
                        selectedCountry?.states?.find(
                          (state) =>
                            state.toUpperCase() ===
                            selectedClient.state.toUpperCase()
                        ) || null
                      }
                    />
                  )} */}
                {selectedCountry && (
                  <Autocomplete
                    options={selectedCountry.states}
                    getOptionLabel={(option) => option}
                    onChange={(event, value) => setSelectedState(value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="State"
                        variant="outlined"
                        error={errors.state ? true : false}
                        helperText={
                          errors.state === undefined
                            ? ""
                            : errors.state.message + ""
                        }
                        {...register("state", registerOptions.state)}
                      />
                    )}
                    value={selectedState || null} // Use selectedState here
                  />
                )}
              </Grid>
            </Grid>

            <Typography
              variant="h5"
              sx={{ marginBottom: 3, fontWeight: "500" }}
            >
              Bank Details
            </Typography>

            <Grid container spacing={2} sx={{ marginBottom: 2 }}>
              <Grid item md={3}>
                <TextField
                  id="outlined-basic"
                  label="Bank Name *"
                  variant="outlined"
                  fullWidth
                  error={errors.bankName ? true : false}
                  helperText={
                    errors.bankName === undefined
                      ? ""
                      : errors.bankName.message + ""
                  }
                  {...register("bankName", registerOptions.bankName)}
                  onChange={(e) => {
                    setSelectedClient({
                      ...selectedClient,
                      bankName: e.target.value,
                    });
                  }}
                  value={selectedClient.bankName}
                />
              </Grid>

              <Grid item md={3}>
                <TextField
                  id="outlined-basic"
                  label="Branch Name"
                  variant="outlined"
                  fullWidth
                  error={errors.branchName ? true : false}
                  helperText={
                    errors.branchName === undefined
                      ? ""
                      : errors.branchName.message + ""
                  }
                  {...register("branchName", registerOptions.branchName)}
                  onChange={(e) => {
                    setSelectedClient({
                      ...selectedClient,
                      branchName: e.target.value,
                    });
                  }}
                  value={selectedClient.branchName}
                />
              </Grid>

              <Grid item md={3}>
                <TextField
                  id="outlined-basic"
                  label="Account No *"
                  variant="outlined"
                  fullWidth
                  error={errors.accountNo ? true : false}
                  helperText={
                    errors.accountNo === undefined
                      ? ""
                      : errors.accountNo.message + ""
                  }
                  {...register("accountNo", registerOptions.accountNo)}
                  onChange={(e) => {
                    setSelectedClient({
                      ...selectedClient,
                      accountNo: e.target.value,
                    });
                  }}
                  value={selectedClient.accountNo}
                />
              </Grid>

              <Grid item md={3}>
                <TextField
                  id="outlined-basic"
                  label="IFSC Code *"
                  variant="outlined"
                  fullWidth
                  error={errors.ifscCode ? true : false}
                  helperText={
                    errors.ifscCode === undefined
                      ? ""
                      : errors.ifscCode.message + ""
                  }
                  {...register("ifscCode", registerOptions.ifscCode)}
                  onChange={(e) => {
                    setSelectedClient({
                      ...selectedClient,
                      ifscCode: e.target.value,
                    });
                  }}
                  value={selectedClient.ifscCode}
                />
              </Grid>

              <Grid item md={3}></Grid>
              <Grid item md={3}></Grid>
            </Grid>

            <Typography
              variant="h5"
              sx={{ marginBottom: 3, fontWeight: "500" }}
            >
              DP Details
            </Typography>

            <Grid container spacing={2} sx={{ marginBottom: 2 }}>
              <Grid item md={3}>
                <TextField
                  id="outlined-basic"
                  label="DP Name"
                  variant="outlined"
                  fullWidth
                  error={errors.dpName ? true : false}
                  helperText={
                    errors.dpName === undefined
                      ? ""
                      : errors.dpName.message + ""
                  }
                  {...register("dpName", registerOptions.dpName)}
                  onChange={(e) => {
                    setSelectedClient({
                      ...selectedClient,
                      dpName: e.target.value,
                    });
                  }}
                  value={selectedClient.dpName}
                />
              </Grid>

              <Grid item md={3}>
                <TextField
                  id="outlined-basic"
                  label="DP ID"
                  variant="outlined"
                  fullWidth
                  error={errors.dpId ? true : false}
                  helperText={
                    errors.dpId === undefined ? "" : errors.dpId.message + ""
                  }
                  {...register("dpId", registerOptions.dpId)}
                  onChange={(e) => {
                    setSelectedClient({
                      ...selectedClient,
                      dpId: e.target.value,
                    });
                  }}
                  value={selectedClient.dpId}
                />
              </Grid>

              <Grid item md={3}>
                <TextField
                  id="outlined-basic"
                  label="Client Code *"
                  variant="outlined"
                  fullWidth
                  error={errors.clientCode ? true : false}
                  helperText={
                    errors.clientCode === undefined
                      ? ""
                      : errors.clientCode.message + ""
                  }
                  {...register("clientCode", registerOptions.clientcode)}
                  onChange={(e) =>
                    setSelectedClient({
                      ...selectedClient,
                      clientCode: e.target.value,
                    })
                  }
                  value={selectedClient.clientCode}
                />
              </Grid>

              <Grid item md={3}>
                <TextField
                  id="outlined-basic"
                  label="RM Name"
                  variant="outlined"
                  fullWidth
                  error={errors.rmName ? true : false}
                  helperText={
                    errors.rmName === undefined
                      ? ""
                      : errors.rmName.message + ""
                  }
                  {...register("rmName", registerOptions.rmName)}
                  disabled
                  value={rmName}
                />
              </Grid>

              {/* <Grid item md={3}>
                  <TextField
                    id="outlined-basic"
                    label="RM Branch"
                    variant="outlined"
                    fullWidth
                    error={errors.rmBranch ? true : false}
                    helperText={
                      errors.rmBranch === undefined
                        ? ""
                        : errors.rmBranch.message + ""
                    }
                    {...register("rmBranch", registerOptions.rmBranch)}
                  />
                </Grid> */}

              {/* <Grid item md={3}>
                  <TextField
                    id="outlined-basic"
                    label="RM Branch Code"
                    variant="outlined"
                    fullWidth
                    error={errors.rmBranch ? true : false}
                    helperText={
                      errors.rmBranch === undefined
                        ? ""
                        : errors.rmBranch.message + ""
                    }
                    {...register("rmBranchCode", registerOptions.rmBranchCode)}
                  />
                </Grid> */}

              <Grid item md={3}>
                <TextField
                  id="outlined-basic"
                  label="RM Employee Code"
                  variant="outlined"
                  fullWidth
                  error={errors.rmEmpCode ? true : false}
                  helperText={
                    errors.rmEmpCode === undefined
                      ? ""
                      : errors.rmEmpCode.message + ""
                  }
                  {...register("rmEmpCode", registerOptions.rmEmpCode)}
                  disabled
                  value={rmEmployeeCode}
                />
              </Grid>
            </Grid>
            <Typography
              variant="h5"
              sx={{ marginBottom: 3, fontWeight: "500" }}
            >
              Document Upload
            </Typography>

            <Stack spacing={3} direction="column" mb={3}>
              <Box>
                <Button variant="contained" component="label">
                  Upload PAN Card
                  <input
                    hidden
                    type="file"
                    accept=".pdf"
                    name="panCardDocument"
                    onChange={(event) =>
                      setNomineePancard(event.target.files[0])
                    }
                  />
                </Button>
                <Typography variant="caption" sx={{ marginLeft: "2rem" }}>
                  {nomineePancard == undefined
                    ? "No file choosen"
                    : nomineePancard.name}
                </Typography>
              </Box>
              <Box>
                <Button variant="contained" component="label">
                  Upload Client Master List
                  <input
                    hidden
                    type="file"
                    accept=".pdf"
                    name="cmlDocument"
                    onChange={(event) => setCMLdocument(event.target.files[0])}
                  />
                </Button>
                <Typography variant="caption" sx={{ marginLeft: "2rem" }}>
                  {CMLdocument == undefined
                    ? "No file choosen"
                    : CMLdocument.name}
                </Typography>
              </Box>
              <Box>
                <Button variant="contained" component="label">
                  Upload Cancel Cheque
                  <input
                    hidden
                    type="file"
                    accept=".pdf"
                    name="cancelCheque"
                    onChange={(event) => setCancelCheque(event.target.files[0])}
                  />
                </Button>
                <Typography variant="caption" sx={{ marginLeft: "2rem" }}>
                  {cancelCheque == undefined
                    ? "No file choosen"
                    : cancelCheque.name}
                </Typography>
              </Box>
              <Box>
                <Button variant="contained" component="label">
                  Upload Profile Image
                  <input
                    hidden
                    type="file"
                    accept=".jpg,.jpeg,.png"
                    name="profileImage"
                    onChange={(event) => setProfileImage(event.target.files[0])}
                  />
                </Button>
                <Typography variant="caption" sx={{ marginLeft: "2rem" }}>
                  {profileImage == undefined
                    ? "No file choosen"
                    : profileImage.name}
                </Typography>
              </Box>
              <Box>
                <Button variant="contained" component="label">
                  Upload Aadhaar Card
                  <input
                    hidden
                    type="file"
                    accept=".jpg,.jpeg,.png,.pdf"
                    name="aadhaarCard"
                    onChange={(event) => setAadhaarCard(event.target.files[0])}
                  />
                </Button>
                <Typography variant="caption" sx={{ marginLeft: "2rem" }}>
                  {aadhaarCard == undefined
                    ? "No file choosen"
                    : aadhaarCard.name}
                </Typography>
              </Box>
            </Stack>
            <Button
              type="submit"
              variant="contained"
              disabled={showCircularProgress}
            >
              {!!showCircularProgress && (
                <CircularProgress
                  color="secondary"
                  size={20}
                  thickness={5}
                  sx={{ mr: 1 }}
                />
              )}
              Submit
            </Button>
          </form>
        </Box>
      </Modal>
    </>
  );
};

export default ClientsAddModal;
