/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Grid, TablePagination, TextField } from "@mui/material";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { product_listOfAllProductType } from "../api/Api";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { StyledTableCell, StyledTableRow } from "../Constants/TableStyle";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useForm } from "react-hook-form";
import axios from "axios";
import { toast } from "react-toastify";
import ProductTypeModel from "./ProductTypeModel";
import { withParam } from "../utils/Router.Helper";

const ProductType = () => {
  const { data, refetch } = useQuery(
    "ProductTypeAPI",
    product_listOfAllProductType,
    {
      refetchOnWindowFocus: false,
    }
  );

  const [open, setOpen] = React.useState(false);
  const [action, setAction] = React.useState("");
  const [productTypeData, setProductTypeData] = React.useState<any>("");

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = useState(0);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpen = (id: any) => {
    if (id === "") {
      setAction("Create");
      setOpen(true);
    } else {
      setProductTypeData(id);
      setAction("Update");
      setOpen(true);
    }
  };
  const handleClose = () => {
    setProductTypeData(null);
    setAction("");
    setOpen(false);
  };

  return (
    <>
      {data && (
        <Box
          sx={{
            minHeight: "calc(100vh - 150px)",
            marginTop: "3%",
            textAlign: "left",
            marginLeft: "5%",
            marginRight: "5%",
          }}
        >
          <Typography variant="h4" textAlign={"center"}>
            Product Type
          </Typography>
          <Box sx={{ display: "flex", marginBottom: "3%" }}>
            <Button
              variant="contained"
              sx={{ marginLeft: "auto" }}
              onClick={() => handleOpen("")}
            >
              Create New Record
            </Button>
          </Box>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Product Name</StyledTableCell>
                  <StyledTableCell align="center">Description</StyledTableCell>
                  <StyledTableCell align="center">GST</StyledTableCell>
                  <StyledTableCell align="center">Stamp Duty</StyledTableCell>
                  <StyledTableCell align="center">TCS</StyledTableCell>
                  <StyledTableCell align="center">Status</StyledTableCell>
                  <StyledTableCell align="center">
                    Is Quantity Required
                  </StyledTableCell>
                  <StyledTableCell align="center">Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data &&
                  data.data.productType
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row: any) => (
                      <StyledTableRow key={row.productTypeCode}>
                        <StyledTableCell component="th" scope="row">
                          {row.productName}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.description}
                        </StyledTableCell>
                        <StyledTableCell>{row.gstValue}</StyledTableCell>
                        <StyledTableCell align="center">
                          {row.stampDutyValue}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.tcsValue}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.isActive ? <>Active</> : <>In Active</>}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row?.isQuantityRequired ? <>True</> : <>False</>}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Button
                            variant="contained"
                            href={"productType/" + row.productTypeCode}
                          >
                            View / Update
                          </Button>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 30, 50]}
            component="div"
            count={data ? data.data.productType.length : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      )}

      <ProductTypeModel
        open={open}
        handleClose={handleClose}
        data={productTypeData}
      />
    </>
  );
};

export default withParam(ProductType);
