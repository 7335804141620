/* eslint-disable no-unreachable */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Autocomplete,
  Link,
  Box,
  Button,
  Container,
  Modal,
  Paper,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Grid,
  Stack,
  TablePagination,
  InputBase,
  IconButton,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import {
  GetAllManuUnlistedOrders,
  GetAllUnlistedMasterDetails,
  listOfManufactureNDistributorCompanies,
} from "../../api/Api";
import AddNewUnlistedMasterModel from "./AddNewUnlistedMasterModel";
import { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { convertDate } from "../../utils/Helper";
import ManufactureUnlistedSortTable from "./ManufactureUnlistedSortTable";
import SearchIcon from "@mui/icons-material/Search";
import { changeDate } from "./../../utils/Helper";
import CloseIcon from "@mui/icons-material/Close";

const ManufacturerUnlisted = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [unlistedModel, setUnlistedModel] = useState(false);
  const [unlistedMasterModel, setUnlistedMasterModel] = useState(false);
  const [unListedMasterCode, setUnListedMasterCode] = useState();
  const [UnlistedName, setUnlistedName] = useState("");
  const [companyMasterCode, setCompanyMasterCode] = useState("");
  const [maturityDate, setMaturityDate] = React.useState<Dayjs | null | any>();
  const [showCircularProgress, setshowCircularProgress] = React.useState(false);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = useState(0);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  // React.useEffect(() => {
  //   setValue("newValidTillDate", changeDate(new Date()));
  // }, []);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function updateUnlistedModel(status: any) {
    status === "open" ? (
      setUnlistedModel(true)
    ) : status === "close" ? (
      setUnlistedModel(false)
    ) : (
      <></>
    );
  }

  function updateUnlistedMasterModel(status: any) {
    status === "open" ? (
      setUnlistedMasterModel(true)
    ) : status === "close" ? (
      setUnlistedMasterModel(false)
    ) : (
      <></>
    );
  }

  const resolver: any = async (values: any) => {
    return {
      // values: !values.newQuantity ? {} : values,
      values: {
        ...values, // Ensure all values are captured
      },
      errors: !values.companyName
        ? {
            companyName: {
              type: "required",
              message: "This is required.",
            },
          }
        : !values.scriptName
        ? {
            scriptName: {
              type: "required",
              message: "This is required.",
            },
          }
        : // : !values.newQuantity
          // ? {
          //     newQuantity: {
          //       type: "required",
          //       message: "This is required.",
          //     },
          //   }
          {},
    };
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({ resolver: resolver });

  const { data, refetch } = useQuery(
    "GetActiveUnlDetail",
    GetAllUnlistedMasterDetails,
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: manufactureOrderDetails, refetch: RefatchManufactureData } =
    useQuery("GetManuUnlistedOrders", GetAllManuUnlistedOrders, {
      refetchOnWindowFocus: false,
    });
  const { data: distributorCompanyList } = useQuery(
    "ManufactureNDistributorCompaniesForManufactureUnlistedScreen",
    () => listOfManufactureNDistributorCompanies("M"),
    {
      enabled: sessionStorage.getItem("Profile") === "B",
      refetchOnWindowFocus: false,
    }
  );

  const onSubmit = (data: any) => {
    const manufactureUnlistedObj = {
      ...data,
      productTypeCode: 11,
      productName: UnlistedName,
      unlistedMasterCode: unListedMasterCode,
      companyMasterCode: companyMasterCode,
    };
    axios
      .post("unlisted/saveUnlisted", manufactureUnlistedObj)
      .then(() => updateUnlistedModel("close"))
      .then(() => toast.success("Added Order Successfully!"))
      .then(() => {
        setshowCircularProgress(false);
      })
      .then(() => {
        refetch();
        RefatchManufactureData();
      })
      .catch((err: any) =>
        toast.error(
          err.response.data ? err.response.data.status.message.title : "Error"
        )
      );
  };

  const filteredUnlistedList =
    manufactureOrderDetails &&
    manufactureOrderDetails?.data?.unlistedDetailsList?.filter((item: any) => {
      return item.scriptName.toLowerCase().includes(searchTerm.toLowerCase());
    });

  const handleSearch = (event: any) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div
      // maxWidth="xl"
      style={{ padding: "0px 20px" }}
    >
      {manufactureOrderDetails && data && (
        <>
          <Box>
            <Typography variant="h4" sx={{ mb: 5 }}>
              Unlisted
            </Typography>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  sx={{ mb: 2 }}
                  onClick={() => updateUnlistedModel("open")}
                >
                  Add Quote
                </Button>
              </Grid>

              <Grid item xs={6}>
                <Button
                  variant="contained"
                  sx={{ mb: 2 }}
                  onClick={() => updateUnlistedMasterModel("open")}
                >
                  Add New Unlisted
                </Button>
              </Grid>
            </Grid>

            <Paper
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: 400,
                mb: 2,
              }}
            >
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search by Script Name"
                onChange={handleSearch}
                inputRef={(input) => input && input.focus()}
                autoFocus={true}
              />
            </Paper>

            <ManufactureUnlistedSortTable
              data={manufactureOrderDetails && filteredUnlistedList}
            />
          </Box>

          <Modal
            keepMounted
            open={unlistedModel}
            onClose={() => updateUnlistedModel("close")}
          >
            <Box sx={style} className="modal-scroll">
              <IconButton
                style={{
                  position: "absolute",
                  fontSize: "18px",
                  top: "8px",
                  right: "5px",
                }}
                onClick={() => updateUnlistedModel("close")}
              >
                <CloseIcon style={{ fontSize: "18px" }} />
              </IconButton>
              <Typography
                id="keep-mounted-modal-title"
                variant="h6"
                component="h2"
                align="center"
              >
                Add Unlisted Share
              </Typography>

              <form
                onSubmit={handleSubmit(onSubmit)}
                style={{ display: "flex", flexDirection: "column" }}
              >
                {sessionStorage.getItem("Profile") === "B" && (
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={
                      distributorCompanyList &&
                      distributorCompanyList.data.companies.map(
                        (options: any) => options
                      )
                    }
                    getOptionLabel={(options: any) => options.companyName}
                    sx={{ width: "95%" }}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        size="small"
                        label="Select Company"
                        {...register("companyName")}
                        error={errors.companyName ? true : false}
                        helperText={
                          errors.companyName === undefined
                            ? ""
                            : errors.companyName.message + ""
                        }
                      />
                    )}
                    onChange={(options: any, newValue) => {
                      setCompanyMasterCode(newValue?.companyMasterCode);
                    }}
                  />
                )}
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={data.data.unlistedList
                    .filter((item: any) => item.isActive === true)
                    .map((options: any) => options)}
                  getOptionLabel={(options: any) => options.scriptName}
                  sx={{ width: "95%" }}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      size="small"
                      label="Select Unlisted"
                      {...register("scriptName")}
                      error={errors.scriptName ? true : false}
                      helperText={
                        errors.scriptName === undefined
                          ? ""
                          : errors.scriptName.message + ""
                      }
                    />
                  )}
                  onChange={(options: any, newValue) => {
                    setUnListedMasterCode(newValue?.unlistedMasterCode);
                    setUnlistedName(newValue?.scriptName);
                  }}
                />
                {/* <TextField
                  id="outlined-basic"
                  label="Quantity"
                  variant="outlined"
                  type="number"
                  size="small"
                  //   defaultValue={data3.data && data3.data.data.unlisteds[0].quantity }
                  error={errors.newQuantity ? true : false}
                  helperText={
                    errors.newQuantity === undefined
                      ? ""
                      : errors.newQuantity.message + ""
                  }
                  {...register("newQuantity", { valueAsNumber: true })}
                /> */}
                {/* MANUFACTURING COST PRICE */}
                <TextField
                  id="outlined-basic"
                  label="Cost Price"
                  variant="outlined"
                  size="small"
                  {...register("manufactureBuyRate", {
                    valueAsNumber: true,
                  })}
                />
                {/* CARRYING COST */}
                {/* <TextField
                  id="outlined-basic"
                  label="Carrying Cost"
                  variant="outlined"
                  size="small"
                  {...register("newManufacturerCarryingCost", {
                    valueAsNumber: true,
                  })}
                /> */}
                {/* MANUFACTURE PRICE */}
                {/* <TextField
                  id="outlined-basic"
                  label="Manufacture Price"
                  variant="outlined"
                  size="small"
                  {...register("newManufacturePrice", { valueAsNumber: true })}
                /> */}
                {/* MANUFACTURING LANDING PRICE */}
                <TextField
                  id="outlined-basic"
                  label="Landing Price"
                  variant="outlined"
                  size="small"
                  {...register("manufactureSaleRate", {
                    valueAsNumber: true,
                  })}
                />
                {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileDatePicker
                    label="Valid Till Date"
                    inputFormat="DD/MM/YYYY"
                    disablePast
                    value={maturityDate}
                    onChange={(newValue) => {
                      setMaturityDate(newValue.toJSON());
                      setValue("newValidTillDate", changeDate(newValue.toJSON()));
                    }}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        {...params}
                        // sx={{width:"100%", marginBottom:'20px'}}
                      />
                    )}
                  />
                </LocalizationProvider> */}
                <TextField
                  id="outlined-basic"
                  label="Description"
                  variant="outlined"
                  {...register("description")}
                  size="small"
                />
                <Stack
                  spacing={3}
                  direction="row"
                  justifyContent={"center"}
                  mt={3}
                >
                  <Button type="submit" variant="contained">
                    {showCircularProgress === true ? (
                      <CircularProgress
                        color="secondary"
                        size={20}
                        thickness={5}
                      />
                    ) : (
                      ""
                    )}
                    Submit
                  </Button>
                </Stack>
              </form>
            </Box>
          </Modal>

          <AddNewUnlistedMasterModel
            open={unlistedMasterModel}
            onClose={() => updateUnlistedMasterModel("close")}
            data={data}
            refetch={refetch}
          />
        </>
      )}
    </div>
  );
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid #000",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
  "& .MuiTextField-root": { m: 1 },
};

export default ManufacturerUnlisted;
