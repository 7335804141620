/* eslint-disable no-lone-blocks */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box,
  Button,
  Container,
  Modal,
  Typography,
  Paper,
  Table,
  TableContainer,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  Link,
  tableCellClasses,
  styled,
  TablePagination,
  Stack,
  Grid,
  InputBase,
  IconButton,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { GetAllBonds, GetBondsMaster } from "../../api/Api";
import AddBond from "./AddBond";
import SearchIcon from "@mui/icons-material/Search";
import { changeDate } from "../../utils/Helper";
import { convertDate } from "../../utils/Helper";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: "10px",
  maxHeight: "80%",
  overflowY: "auto",
};

const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.action.hover,
    color: theme.palette.common.black,
    fontWeight: "600",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

interface Column {
  id: "name" | "coupon" | "maturity" | "rating" | "yield" | "price" | "isin";
  label: string;
  minWidth?: number;
  align?: "right" | "left";
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: "name", label: "Issuer Name", minWidth: 170 },
  {
    id: "isin",
    label: "ISIN ",
    minWidth: 100,
    // align: "right",
    //   format: (value: number) => value.toFixed(2),
  },
  {
    id: "coupon",
    label: "Coupon (%)",
    minWidth: 100,
    align: "right",
  },
  {
    id: "maturity",
    label: "Maturity",
    minWidth: 100,
    align: "right",
    //   format: (value: number) => value.toLocaleString('en-US'),
  },
  {
    id: "rating",
    label: "Rating",
    minWidth: 100,
    align: "left",
    //   format: (value: number) => value.toLocaleString('en-US'),
  },
];

const BondMaster = () => {
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [bondFile, setBondFile] = useState<any>();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [filteredData, setFilteredData] = React.useState<any>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showCircularProgress, setshowCircularProgress] = useState(false);
  const [failedIsin, setFailedIsin] = useState<any>();
  const [bondMasterData, setBondMasterData] = useState([]);

  const { data } = useQuery("getAllBondMaster", () => GetAllBonds(), {
    onSuccess: (data: any) => {
      const bondMaster = data?.data?.bondWork.map((item: any, index: any) => {
        return { ...item, SrNo: index + 1 };
      });
      setBondMasterData(bondMaster);
    },
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event: any) => {
    setSearchTerm(event.target.value);
  };

  const filteredBroker = bondMasterData.filter((item: any) => {
    return (
      item.issuerName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.isinNumber.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const rows = [
    filteredData.map((item: any) => {
      return {
        isin: item.isin,
        name: item.issuerName,
        coupon: item.coupon,
        maturity: item.maturityDate,
        rating: item.rating1,
      };
    }),
  ];

  const resolver = async (values: any) => {
    return {
      values: !values.brokerName ? {} : values,
      errors: !values.brokerName ? {} : {},
    };
  };

  const { register, handleSubmit } = useForm({
    resolver: resolver,
  });

  const onSubmit = (data: any) => {
    setshowCircularProgress(true);
    console.log("data", data);
    const formData = new FormData();

    const dto: any = {
      ...data,
    };
    formData.append("file", bondFile);
    // console.log("Data", formData);

    axios
      .post("bondwork/importBondExcelData", formData)
      .then((e) => toast.success("Bonds Added Successfully!"))
      .then((e) => {
        setshowCircularProgress(false);
        setOpen(false);
      })
      .catch((err: any) => {
        setshowCircularProgress(false);
        toast.error(
          !!err.response?.data?.status?.message?.title
            ? err.response.data.status.message.title
            : "Kindly Upload Valid Excel File"
        );
        console.log("partial data", err.response.data.failedIsin);
        {
          err.response
            ? setFailedIsin(err.response.data.failedIsin)
            : setFailedIsin([]);
        }
      });
  };

  const filteredCount = filteredBroker.length;
  const pageCount = Math.ceil(filteredCount / rowsPerPage);
  // Ensure the current page is within bounds after filtering
  const adjustedPage = Math.min(page, pageCount - 1);

  return (
    <div>
      <Container>
        <Box sx={{ mt: "3%" }}>
          <Typography variant="h4" gutterBottom>
            Bond Master
          </Typography>
          <Grid container spacing={2}>
            <Grid item md={12} sx={{ display: "flex", width: "100%" }}>
              <Paper
                component="form"
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  width: 400,
                }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search Bond by Issuer Name or ISIN."
                  onChange={handleSearch}
                />
                <IconButton
                  type="button"
                  sx={{ p: "10px" }}
                  aria-label="search"
                >
                  <SearchIcon />
                </IconButton>
              </Paper>
              <Stack spacing={2} direction="row" sx={{ marginLeft: "auto" }}>
                <Button
                  variant="contained"
                  component="label"
                  onClick={handleOpen}
                >
                  Add Bond file
                </Button>
                {/* <AddBond/> */}
                <Button variant="contained" href="/addbond">
                  Add Bond
                </Button>
              </Stack>
            </Grid>
            <Grid item md={12}>
              <TableContainer component={Paper} sx={{ marginTop: "2%" }}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <StyledTableCell
                          key={column.id}
                          align={column.align}
                          style={{ top: 57, minWidth: column.minWidth }}
                        >
                          {column.label}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredBroker
                      .slice(
                        adjustedPage * rowsPerPage,
                        Math.min(
                          adjustedPage * rowsPerPage + rowsPerPage,
                          filteredCount
                        )
                      )
                      .map((row: any) => {
                        return (
                          <Link
                            href={"/BondDetails/" + row.isinNumber}
                            display="table-row"
                            key={row.isinNumber}
                            sx={{
                              textDecoration: "none",
                              "&:hover": { backgroundColor: "lightgray" },
                            }}
                          >
                            <StyledTableCell>{row.issuerName}</StyledTableCell>
                            <StyledTableCell>{row.isinNumber}</StyledTableCell>
                            <StyledTableCell align="center">
                              {row.coupon}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {convertDate(row.maturityDate)}
                            </StyledTableCell>
                            <StyledTableCell>{row.rating1}</StyledTableCell>
                          </Link>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={filteredCount ? filteredCount : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Modal open={open}>
        <>
          <Box sx={style}>
            <IconButton onClick={handleClose} sx={{ marginLeft: "90%" }}>
              <CloseIcon />
            </IconButton>
            <Box>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Typography
                  variant="h5"
                  component="div"
                  textAlign="center"
                  mb={2}
                >
                  Add Bond
                </Typography>
                <Typography>Add File</Typography>
                <input
                  type="file"
                  onChange={(event: any) => setBondFile(event.target.files[0])}
                />
                <Button
                  type="submit"
                  disabled={showCircularProgress}
                  variant="contained"
                >
                  {showCircularProgress === true ? (
                    <CircularProgress
                      color="secondary"
                      size={20}
                      thickness={5}
                    />
                  ) : (
                    ""
                  )}
                  Submit
                </Button>
              </form>
              <Box sx={{ width: "100%", marginTop: "20px" }}>
                {failedIsin ? (
                  <>
                    <Typography
                      variant="caption"
                      color={"red"}
                      marginBottom={2}
                    >
                      ISIN Failed to Upload
                    </Typography>
                    {failedIsin.map((it: any) => (
                      <>
                        <Typography>{it}</Typography>
                      </>
                    ))}
                  </>
                ) : (
                  <></>
                )}
              </Box>
            </Box>
          </Box>
        </>
      </Modal>
    </div>
  );
};

export default BondMaster;
