/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box,
  Button,
  FormControlLabel,
  Modal,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Grid,
  Container,
  Autocomplete,
  CircularProgress,
  TablePagination,
  IconButton,
  InputBase,
  MenuItem,
  Menu,
  Toolbar,
  styled,
} from "@mui/material";
import { Stack } from "@mui/system";
import axios from "axios";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import {
  GetAllClient,
  GetListOfUserByCompanyMasterCodeAndUserTypeCode,
  listOfManufactureNDistributorCompanies,
} from "../api/Api";
import States from "../Constants/States";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs, { Dayjs } from "dayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import ImportClient from "./ImportClient";
import CloseIcon from "@mui/icons-material/Close";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import { isValidUsername } from "../utils/Helper";
import { Link } from "react-router-dom";
import ClientsAddModal from "./ClientsAddModal";

const StyledLink = styled(Link)(({ theme }) => ({
  display: "table-row",
  textDecoration: "none",
  "&:hover": { backgroundColor: "lightgray" },
}));

const Clients = () => {
  const [open, setOpen] = useState(false);
  const [Clients, setClients] = useState([]);
  const [openImportModal, setOpenImportModal] = useState(false);
  const [nomineePancard, setNomineePancard] = useState<any>();
  const [cancelCheque, setCancelCheque] = useState<any>();
  const [profileImage, setProfileImage] = useState<any>();
  const [aadhaarCard, setAadhaarCard] = useState<any>();
  const [selectedCountry, setSelectedCountry] = useState<any>(null);
  const [selectedState, setSelectedState] = useState<any>(null);
  const [showCircularProgress, setshowCircularProgress] = useState(false);
  const [CMLdocument, setCMLdocument] = useState<any>();
  const [value, setValueDate] = React.useState<Dayjs | null | any>(
    dayjs("2022-04-07")
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOrder, setSortOrder] = useState("asc"); // "asc" or "desc"
  const [userType, setUserType] = useState("");
  // state for RM NAME
  const [rmName, setRmName] = React.useState("");
  // state for RM EMPLOYEE CODE
  const [rmEmployeeCode, setRmEmployeeCode] = React.useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = useState(0);
  const [searchClientFromPAN, setSearchClientFromPAN] = useState("");
  const [filteredClientsData, setFilteredClientsData] = useState([]);
  const [selectedClient, setSelectedClient] = useState<any>({
    fullName: "",
    mobileNo: "",
    emailId: "",
    panCardNo: "",
    aadhaarCardNo: "",
    addressLine1: "",
    addressLine2: "",
    addressLine3: "",
    city: "",
    pinCode: "",
    clientCode: "",
    bankName: "",
    branchName: "",
    accountNo: "",
    ifscCode: "",
    dpName: "",
    dpId: "",
    rmName: "",
    rmBranch: "",
    rmBranchCode: "",
    rmEmpCode: "",
  });
  const [filteredDistributor, setFilteredDistributor] = useState([]);

  const { data: ListOfCompany } = useQuery(
    "ListOfManuAndDistList",
    () => listOfManufactureNDistributorCompanies("D,E"),
    {
      enabled: sessionStorage.getItem("Profile") === "B",
      refetchOnWindowFocus: false,
    }
  );

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClose = () => setOpen(false);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    // If the search term is empty, reset the page to 0, otherwise, keep the current page
    if (event.target.value === "") {
      setPage(0);
    }
  };
  const handleSort = () => {
    // Toggle between "asc" and "desc" when clicking on the sorting button
    setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
  };

  const filteredClients = Clients?.filter((item: any) => {
    return (
      item?.fullName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item?.panCardNo?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item?.clientCode?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const sortedAndFilteredClients = [...filteredClients].sort(
    (a: any, b: any) => {
      const nameA = a.fullName.toLowerCase();
      const nameB = b.fullName.toLowerCase();

      if (sortOrder === "asc") {
        return nameA.localeCompare(nameB);
      } else {
        return nameB.localeCompare(nameA);
      }
    }
  );

  const handleSearchClientFromPAN = (e: any) => {
    const val = e.target.value;
    setSearchClientFromPAN(val);
    const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    if (val.length === 10 && panRegex.test(val)) {
      const filteredData = Clients?.filter(
        (client: any) => client?.panCardNo?.toLowerCase() === val.toLowerCase()
      );
      setFilteredClientsData(filteredData);
    }
    // search by client name
    else if (val.length > 0) {
      const filteredClientData = Clients?.filter((client: any) =>
        client?.fullName?.toLowerCase().includes(val.toLowerCase())
      );
      setFilteredClientsData(filteredClientData);
    } else {
      setFilteredClientsData([]);
    }
  };

  const registerOptions: any = {
    // companyCode : { required: "Select Company Name is required" },
    fullName: {
      required: "Full Name required",
      validate: (value: string) => {
        // const alphanumericRegex =
        //   /^[A-Za-z0-9!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]+$/;
        // if (!alphanumericRegex.test(value)) {
        //   return "Only alphanumeric are allowed here!";
        // }
        // return true;
        if (!isValidUsername(value)) {
          return "Only letters, numbers, special characters(not more than 3) are allowed!";
        }
        return true;
      },
    },
    companyName: { required: "select distributor Company" },
    // emailId: {
    //   required: "Email Id is required",
    //   validate: (value: string) => {
    //     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    //     if (!emailRegex.test(value)) {
    //       return "Please enter valid email ID";
    //     }
    //     return true;
    //   },
    // },
    pancard: { required: "Pan Card No is required" },
    // addressL1: { required: "Address Line 1 is required" },
    clientcode: { required: "Client Code is required" },
    bankName: { required: "Bank Name is required" },
    // branchName: { required: "Branch Name is required" },
    accountNo: {
      required: "Account No is required",
      validate: (value: string) => {
        const accountNoRegex = /^[0-9]{8,30}$/;
        if (!accountNoRegex.test(value)) {
          return "Account No. should be between 8 to 30 digits (only numbers)";
        }
        return true;
      },
    },
    ifscCode: {
      required: "IFSC Code is required",
      validate: (value: string) => {
        const ifscCodeRegex = /^[A-Z]{4}0[A-Z0-9]{6}$/;
        if (!ifscCodeRegex.test(value)) {
          return "IFSC Code should be 11 characters long, first four characters should be uppercase alphabets, fifth character should be 0, last 6 characters should be numbers but can also have alphabets";
        }
        return true;
      },
    },
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    watch,
  } = useForm();

  const onSubmit = (data: any) => {
    setshowCircularProgress(true);
    const formData: any = new FormData();
    formData.append("panCardDocument", nomineePancard);
    formData.append("cancelCheque", cancelCheque);
    formData.append("profileImage", profileImage);
    formData.append("aadhaarCardDocument", aadhaarCard);
    formData.append("clientMasterList", CMLdocument);
    formData.append("clientDto", JSON.stringify(data));
    axios
      .post("client/saveClient", formData)
      .then(() => toast.success("Client Added Successfully"))
      .then(() => {
        setshowCircularProgress(false);
        setOpen(false);
        window.location.reload();
      })
      .catch((err: any) => {
        setshowCircularProgress(false);
        toast.error(
          err.response.data ? err.response.data.status.message.title : "Error"
        );
      });
  };

  const { data } = useQuery("getAllClients", () => GetAllClient(), {
    onSuccess: (data: any) => {
      const newData = data.data.clients.map((item: any, index: any) => {
        return { ...item, SrNo: index + 1 };
      });
      setClients(newData);
    },
    refetchOnWindowFocus: false,
  });

  const { data: UserProfilesByCompanyMasterCode } = useQuery(
    ["userProfileByCompany", watch("companyMasterCode"), userType],
    () =>
      GetListOfUserByCompanyMasterCodeAndUserTypeCode(
        userType === "S" ? "E" : userType,
        getValues("companyMasterCode")
      ),
    { enabled: !!getValues("companyMasterCode"), refetchOnWindowFocus: false }
  );

  const handleSelectClientDetails = (e: any, value: any) => {
    if (value) {
      setSelectedClient({
        fullName: value.fullName,
        mobileNo: value.mobileNo,
        emailId: value.emailId,
        panCardNo: value.panCardNo,
        aadhaarCardNo: value.aadhaarCardNo,
        addressLine1: value.addressLine1,
        addressLine2: value.addressLine2,
        addressLine3: value.addressLine3,
        city: value.city,
        state: value.state,
        country: value.country,
        pinCode: value.pinCode,
        clientCode: value.clientCode,
        bankName: value.bankName,
        branchName: value.branchName,
        accountNo: value.accountNo,
        ifscCode: value.ifscCode,
        dpName: value.dpName,
        dpId: value.dpId,
        rmName: value.rmName,
        rmBranch: value.rmBranch,
        rmBranchCode: value.rmBranchCode,
        rmEmpCode: value.rmEmpCode,
      });
      setRmName(value.rmName);
      setRmEmployeeCode(value.rmEmpCode);
      setUserType(value.companyTypeCode);
      // set the value of rmName and rmEmployeeCode once I get the companyMasterCode using ListOfCompany
      const filteredRMName = ListOfCompany?.data?.companies?.find(
        (company: any) => company.companyMasterCode === value.companyMasterCode
      );
      setRmName(filteredRMName?.rmName || "");
      setRmEmployeeCode(filteredRMName?.rmUserCode || "");
      // filter the distributor companyName from companyMasterCode
      const filteredDistributor = ListOfCompany?.data?.companies?.filter(
        (company: any) => company.companyMasterCode === value.companyMasterCode
      );
      setFilteredDistributor(filteredDistributor);
      // Pre-fill the companyName in the Autocomplete based on the filtered result
      setValue("companyName", filteredDistributor[0]?.companyName || "");
      setValue(
        "companyMasterCode",
        filteredDistributor[0]?.companyMasterCode || ""
      );
      setValue("fullName", value.fullName);
      setValue("panCardNo", value.panCardNo);
      setValue("emailId", value.emailId);
      setValue("addressLine1", value.addressLine1);
      setValue("bankName", value.bankName);
      setValue("branchName", value.branchName);
      setValue("accountNo", value.accountNo);
      setValue("ifscCode", value.ifscCode);
      const filteredResidentialStatus = [
        {
          label: "RESIDENT INDIVIDUAL",
          value: "RESIDENT_INDIVIDUAL",
        },
        {
          label: "NON RESIDENT INDIVIDUAL",
          value: "NON_RESIDENT_INDIVIDUAL",
        },
      ].find((option: any) => option.value === value.residentialStatus);
      setValue("residentialStatus", filteredResidentialStatus?.value || "");
      // +++++changes+++++++
      // Handling country and state selection based on client details
      const filteredCountry = States.countries.find(
        (country: any) => country.country === value.country
      );
      setSelectedCountry(filteredCountry);
      setValue("country", filteredCountry?.country || "");

      if (filteredCountry) {
        const filteredState = filteredCountry.states.find(
          (state: any) => state.toUpperCase() === value.state.toUpperCase()
        );
        setSelectedState(filteredState);
        setValue("state", filteredState || "");
      } else {
        setSelectedState(null); // If no valid state, clear the state
        setValue("state", "");
      }
    } else {
      setSelectedClient({
        fullName: "",
        panCardNo: "",
        mobileNo: "",
        emailId: "",
        aadhaarCardNo: "",
        addressLine1: "",
        addressLine2: "",
        addressLine3: "",
        city: "",
        state: "",
        country: "",
        pinCode: "",
        clientCode: "",
        bankName: "",
        branchName: "",
        accountNo: "",
        ifscCode: "",
        dpName: "",
        dpId: "",
        rmName: "",
        rmBranch: "",
        rmBranchCode: "",
        rmEmpCode: "",
      });
      setSelectedCountry(null); // Reset the country and state
      setSelectedState(null);
      setValue("country", "");
      setValue("state", "");
    }
  };

  function EnhancedTableToolbar(props: any) {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleButtonClick = (event: any) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };
    function downloadPayoutExcel() {
      axios({
        url: `/client/clientListExcelDownload?forSelf=${false}`,
        method: "GET",
        responseType: "blob", // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        var date1 = new Date();
        link.setAttribute(
          "download",
          "Clients List_" +
            ("0" + date1.getDate()).slice(-2) +
            "_" +
            ("0" + (date1.getMonth() + 1)).slice(-2) +
            "_" +
            date1.getFullYear() +
            ".xlsx"
        );
        document.body.appendChild(link);
        link.click();
      });
    }
    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
        }}
      >
        <Button variant="contained" onClick={handleButtonClick} sx={{ m: 2 }}>
          Download
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={downloadPayoutExcel}>
            <Button
              variant="text"
              color="primary"
              startIcon={<DownloadForOfflineIcon />}
            >
              Excel
            </Button>
          </MenuItem>
        </Menu>
      </Toolbar>
    );
  }

  return (
    <Container maxWidth="xl">
      <Box
        sx={{ paddingTop: 10, "& .MuiTextField-root": { m: 1, width: "25ch" } }}
      >
        <Grid container gap={2}>
          <Button variant="contained" onClick={() => setOpen(true)}>
            Add Client
          </Button>
          <Button variant="contained" onClick={() => setOpenImportModal(true)}>
            Import Client
          </Button>

          <Grid
            item
            md={12}
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Paper
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: 400,
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search Client by Name or Pan Card or Client Code"
                onChange={handleSearch}
              />
              <IconButton
                type="button"
                sx={{ p: "10px" }}
                aria-label="search"
              ></IconButton>
            </Paper>

            <EnhancedTableToolbar />
          </Grid>
        </Grid>

        <TableContainer component={Paper} sx={{ mt: "2%" }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Sr No.</TableCell>
                <TableCell onClick={handleSort} style={{ cursor: "pointer" }}>
                  Client Name {sortOrder === "asc" ? "▲" : "▼"}
                </TableCell>
                <TableCell>Client PAN</TableCell>
                <TableCell>Client Email Id</TableCell>
                <TableCell>D1 Name</TableCell>
                <TableCell>L1 Name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedAndFilteredClients
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item: any, index: any) => (
                  <StyledLink
                    key={item.clientId}
                    to={"/client/" + item.clientId}
                  >
                    <TableCell align="left">
                      {page * rowsPerPage + index + 1}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {item.fullName}
                    </TableCell>
                    <TableCell>{item.panCardNo}</TableCell>
                    <TableCell component="th" scope="row">
                      {item.emailId}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {item.companyName ? item.companyName : "-"}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {item.contactPersonName !== null
                        ? item.contactPersonName
                        : "-"}
                    </TableCell>
                  </StyledLink>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 30, 50]}
          component="div"
          // count={data ? data.data.clients.length : 0}
          count={sortedAndFilteredClients ? sortedAndFilteredClients.length : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        {/* ADD CLIENT MODAL */}
        <ClientsAddModal
          open={open}
          setOpen={setOpen}
          handleClose={handleClose}
          Clients={Clients}
          filteredClientsData={filteredClientsData}
          setFilteredClientsData={setFilteredClientsData}
          selectedClient={selectedClient}
          setSelectedClient={setSelectedClient}
          rmName={rmName}
          setRmName={setRmName}
          rmEmployeeCode={rmEmployeeCode}
          setRmEmployeeCode={setRmEmployeeCode}
          userType={userType}
          setUserType={setUserType}
          ListOfCompany={ListOfCompany && ListOfCompany}
          setFilteredDistributor={setFilteredDistributor}
          States={States}
          selectedCountry={selectedCountry}
          setSelectedCountry={setSelectedCountry}
          selectedState={selectedState}
          setSelectedState={setSelectedState}
          value={value}
          setValueDate={setValueDate}
        />
      </Box>
      <ImportClient
        open={openImportModal}
        onClose={() => setOpenImportModal(false)}
      />
    </Container>
  );
};

export default Clients;

export const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: "10px",
  maxHeight: "80%",
  overflowY: "auto",
};
