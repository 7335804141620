/* eslint-disable no-unused-vars */
import { Home } from "@mui/icons-material";
import { Box, useMediaQuery } from "@mui/material";
import React, { useEffect } from "react";
import {
  Navigate,
  Route,
  Router,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { toast } from "react-toastify";

const Layout = (props) => {
  const isMobile = useMediaQuery("(max-width:730px)");
  const pathLocation = useLocation();

  if (!!sessionStorage.getItem("JWT")) {
    return (
      <Box
        sx={{
          padding:
            sessionStorage.getItem("JWT") &&
            pathLocation.pathname !== "/" &&
            pathLocation.pathname !== "/whyus"
              ? isMobile
                ? "65px 10px 50px 40px"
                : "65px 10px 50px 70px"
              : "",
        }}
      >
        {props.children}
      </Box>
    );
  } else return <></>;
};

export default Layout;
